import React, {useState, useEffect} from 'react'
import {motion} from "framer-motion"

import Landing_Page_JSON from "../JSONs/landing-page-two"
import Landing_Page_JSON_Component_Desktop from "../Content-Components/landing-page-desktop"

import * as THREE from 'three';
import { ParametricGeometry } from 'three/examples/jsm/geometries/ParametricGeometry';

export default function LandingPage(props) {
	let Landing_Page_JSON_Content_Desktop = Landing_Page_JSON.map(eachElement => (
			<Landing_Page_JSON_Component_Desktop 
				key={eachElement.id}
				id={eachElement.id}
				darkMode={props.darkMode}
				title={eachElement.title}
				title_span_1={eachElement.title_span_1}
				title_span_2={eachElement.title_span_2}
				text={eachElement.text}
				point_1={eachElement.point_1}
				point_2={eachElement.point_2}
				point_3={eachElement.point_3}
				point_4={eachElement.point_4}
			/>))

	//Klein Bottle for desktop start
	const LandingPageDesktopRef = React.useRef();
	const visionDesktopRef = React.useRef();
	const kleinBottleDesktopRef = React.useRef();

	let [kleinBottleDesktopHeight, setKleinBottleDesktopHeight] = React.useState();
	let [kleinBottleDesktopWidth, setKleinBottleDesktopWidth] = React.useState();
	
	const [materialColor, setMaterialColor] = React.useState(props.darkMode ? '#707070' : '#707070');

	React.useEffect(() => {
		setMaterialColor(props.darkMode ? '#707070' : '#707070');
	}, [props.darkMode]);

	React.useEffect(() => {
  		if (LandingPageDesktopRef.current && visionDesktopRef && kleinBottleDesktopRef.current) {
			const LandingPageDesktop_height = LandingPageDesktopRef.current.clientHeight;
			const visionDesktop_height = visionDesktopRef.current.clientHeight;

			const kleinBottleDesktop_height = `${visionDesktop_height -50}px`;
			
			

			const kleinBottleDesktop_width = `${visionDesktopRef.current.clientWidth/2}px`;
			const actual_width = Math.min(visionDesktopRef.current.clientWidth/2, visionDesktop_height -50);
			setKleinBottleDesktopHeight(`${actual_width}px`);
			setKleinBottleDesktopWidth(`${actual_width}px`);
			//setKleinBottleDesktopWidth(kleinBottleDesktop_width);
			
		    function rotateAboutPoint(obj, point, axis, theta, pointIsWorld) {
		      pointIsWorld = pointIsWorld === undefined ? false : pointIsWorld;

		      if (pointIsWorld) {
		        obj.parent.localToWorld(obj.position);
		      }

		      obj.position.sub(point);
		      obj.position.applyAxisAngle(axis, theta);
		      obj.position.add(point);

		      if (pointIsWorld) {
		        obj.parent.worldToLocal(obj.position);
		      }

		      obj.rotateOnAxis(axis, theta);
		    }

		    const kleinbottledesktopdiv = document.getElementById("kleinbottledesktopdiv");
		    const scene = new THREE.Scene();
		    // Create a camera

		    //const kdesktop_width = parseInt(kleinBottleDesktop_width.replace('px', ''), 10)
		    //const kdesktop_height = parseInt(kleinBottleDesktop_height.replace('px', ''), 10)
		    const kdesktop_width = actual_width;
		    const kdesktop_height = actual_width;
		    const camera = new THREE.PerspectiveCamera(75, kdesktop_width / kdesktop_height, 0.1, 1000);
		    camera.position.z = 0;
		    camera.position.y = 12;
		    
		    // Create a renderer
		    const renderer = new THREE.WebGLRenderer({alpha: true});
		    renderer.setSize(kdesktop_width, kdesktop_height);
		    kleinbottledesktopdiv.appendChild(renderer.domElement);
		    
		    // Create a Klein bottle geometry

		    const kleinGeometry = new ParametricGeometry(generateKleinBottle, 100, 20);
		    const material = new THREE.MeshBasicMaterial({ color: materialColor, wireframe: true });
		    material.needsUpdate = true;
		    const kleinBottle = new THREE.Mesh(kleinGeometry, material);
		    kleinBottle.position.set(0, 0, 0);

		    // Add the Klein bottle to the scene
		    scene.add(kleinBottle);

		    function generateKleinBottle(u, v, target) {
		        u *= Math.PI;
		        v *= 2 * Math.PI;

		        u = u * 2;
		        let x, y, z;
		        if (u < Math.PI) {
		            x = 3 * Math.cos(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.cos(u) * Math.cos(v);
		            //z = 4 * Math.sin(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.sin(u) * Math.cos(v);
		            z = -8 * Math.sin(u) - 2 * (1 - Math.cos(u)/2) * Math.sin(u) * Math.cos(v);
		        } else {
		            x = 3 * Math.cos(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.cos(v + Math.PI);
		            //z = 4 * Math.sin(u) * (1 + Math.sin(u));
		            z = -8 * Math.sin(u);
		        }

		        y = -2 * (1 - Math.cos(u) / 2) * Math.sin(v);

		        target.set(x, y, z).multiplyScalar(0.75);
		    }

		    var axis = new THREE.Vector3(0, 0, 1);
		    //var cameraaxis = new THREE.Vector3(1, 0, 0);
		    const boundingBox = new THREE.Box3().setFromObject(kleinBottle);
		    const center = new THREE.Vector3();
		    boundingBox.getCenter(center);
		    var point = new THREE.Vector3(center.x, center.y, center.z);
		    camera.lookAt(point);

		    function animate() {
		        requestAnimationFrame(animate);
		        rotateAboutPoint(kleinBottle, point, axis, 0.01, false)
		        //rotateAboutPoint(camera, point, axis, 0.05, false)
		        //camera.lookAt(point);
		        renderer.render(scene, camera);
		    }

		    animate();
		}
	}, [materialColor]);

	const klein_bottle_desktop_styles = {
		height: kleinBottleDesktopHeight,
		width: kleinBottleDesktopWidth,
	}
	//Klein Bottle for desktop end


	//Klein Bottle for mobile start
	const kleinBottleMobileRef = React.useRef();

	let [kleinBottleHeight, setKleinBottleHeight] = React.useState();
	let [kleinBottleWidth, setKleinBottleWidth] = React.useState();

	React.useEffect(() => {
		if (LandingPageDesktopRef.current && kleinBottleMobileRef.current) {
			const LandingPage_height = LandingPageDesktopRef.current.clientHeight;

			const kleinBottle_height = "250px"
			setKleinBottleHeight(kleinBottle_height);


			const kleinBottle_width = "300px";
			setKleinBottleWidth(kleinBottle_width);

		    function rotateAboutPoint(obj, point, axis, theta, pointIsWorld) {
		      pointIsWorld = pointIsWorld === undefined ? false : pointIsWorld;

		      if (pointIsWorld) {
		        obj.parent.localToWorld(obj.position);
		      }

		      obj.position.sub(point);
		      obj.position.applyAxisAngle(axis, theta);
		      obj.position.add(point);

		      if (pointIsWorld) {
		        obj.parent.worldToLocal(obj.position);
		      }

		      obj.rotateOnAxis(axis, theta);
		    }
		    const kleinbottlemobilediv = document.getElementById("kleinbottlemobilediv");
		    const scene = new THREE.Scene();
		    // Create a camera

		    const k_width = parseInt(kleinBottle_width.replace('px', ''), 10)
		    const k_height = parseInt(kleinBottle_height.replace('px', ''), 10)
		    const camera = new THREE.PerspectiveCamera(75, k_width / k_height, 0.1, 1000);
		    camera.position.z = 0;
		    camera.position.y = 12;
		    

		    // Create a renderer
		    const renderer = new THREE.WebGLRenderer({alpha: true});
		    renderer.setSize(k_width, k_height);
		    kleinbottlemobilediv.appendChild(renderer.domElement);
		    
		    // Create a Klein bottle geometry
		    const kleinGeometry = new ParametricGeometry(generateKleinBottle, 100, 20);
		    const material = new THREE.MeshBasicMaterial({ color: "#707070", wireframe: true });
		    const kleinBottle = new THREE.Mesh(kleinGeometry, material);

		    // Add the Klein bottle to the scene
		    scene.add(kleinBottle);

		    function generateKleinBottle(u, v, target) {
		        u *= Math.PI;
		        v *= 2 * Math.PI;

		        u = u * 2;
		        let x, y, z;
		        if (u < Math.PI) {
		            x = 3 * Math.cos(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.cos(u) * Math.cos(v);
		            //z = 4 * Math.sin(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.sin(u) * Math.cos(v);
		            z = -8 * Math.sin(u) - 2 * (1 - Math.cos(u)/2) * Math.sin(u) * Math.cos(v);
		        } else {
		            x = 3 * Math.cos(u) * (1 + Math.sin(u)) + (2 * (1 - Math.cos(u) / 2)) * Math.cos(v + Math.PI);
		            //z = 4 * Math.sin(u) * (1 + Math.sin(u));
		            z = -8 * Math.sin(u);
		        }

		        y = -2 * (1 - Math.cos(u) / 2) * Math.sin(v);

		        target.set(x, y, z).multiplyScalar(0.75);
		    }

		    var axis = new THREE.Vector3(0, 0, 1);
		    const boundingBox = new THREE.Box3().setFromObject(kleinBottle);
		    const center = new THREE.Vector3();
		    boundingBox.getCenter(center);
		    var point = new THREE.Vector3(center.x, center.y, center.z);
		    camera.lookAt(point);

		    function animate() {
		        requestAnimationFrame(animate);
		        rotateAboutPoint(kleinBottle, point, axis, 0.01, false)
		        renderer.render(scene, camera);
		    }

		    animate();
		}
	}, []);

	const klein_bottle_mobile_styles = {
		height: kleinBottleHeight,
		width: kleinBottleWidth,
	}
	//Klein Bottle for mobile end

	const [buttonText, setButtonText] = useState('');
	const words = ["Imagine", "Believe", "Achieve"];
	const delay = 500; // Adjust the delay in milliseconds

	const animateText = (index) => {
		const word = words[index];
		const wordLength = word.length;

		// Typing animation
		for (let i = 0; i <= wordLength; i++) {
			setTimeout(() => {
				setButtonText(word.substring(0, i));
			}, i * 150); // Adjust the delay here
		}

		// Delay before clearing
		setTimeout(() => {
			  clearText(index);
			}, wordLength * 150 + delay); // Adjust the delay here
	};

	const clearText = (index) => {
		const word = words[index];
		const wordLength = word.length;

		// Clearing animation
		for (let i = wordLength; i >= 0; i--) {
			setTimeout(() => {
				setButtonText(word.substring(0, i));
			}, (wordLength - i) * 50); // Adjust the delay here
		}

		// Move to the next word or restart the loop
		const nextIndex = (index + 1) % words.length;
			setTimeout(() => {
				animateText(nextIndex);
			}, wordLength * 150 + delay); // Adjust the delay here
	};

	// Initial animation start
	useEffect(() => {
		setTimeout(() => {
			animateText(0);
		}, 500);
	}, []);


	const scrollToContact = () => {
		const contactElement = document.getElementById("contact");

		if (contactElement) {
		const contactPosition = contactElement.offsetTop;
		// Scroll to the target position with the calculated offset
		window.scrollTo({
			top: contactPosition,
			behavior: "smooth",
		});
		}
	};

	return (
		<div className="" id="landing-page" >
		<section className="container landing-page pt-xl-5" ref={LandingPageDesktopRef}>
			{
				props.windowWidth > 992 && 
				<div 
					className="d-flex flex-row justify-content-between align-items-start"
					ref={visionDesktopRef}>
					<div className="col-7 pt-5">
						{Landing_Page_JSON_Content_Desktop}

					</div>
					<motion.div 
						initial={{x:100, opacity: 0}}
						viewport={{ once: true }}
						animate={{x: 0, opacity: 1}}
						transition={{duration: 0.3, delay: 0.3}}
						id="kleinbottledesktopdiv"
						ref={kleinBottleDesktopRef}
						style={klein_bottle_desktop_styles}>
					</motion.div>
				</div>
			}

			{
				props.windowWidth <= 992 && 

					<div className="d-flex flex-column justify-content-center align-items-center">
						<motion.h1 
							className="lp-title-mobile pt-2 pb-3 text-center"
							initial={{y:-30, opacity: 0}}
							viewport={{ once: true }}
							animate={{y: 0, opacity: 1}}
							transition={{duration: 1, type: "spring", delay: 0.1}}>
							<span>{Landing_Page_JSON[0].title_span_1}</span>
							{Landing_Page_JSON[0].title}
							<span>{Landing_Page_JSON[0].title_span_2}</span>
						</motion.h1>

						<motion.h2 
							className="lp-text-mobile ps-3 pe-3 text-center"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-30}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.2 }}>
							{Landing_Page_JSON[0].text}
						</motion.h2>

						<motion.div
							ref={kleinBottleMobileRef}
							style={klein_bottle_mobile_styles}
							id="kleinbottlemobilediv"
							className="kleinbottlemobilediv"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-30}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.3 }}>
						</motion.div>

						<div className="d-flex flex-row pb-3 justify-content-around lp-points-div pt-3 pb-3">
							<motion.div 
								className=" pb-2 lp-points w-md-40"
								initial={{y:-30, opacity: 0}}
								viewport={{ once: true }}
								animate={{y: 0, opacity: 1}}
								transition={{duratio10: 1, type: "spring", delay: 0.4}}>
								<div className="d-flex flex-row align-items-center">
									<div className=" pb-2">
										<img 
											src="./SVGs/Landing_Page/lp-point.svg" 
											className="pe-1"
											alt="services point icon: bullseye"/>
									</div>
									<h3 className=" pb-2 lp-service-1">{Landing_Page_JSON[0].point_1}</h3>
								</div>
								
								<div className="d-flex flex-row align-items-center">
									<div className=" pb-2">
										<img 
											src="./SVGs/Landing_Page/lp-point.svg" 
											className="pe-1"
											alt="services point icon: bullseye"/>
									</div>
									<h3 className=" pb-2 lp-service-2">{Landing_Page_JSON[0].point_2}</h3>
								</div>
							</motion.div>

							<motion.div 
								className=" pb-2 lp-points w-md-40"
								initial={{y:-30, opacity: 0}}
								viewport={{ once: true }}
								animate={{y: 0, opacity: 1}}
								transition={{duratio10: 1, type: "spring", delay: 0.4}}>
								<div className="d-flex flex-row align-items-center">
									<div className=" pb-2">
										<img 
											src="./SVGs/Landing_Page/lp-point.svg" 
											className="pe-1"
											alt="services point icon: bullseye"/>
									</div>
									<h3 className=" pb-2 lp-service-3">{Landing_Page_JSON[0].point_3}</h3>
								</div>
								<div className="d-flex flex-row align-items-center">
									<div className=" pb-2">
										<img 
											src="./SVGs/Landing_Page/lp-point.svg" 
											className="pe-1"
											alt="services point icon: bullseye"/>
									</div>
									<h3 className=" pb-2 lp-service-4">{Landing_Page_JSON[0].point_4}</h3>
								</div>
							</motion.div>
						</div>

						<motion.div 
							className="text-center pb-4"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-30}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.5 }}>
							<button 
								className="lp-buttonMobile"
								onClick={scrollToContact}>
								{buttonText}
							</button>
						</motion.div>


						<motion.div 
							className="d-flex flex-row justify-content-around lp-logos-mobile pt-1"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-30}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.7 }}>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/aws-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/aws-m-light-mode.svg"} 
								className="img-fluid"
								alt="AWS icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/postgreSQL-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/postgreSQL-m-light-mode.svg"} 
								className="img-fluid"
								alt="PostgreSQL icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/flask-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/flask-m-light-mode.svg"} 
								className="img-fluid"
								alt="Flask icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/bootstrap-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/bootstrap-m-light-mode.svg"} 
								className="img-fluid"
								alt="Bootstrap icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/react-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/react-m-light-mode.svg"} 
								className="img-fluid"
								alt="React icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/threejs-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/threejs-m-light-mode.svg"} 
								className="img-fluid"
								alt="ThreeJS icon"/>
							<img 
								src={props.darkMode ? 
								"../SVGs/Landing_Page/python-m-dark-mode.svg" : 
								"../SVGs/Landing_Page/python-m-light-mode.svg"} 
								className="img-fluid"
								alt="Python icon"/>
						</motion.div>

					</div>

			}
		</section>
		</div>
	)
}