import React from "react"

export default function WhyUsSVG(props) {
	return(
			<div className="">
				<svg width="100%" height="100%" viewBox="0 0 500 530"  version="1.1" id="svg356" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
					<defs id="defs360" />
					<mask id="mask0_282_7984" maskUnits="userSpaceOnUse" x="129" y="67" width="30" height="30">
					  <path d="m 158.387,83.9094 c -0.979,7.8689 -8.151,13.4537 -16.02,12.4738 -7.867,-0.9791 -13.451,-8.1489 -12.472,-16.0178 0.978,-7.867 8.15,-13.4518 16.016,-12.4719 7.867,0.9781 13.454,8.1489 12.476,16.0159 z"  id="ys-path214" />
					</mask>
					<mask id="mask1_282_7984" maskUnits="userSpaceOnUse" x="134" y="351" width="26" height="26">
					  <path d="m 159.039,361.211 c 1.617,6.646 -2.461,13.342 -9.107,14.957 -6.644,1.616 -13.34,-2.461 -14.955,-9.106 -1.616,-6.645 2.46,-13.341 9.105,-14.956 6.645,-1.616 13.342,2.462 14.957,9.105 z"  id="ys-path231" />
					</mask>
					<g className="ys-big-globe">
						<path d="m 582.9468,254.69316 10.07903,24.78876 7.6274,-38.40954 4.90325,-38.40955 -16.34493,-28.87442 2.7601,-7.21107 c -12.31726,-21.4001 -28.80832,-40.08648 -48.35288,-54.97099 l -42.12178,-4.8303 -20.70311,9.80626 -42.22267,3.54175 -17.16137,19.61299 -24.51739,10.89662 -0.54507,15.79987 -18.79539,5.17578 4.90324,17.70643 -33.50629,30.78098 v 34.59643 l 48.21604,14.98227 14.43721,54.75332 -1.90656,29.42064 11.44168,21.79208 22.88221,-10.35155 23.97117,-33.77883 1.36266,-34.0502 15.25481,-31.05468 25.87773,-46.30948 34.59643,32.68986 18.25033,-27.51408 z"  fillOpacity="1" id="ys-path34" fill={props.darkMode? "#4a4a4a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />
						<path d="m 431.94,73.969634 c -18.07406,0 -35.53695,2.611779 -52.04424,7.455186 l -2.59427,15.191601 22.58764,-8.426094 38.33649,12.293143 37.27651,-11.563106 36.68853,3.373476 C 487.92608,80.554227 460.70309,73.969634 431.94,73.969634 Z"  fillOpacity="1" id="ys-path42" fill={props.darkMode? "#4a4a4a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 250.99416,222.26655 11.07174,12.58051 4.79191,-32.07985 14.42677,-33.85653 52.3736,-21.22499 8.64331,-28.76657 50.50298,7.19136 14.56478,-19.82244 -30.06544,-9.670459 -45.95925,7.176279 c -40.84725,26.61913 -70.49868,68.97401 -80.3504,118.47269 z"  fillOpacity="1" id="ys-path38" fill={props.darkMode? "#4a4a4a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />
						<path d="m 280.22577,363.49237 25.42661,29.08085 -15.7616,-38.16021 8.77899,-26.9064 11.4846,-33.11083 -22.87873,-31.73427 -25.2109,-27.81445 -14.55783,28.80136 c 1.01823,37.04457 12.96323,71.35687 32.71886,99.84395 z"  fillOpacity="1" id="ys-path40" fill={props.darkMode? "#4a4a4a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 310.80579,133.55871 c 0,1.34062 -1.08665,2.42842 -2.42843,2.42842 -1.34179,0 -2.42843,-1.08664 -2.42843,-2.42842 0,-1.34179 1.08664,-2.42971 2.42843,-2.42971 1.34062,0 2.42843,1.08792 2.42843,2.42971 z"  fillOpacity="1" id="ys-path6" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 365.3295,104.34693 c 0,1.34016 -1.08781,2.42912 -2.42843,2.42912 -1.34062,0 -2.42843,-1.0878 -2.42843,-2.42912 0,-1.34132 1.08781,-2.42982 2.42843,-2.42982 1.34062,0 2.42843,1.08746 2.42843,2.42982 z"  fillOpacity="1" id="ys-path8" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 482.10957,167.47345 c 0,1.34016 -1.08665,2.42878 -2.42959,2.42878 -1.34062,0 -2.42727,-1.08746 -2.42727,-2.42878 0,-1.34132 1.08665,-2.42878 2.42727,-2.42878 1.34294,-0.001 2.42959,1.0863 2.42959,2.42878 z"  fillOpacity="1" id="ys-path10" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 387.2218,89.046077 c 0,1.340275 -1.0878,2.428777 -2.42959,2.428777 -1.34062,0 -2.42959,-1.087458 -2.42959,-2.428777 0,-1.341319 1.08781,-2.429938 2.42959,-2.429938 1.34179,0.0012 2.42959,1.088619 2.42959,2.429938 z"  fillOpacity="1" id="ys-path24" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 403.81475,107.56731 c 0,1.34016 -1.08781,2.42878 -2.43075,2.42878 -1.33947,0 -2.42843,-1.08746 -2.42843,-2.42878 0,-1.34132 1.0878,-2.42878 2.42843,-2.42878 1.34294,-0.001 2.43075,1.0863 2.43075,2.42878 z"  fillOpacity="1" id="ys-path26" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 287.836,331.18834 c 0,1.34016 -1.08665,2.42878 -2.42959,2.42878 -1.34062,0 -2.42727,-1.08746 -2.42727,-2.42878 0,-1.34132 1.08665,-2.42878 2.42727,-2.42878 1.34294,-0.001 2.42959,1.0863 2.42959,2.42878 z"  fillOpacity="1" id="ys-path30" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 561.99551,128.42293 c 71.82886,71.82887 71.82886,188.28564 10e-4,260.1145 -71.82887,71.82771 -188.28564,71.82771 -260.1145,-10e-4 -71.82887,-71.82886 -71.83003,-188.28563 -10e-4,-260.1145 71.82886,-71.828165 188.28563,-71.827817 260.1145,0.001 z"  fillOpacity="0.01"  id="ys-path32" fill={props.darkMode? "#ffffff": "#000000"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />							
						<path d="m 455.14112,234.62324 c 0,1.33946 -1.0878,2.42843 -2.43074,2.42843 -1.33947,0 -2.42727,-1.08781 -2.42727,-2.42843 0,-1.34178 1.0878,-2.42959 2.42727,-2.42959 1.34178,0 2.43074,1.08781 2.43074,2.42959 z"  fillOpacity="1" id="ys-path84" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-4"/>								      
						<path d="m 419.20524,210.71817 c 0,1.33946 -1.08665,2.42843 -2.42959,2.42843 -1.34063,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34178 1.08665,-2.43075 2.42843,-2.43075 1.34294,0 2.42959,1.08781 2.42959,2.43075 z"  fillOpacity="1" id="ys-path86" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								     
						<path d="m 389.65081,184.79347 c 0,1.34062 -1.0878,2.42843 -2.42959,2.42843 -1.33946,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34178 1.08781,-2.42959 2.42843,-2.42959 1.34179,0 2.42959,1.08665 2.42959,2.42959 z"  fillOpacity="1" id="ys-path88" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 302.49551,300.32942 c 0,1.34062 -1.08781,2.42843 -2.42959,2.42843 -1.34063,0 -2.42959,-1.08665 -2.42959,-2.42843 0,-1.34179 1.0878,-2.42959 2.42959,-2.42959 1.34178,0 2.42959,1.08664 2.42959,2.42959 z"  fillOpacity="1" id="ys-path90" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 379.3449,237.77997 c 0,1.34062 -1.08665,2.42727 -2.42959,2.42727 -1.34063,0 -2.42727,-1.08665 -2.42727,-2.42727 0,-1.34295 1.08664,-2.42843 2.42727,-2.42843 1.34294,-0.001 2.42959,1.08548 2.42959,2.42843 z"  fillOpacity="1" id="ys-path92" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-6"/>								      
						<path d="m 360.47264,248.93404 c 0,1.34063 -1.0878,2.42843 -2.43074,2.42843 -1.33947,0 -2.42727,-1.08664 -2.42727,-2.42843 0,-1.34178 1.0878,-2.42843 2.42727,-2.42843 1.34294,-10e-4 2.43074,1.08549 2.43074,2.42843 z"  fillOpacity="1" id="ys-path94" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 399.50179,265.47149 c 0,1.34062 -1.08781,2.42843 -2.42959,2.42843 -1.34062,0 -2.42727,-1.08665 -2.42727,-2.42843 0,-1.34063 1.08665,-2.42843 2.42727,-2.42843 1.34178,-0.001 2.42959,1.08664 2.42959,2.42843 z"  fillOpacity="1" id="ys-path96" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 417.439,320.70274 c 0,1.33946 -1.0878,2.42727 -2.42843,2.42727 -1.34062,0 -2.42959,-1.08781 -2.42959,-2.42727 0,-1.34295 1.08781,-2.43075 2.42959,-2.43075 1.34063,0 2.42843,1.0878 2.42843,2.43075 z"  fillOpacity="1" id="ys-path98" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 438.17661,302.75727 c 0,1.34063 -1.0878,2.42959 -2.42959,2.42959 -1.33946,0 -2.42842,-1.0878 -2.42842,-2.42959 0,-1.34178 1.0878,-2.42843 2.42842,-2.42843 1.34179,0 2.42959,1.08781 2.42959,2.42843 z"  fillOpacity="1" id="ys-path100" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 444.738,359.38079 c 0,1.34063 -1.08781,2.42843 -2.42843,2.42843 -1.34062,0 -2.42959,-1.08664 -2.42959,-2.42843 0,-1.34062 1.08781,-2.42843 2.42959,-2.42843 1.34178,-10e-4 2.42843,1.08665 2.42843,2.42843 z"  fillOpacity="1" id="ys-path102" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 417.439,348.85077 c 0,1.33947 -1.08664,2.42843 -2.42843,2.42843 -1.34062,0 -2.42843,-1.0878 -2.42843,-2.42843 0,-1.34178 1.08781,-2.42959 2.42843,-2.42959 1.34179,0 2.42843,1.08781 2.42843,2.42959 z"  fillOpacity="1" id="ys-path104" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 424.80501,369.54404 c 0,1.34062 -1.0878,2.42727 -2.42843,2.42727 -1.34062,0 -2.42959,-1.08665 -2.42959,-2.42727 0,-1.34178 1.08781,-2.42959 2.42959,-2.42959 1.34063,0 2.42843,1.08781 2.42843,2.42959 z"  fillOpacity="1" id="ys-path106" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-5"/>								      
						<path d="m 466.58223,327.44167 c 0,1.34062 -1.08781,2.42727 -2.42959,2.42727 -1.33947,0 -2.42843,-1.08665 -2.42843,-2.42727 0,-1.34178 1.0878,-2.42959 2.42843,-2.42959 1.34178,0 2.42959,1.08781 2.42959,2.42959 z"  fillOpacity="1" id="ys-path108" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 469.00892,291.98881 c 0,1.34062 -1.08664,2.42843 -2.42843,2.42843 -1.34062,0 -2.42727,-1.08665 -2.42727,-2.42843 0,-1.34178 1.08665,-2.42843 2.42727,-2.42843 1.34179,-0.001 2.42843,1.08665 2.42843,2.42843 z"  fillOpacity="1" id="ys-path120" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 464.15322,266.1418 c 0,1.33946 -1.08665,2.42843 -2.42843,2.42843 -1.34062,0 -2.42727,-1.08781 -2.42727,-2.42843 0,-1.34294 1.08665,-2.42959 2.42727,-2.42959 1.34178,0 2.42843,1.08665 2.42843,2.42959 z"  fillOpacity="1" id="ys-path122" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 491.83314,253.79206 c 0,1.33947 -1.08665,2.42727 -2.42843,2.42727 -1.34062,0 -2.42727,-1.0878 -2.42727,-2.42727 0,-1.34294 1.08665,-2.42959 2.42727,-2.42959 1.34178,0 2.42843,1.08781 2.42843,2.42959 z"  fillOpacity="1" id="ys-path124" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 512.912,215.94382 c 0,1.34062 -1.0878,2.42843 -2.42843,2.42843 -1.34062,0 -2.42843,-1.08665 -2.42843,-2.42843 0,-1.34179 1.08781,-2.42959 2.42843,-2.42959 1.34063,0 2.42843,1.08664 2.42843,2.42959 z"  fillOpacity="1" id="ys-path126" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 461.72363,193.92095 c 0,1.34062 -1.08665,2.42843 -2.42843,2.42843 -1.34062,0 -2.42843,-1.08665 -2.42843,-2.42843 0,-1.34178 1.08781,-2.42843 2.42843,-2.42843 1.34062,-0.001 2.42843,1.08665 2.42843,2.42843 z"  fillOpacity="1" id="ys-path128" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 441.29536,165.04653 c 0,1.33946 -1.08664,2.42843 -2.42842,2.42843 -1.34179,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34178 1.08664,-2.42959 2.42843,-2.42959 1.34062,-0.001 2.42842,1.08665 2.42842,2.42959 z"  fillOpacity="1" id="ys-path130" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-2" />							      
						<path d="m 403.81475,165.04653 c 0,1.34062 -1.08665,2.42843 -2.42959,2.42843 -1.34063,0 -2.42727,-1.08665 -2.42727,-2.42843 0,-1.34062 1.08664,-2.42843 2.42727,-2.42843 1.34294,0 2.42959,1.08781 2.42959,2.42843 z"  fillOpacity="1" id="ys-path132" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 374.48804,205.85899 c 0,1.34062 -1.0878,2.42843 -2.42959,2.42843 -1.34062,0 -2.42959,-1.08665 -2.42959,-2.42843 0,-1.34178 1.08781,-2.42843 2.42959,-2.42843 1.34179,0 2.42959,1.08665 2.42959,2.42843 z"  fillOpacity="1" id="ys-path134" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 456.86677,123.23208 c 0,1.34063 -1.08665,2.42843 -2.42959,2.42843 -1.34063,0 -2.42727,-1.08664 -2.42727,-2.42843 0,-1.34178 1.08664,-2.42959 2.42727,-2.42959 1.34294,0 2.42959,1.08781 2.42959,2.42959 z"  fillOpacity="1" id="ys-path136" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 427.27858,141.1287 c 0,1.34063 -1.08781,2.42959 -2.42959,2.42959 -1.34063,0 -2.42843,-1.0878 -2.42843,-2.42959 0,-1.34062 1.0878,-2.42843 2.42843,-2.42843 1.34178,-10e-4 2.42959,1.08665 2.42959,2.42843 z"  fillOpacity="1" id="ys-path138" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 476.41134,135.53427 c 0,1.34062 -1.08781,2.42959 -2.42959,2.42959 -1.33946,0 -2.42727,-1.08781 -2.42727,-2.42959 0,-1.34063 1.08781,-2.42843 2.42727,-2.42843 1.34178,-0.001 2.42959,1.08664 2.42959,2.42843 z"  fillOpacity="1" id="ys-path140" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-10"/>								      <path d="m 528.91598,125.65935 c 0,1.34062 -1.08781,2.42843 -2.42843,2.42843 -1.34062,0 -2.42959,-1.08665 -2.42959,-2.42843 0,-1.34178 1.08781,-2.42843 2.42959,-2.42843 1.34062,0 2.42843,1.08665 2.42843,2.42843 z"  fillOpacity="1" id="ys-path142" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 510.48589,182.36446 c 0,1.34062 -1.08664,2.42843 -2.42843,2.42843 -1.34178,0 -2.42843,-1.08665 -2.42843,-2.42843 0,-1.34179 1.08665,-2.42843 2.42843,-2.42843 1.34063,0 2.42843,1.08664 2.42843,2.42843 z"  fillOpacity="1" id="ys-path146" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 393.63442,123.23208 c 0,1.33946 -1.08781,2.42843 -2.42959,2.42843 -1.34063,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34178 1.08664,-2.42959 2.42843,-2.42959 1.34178,0 2.42959,1.08665 2.42959,2.42959 z"  fillOpacity="1" id="ys-path166" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 265.52299,311.28171 c 0,1.33946 -1.08665,2.42843 -2.42843,2.42843 -1.33947,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34179 1.0878,-2.43075 2.42843,-2.43075 1.34178,0 2.42843,1.0878 2.42843,2.43075 z"  fillOpacity="1" id="ys-path168" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 331.61071,143.55887 c 0,1.34062 -1.08781,2.42843 -2.42959,2.42843 -1.34062,0 -2.42843,-1.08665 -2.42843,-2.42843 0,-1.34179 1.08781,-2.42959 2.42843,-2.42959 1.34178,0 2.42959,1.08664 2.42959,2.42959 z"  fillOpacity="1" id="ys-path170" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 280.64211,167.4738 c 0,1.34062 -1.08781,2.42843 -2.42843,2.42843 -1.34063,0 -2.42959,-1.08665 -2.42959,-2.42843 0,-1.34178 1.0878,-2.42843 2.42959,-2.42843 1.34062,0 2.42843,1.08665 2.42843,2.42843 z"  fillOpacity="1" id="ys-path172" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-9"/>								      
						<path d="m 258.23654,269.33258 c 0,1.33946 -1.08664,2.42843 -2.42843,2.42843 -1.34062,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34179 1.08665,-2.42959 2.42843,-2.42959 1.34179,0 2.42843,1.08664 2.42843,2.42959 z"  fillOpacity="1" id="ys-path174" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 265.52299,202.22098 c 0,1.34063 -1.08781,2.42843 -2.42843,2.42843 -1.34063,0 -2.42959,-1.08664 -2.42959,-2.42843 0,-1.34178 1.0878,-2.42842 2.42959,-2.42842 1.34062,-0.001 2.42843,1.08664 2.42843,2.42842 z"  fillOpacity="1" id="ys-path176" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 282.98299,278.14092 c 0,1.33946 -1.0878,2.42843 -2.42959,2.42843 -1.33946,0 -2.42843,-1.08781 -2.42843,-2.42843 0,-1.34179 1.08781,-2.42959 2.42843,-2.42959 1.34179,0 2.42959,1.08664 2.42959,2.42959 z"  fillOpacity="1" id="ys-path178" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-3"/>								      
						<path d="m 265.52299,241.79928 c 0,1.34062 -1.08665,2.42843 -2.42843,2.42843 -1.34063,0 -2.42843,-1.08665 -2.42843,-2.42843 0,-1.34062 1.08664,-2.42843 2.42843,-2.42843 1.34178,0 2.42843,1.08781 2.42843,2.42843 z"  fillOpacity="1" id="ys-path180" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 443.38168,96.564118 c 0,1.340276 -1.08781,2.428778 -2.42843,2.428778 -1.34062,0 -2.42959,-1.087459 -2.42959,-2.428778 0,-1.341318 1.08781,-2.428777 2.42959,-2.428777 1.34062,0 2.42843,1.087459 2.42843,2.428777 z"  fillOpacity="1" id="ys-path16" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 403.81475,84.187361 c 0,1.34016 -1.08781,2.428778 -2.42959,2.428778 -1.34063,0 -2.42959,-1.087459 -2.42959,-2.428778 0,-1.342478 1.0878,-2.428777 2.42959,-2.428777 1.34178,-0.0012 2.42959,1.086299 2.42959,2.428777 z"  fillOpacity="1" id="ys-path20" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-1"/>	      
						<path d="m 481.69845,84.957408 c 0,1.340275 -1.08781,2.427734 -2.42843,2.427734 -1.34062,0 -2.42843,-1.087459 -2.42843,-2.427734 0,-1.341319 1.08781,-2.428778 2.42843,-2.428778 1.34062,-0.001 2.42843,1.086415 2.42843,2.428778 z"  fillOpacity="1" id="ys-path14" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-7"/>		      
						<path d="m 343.79867,115.65106 c 0,1.34063 -1.08781,2.42843 -2.42843,2.42843 -1.34062,0 -2.42959,-1.08664 -2.42959,-2.42843 0,-1.34062 1.08781,-2.42866 2.42959,-2.42866 1.34062,-10e-4 2.42843,1.08688 2.42843,2.42866 z"  fillOpacity="1" id="ys-path28" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  className="globe-dot-animation-8"/>								   
					</g>
					<g id="g13003" className="ys-cloud-1">
					  <path d="m 418.26819,30.384309 -0.14728,-0.007 c -0.81643,-0.07074 -35.80716,-8.136167 -39.7954,-9.055816 -0.86746,-0.160156 -2.48409,-0.897846 -2.23592,-2.505086 l 0.029,-0.190656 c 0.0452,-0.306163 0.0916,-0.616733 0.16004,-0.926259 1.06693,-4.684295 5.07952,-8.0828207 9.54208,-8.0828207 0.66103,0 1.32439,0.075265 1.9715,0.2223161 1.08085,0.2462056 2.0898,0.6853876 3.00713,1.3097746 2.58151,-3.8071953 6.78777,-6.1488091 11.10653,-6.1488091 0.91848,0 1.83929,0.103446 2.74155,0.309445 5.87044,1.33697 10.06627,6.9016571 10.12425,13.3327051 4.0613,0.943538 6.58251,5.309725 5.62575,9.749901 -0.20991,0.965457 -1.02634,1.991915 -2.12923,1.991915 z M 385.82187,10.706723 c -3.95809,0 -7.52419,3.042264 -8.47863,7.232986 -0.0638,0.281114 -0.10553,0.565474 -0.14612,0.846587 l -0.0302,0.196107 c -0.1496,0.968705 1.31975,1.261763 1.38354,1.273824 13.34592,3.077055 38.78297,8.918622 39.67594,9.037376 l 0.0417,10e-4 c 0.44417,0 0.93125,-0.518622 1.06577,-1.132107 0.83384,-3.869252 -1.3325,-7.666601 -4.82902,-8.464249 -0.0765,-0.0174 -0.15772,-0.0283 -0.23658,-0.0414 l -0.59957,-0.098 0.0104,-0.471885 c 0.13684,-6.112824 -3.76326,-11.4606687 -9.27303,-12.7148238 -0.82108,-0.1863304 -1.66187,-0.281125 -2.49917,-0.281125 -4.12857,0 -8.15276,2.3470645 -10.50001,6.1247918 l -0.32123,0.514331 -0.47664,-0.372731 c -0.9127,-0.711481 -1.94136,-1.201806 -3.05816,-1.455666 -0.56709,-0.129655 -1.15043,-0.195063 -1.72912,-0.195063 z"  id="ys-path258"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 399.73912,35.73932 v 0.0012 c -0.17975,0.798343 0.32124,1.602137 1.1226,1.783515 0,0 0.001,0 0.001,0 l 2.91899,0.664513 -5.44368,4.020014 c 0,1.16e-4 -10e-4,3.48e-4 -10e-4,5.8e-4 -0.40357,0.296769 -1.06345,0.783731 -1.46587,1.081312 l -5.73012,4.230732 v 0 c -0.0719,0.05358 -0.1322,0.0559 -0.16931,0.0472 -0.0383,-0.0081 -0.0916,-0.03688 -0.13337,-0.115623 0,-1.16e-4 0,-3.48e-4 -0.001,-5.8e-4 l -3.33068,-6.293854 c -0.16932,-0.321124 -0.4175,-0.789066 -0.62973,-1.190673 -0.0812,-0.153429 -0.15772,-0.297117 -0.22266,-0.419235 v 0 l -3.16716,-5.979689 2.91782,0.665441 v 0 l 0.003,5.8e-4 c 0.79672,0.178015 1.59808,-0.321819 1.78131,-1.123642 l 1.61432,-7.087906 v -1.16e-4 c 0.0406,-0.176276 0.22034,-0.289115 0.39546,-0.248989 l 10e-4,3.48e-4 10.90126,2.481892 c 0,0 0,1.16e-4 0,1.16e-4 0.17859,0.04105 0.28993,0.221504 0.2505,0.396736 v 0 z"  fillOpacity="1"  id="ys-path260" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"} />   
					</g>
					<g id="g12999" className="ys-envelope-1">
					  <path d="m 311.20042,65.902006 c -1.00546,0 -2.00397,-0.152502 -2.97233,-0.454374 l -24.47333,-7.639348 c -2.54208,-0.792197 -4.62375,-2.527933 -5.86116,-4.889215 -1.23857,-2.358963 -1.48211,-5.060156 -0.69003,-7.603397 l 3.24718,-10.406992 c 1.30816,-4.190723 5.13403,-7.005219 9.51889,-7.005219 1.00547,0 2.0063,0.153661 2.97465,0.455417 l 24.47217,7.634013 c 2.5444,0.794285 4.62492,2.53002 5.86349,4.890143 1.23741,2.359079 1.48211,5.059229 0.68887,7.602353 l -3.24719,10.409196 c -1.30931,4.191882 -5.13635,7.007423 -9.52121,7.007423 z M 289.97196,28.994167 c -3.9059,0 -7.31312,2.507173 -8.47863,6.240275 l -3.24718,10.406992 c -0.70626,2.265375 -0.48824,4.671306 0.61348,6.773162 1.10289,2.103015 2.9561,3.649139 5.22101,4.354125 l 24.47333,7.639348 c 0.86282,0.268125 1.75232,0.404274 2.64761,0.404274 3.90706,0 7.31545,-2.508333 8.48095,-6.240275 l 3.24719,-10.409196 c 0.70858,-2.265374 0.48939,-4.670146 -0.61349,-6.772001 -1.10288,-2.101856 -2.9561,-3.649139 -5.22217,-4.355286 l -24.47217,-7.634941 c -0.86398,-0.270328 -1.75579,-0.406477 -2.64993,-0.406477 z"  id="ys-path182"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 300.69347,52.056246 c -2.04457,-0.001 -3.90706,-1.124454 -4.85686,-2.935454 l -10.51972,-20.065509 0.52187,-0.239712 c 2.23592,-1.022051 4.76292,-1.186613 7.10669,-0.456461 l 24.47333,7.634941 c 2.33681,0.727949 4.32108,2.296919 5.58748,4.41733 l 0.2934,0.494731 -20.06527,10.5225 c -0.78165,0.410768 -1.66187,0.627634 -2.54092,0.627634 z m -13.87012,-22.471324 9.97697,19.030237 c 0.76193,1.450331 2.25332,2.351425 3.89315,2.351425 h 0.001 c 0.71322,0 1.39744,-0.169898 2.03296,-0.503314 l 19.03082,-9.978828 c -1.1284,-1.644234 -2.76706,-2.859145 -4.66319,-3.449784 l -24.47333,-7.635057 c -1.9054,-0.592727 -3.95112,-0.521869 -5.79854,0.185321 z"  id="ys-path184"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					</g>
					<g id="g12995" className="ys-small-globe-1">
					<path d="m 236.02116,109.99609 c -0.7109,0 -1.4334,-0.0446 -2.14546,-0.13406 -9.40755,-1.17014 -16.10835,-9.77611 -14.9382,-19.182852 1.06809,-8.591701 8.39165,-15.071688 17.03727,-15.071688 0.71206,0 1.4334,0.04465 2.14546,0.134062 4.55766,0.566518 8.62128,2.873294 11.44401,6.496339 2.82273,3.623046 4.06478,8.12747 3.49768,12.685472 -1.06925,8.593787 -8.39397,15.072727 -17.04076,15.072727 z m -0.0464,-33.297894 c -8.09592,0 -14.95676,6.068175 -15.95643,14.116088 -1.09592,8.810769 5.18158,16.871786 13.99189,17.967946 0.66683,0.0828 1.34294,0.12536 2.01093,0.12536 8.09709,0 14.95908,-6.06922 15.95875,-14.118289 0.53115,-4.269119 -0.6332,-8.488255 -3.27618,-11.88133 -2.64297,-3.394119 -6.45029,-5.554888 -10.71802,-6.08557 -0.66683,-0.0828 -1.34294,-0.124205 -2.01094,-0.124205 z" fill={props.darkMode? "#b3b3b3": "#000000"} id="ys-path212" fill={props.darkMode? "#b3b3b3": "#000000"} />
					</g>
					<g id="g12986" className="ys-magnifying-glass">
					  <path d="m 204.5316,155.53228 -0.96024,3.46869 1.04837,0.29109 0.96024,-3.46869 z"  id="ys-path262"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 209.80015,157.06542 -0.93704,3.40258 1.05185,0.28877 0.93705,-3.40259 z"  id="ys-path264"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 205.08942,182.33894 c -0.24702,0 -0.49172,-0.0336 -0.73178,-0.10089 l -7.01624,-1.94019 c -1.45195,-0.40474 -2.30666,-1.91352 -1.90424,-3.36548 l 4.60636,-16.64993 c 0.39315,-1.41717 1.93324,-2.30434 3.36548,-1.90772 l 7.01624,1.94019 c 1.45311,0.40358 2.30782,1.91352 1.90424,3.36547 l -4.60637,16.65226 c -0.32703,1.17942 -1.40904,2.00629 -2.63369,2.00629 z m -2.40988,-22.97382 c -0.73757,0 -1.38933,0.49635 -1.58764,1.20841 l -4.60636,16.64994 c -0.24122,0.87326 0.27369,1.78247 1.14579,2.02369 l 7.01624,1.94019 c 0.8663,0.24122 1.78827,-0.29224 2.02253,-1.14463 l 4.60752,-16.65225 c 0.24238,-0.87442 -0.27253,-1.78364 -1.14579,-2.02486 l -7.01624,-1.94135 c -0.14032,-0.0394 -0.28876,-0.0591 -0.43605,-0.0591 z"  id="ys-path266"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 211.4365,157.78559 c -1.27916,0 -2.55716,-0.17395 -3.79804,-0.51723 -7.56014,-2.09327 -12.00879,-9.94566 -9.91667,-17.50232 1.70013,-6.14413 7.33284,-10.43505 13.70079,-10.43505 1.278,0 2.55716,0.17511 3.79921,0.51839 7.55898,2.09095 12.00878,9.94334 9.91667,17.50348 -1.69898,6.14181 -7.33516,10.43273 -13.70196,10.43273 z m -0.0128,-27.36564 c -5.87972,0 -11.08101,3.96157 -12.65126,9.63486 -1.92975,6.97913 2.17678,14.22962 9.15706,16.16286 1.14695,0.31776 2.3287,0.47896 3.50812,0.47896 5.87856,0 11.08218,-3.96273 12.65242,-9.63486 1.93092,-6.98145 -2.17677,-14.23194 -9.1559,-16.16286 -1.15043,-0.31776 -2.32985,-0.47896 -3.51044,-0.47896 z"  id="ys-path268"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					  <path d="m 219.97312,145.92062 c -1.30584,4.71769 -6.18937,7.48476 -10.90822,6.18008 -4.71886,-1.30467 -7.48476,-6.1882 -6.18009,-10.90706 1.30699,-4.71885 6.18936,-7.48243 10.90938,-6.17892 4.71537,1.30699 7.4836,6.1882 6.17893,10.9059 z"  fillOpacity="1" id="ys-path270" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								   
					</g>
					<g className="ys-message">
						<path d="m 215.30181,246.4743 c -0.087,1.46587 -1.34526,2.58035 -2.80765,2.49222 -1.46588,-0.087 -2.5792,-1.34643 -2.49106,-2.80882 0.0858,-1.46471 1.3441,-2.57919 2.80766,-2.49105 1.46471,0.087 2.57803,1.34526 2.49105,2.80765 z"  fillOpacity="1" id="ys-path190" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 224.71748,247.04256 c -0.0881,1.46355 -1.34758,2.57803 -2.81113,2.49105 -1.46471,-0.0893 -2.57919,-1.34642 -2.48989,-2.80997 0.087,-1.46355 1.34642,-2.57919 2.80997,-2.49105 1.46355,0.087 2.57803,1.34526 2.49105,2.80997 z"  fillOpacity="1" id="ys-path192" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />
						<path d="m 229.55579,261.81029 c -0.0986,1.60967 1.12491,2.98973 2.73459,3.08946 1.60852,0.0951 2.98857,-1.13072 3.08714,-2.73807 0.0974,-1.60736 -1.12839,-2.98973 -2.73691,-3.08831 -1.60735,-0.0939 -2.98857,1.12956 -3.08482,2.73692 z"  fillOpacity="1" id="ys-path194" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 234.06937,269.4087 c -0.0731,1.22233 0.85935,2.27534 2.08052,2.34841 1.22581,0.0719 2.27651,-0.85935 2.34957,-2.08168 0.0731,-1.22465 -0.85703,-2.27651 -2.08168,-2.34957 -1.22349,-0.0742 -2.27651,0.85818 -2.34841,2.08284 z"  fillOpacity="1" id="ys-path196" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 198.37007,267.70972 c -0.18092,0 -0.36067,-0.006 -0.54159,-0.0162 l -22.68216,-1.36614 c -2.37322,-0.14264 -4.54919,-1.20146 -6.12697,-2.98045 -1.57778,-1.78015 -2.36673,-4.0671 -2.22293,-6.43986 l 0.5787,-9.6453 c 0.29305,-4.87309 4.48482,-8.66302 9.42089,-8.3499 l 22.67926,1.36382 c 2.37392,0.14264 4.55185,1.20146 6.1279,2.98045 1.57836,1.77899 2.36812,4.06594 2.22432,6.44218 l -0.5787,9.6453 c -0.28297,4.69102 -4.18422,8.36613 -8.87872,8.36613 z m -22.11889,-27.72631 c -4.11882,0 -7.5402,3.22631 -7.78861,7.34328 l -0.57869,9.64529 c -0.12629,2.08284 0.56663,4.08914 1.95156,5.65126 1.38492,1.56097 3.29392,2.49106 5.37733,2.6163 l 22.68066,1.36498 c 4.31527,0.2621 8.01126,-3.05351 8.26872,-7.3282 l 0.57869,-9.64645 c 0.12641,-2.084 -0.5671,-4.09145 -1.95179,-5.65242 -1.38353,-1.56097 -3.29473,-2.48989 -5.37757,-2.61514 l -22.67984,-1.36382 c -0.16235,-0.009 -0.32135,-0.0151 -0.48046,-0.0151 z"  id="ys-path198" fill={props.darkMode? "#b3b3b3": "#000000"}  />
						<path d="m 174.92435,271.47994 c -0.0959,1.60851 -1.47978,2.83432 -3.08482,2.73807 -1.60933,-0.0974 -2.83514,-1.47863 -2.73714,-3.08715 0.0959,-1.60851 1.47758,-2.832 3.08691,-2.73691 1.60503,0.0963 2.83201,1.47979 2.73505,3.08599 z"  fillOpacity="1" id="ys-path200" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 180.54778,252.89329 c -0.0861,1.46587 -1.34561,2.57919 -2.81009,2.49105 -1.46332,-0.0858 -2.57803,-1.3441 -2.49094,-2.80881 0.0894,-1.46471 1.34689,-2.58035 2.81021,-2.49106 1.46228,0.0881 2.57908,1.34643 2.49082,2.80882 z"  fillOpacity="1" id="ys-path202" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />
						<path d="m 189.96218,253.46039 c -0.087,1.46471 -1.34526,2.57919 -2.80882,2.48989 -1.46587,-0.087 -2.57895,-1.34526 -2.49186,-2.80997 0.0872,-1.46472 1.34723,-2.57804 2.80963,-2.4899 1.46471,0.0893 2.57919,1.34642 2.49105,2.80998 z"  fillOpacity="1" id="ys-path204" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 199.37785,254.02864 c -0.0893,1.46355 -1.34642,2.57803 -2.80997,2.48874 -1.46471,-0.087 -2.58035,-1.34295 -2.49337,-2.80766 0.0905,-1.46471 1.34874,-2.57919 2.81229,-2.49337 1.46471,0.0893 2.57919,1.34642 2.49105,2.81229 z"  fillOpacity="1" id="ys-path206" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 169.53125,278.48342 c -0.0709,1.22349 -1.12446,2.15705 -2.34818,2.08167 -1.22581,-0.0742 -2.15636,-1.12491 -2.0833,-2.34841 0.0741,-1.22465 1.1255,-2.1559 2.35026,-2.08167 1.22257,0.0719 2.15753,1.12259 2.08122,2.34841 z"  fillOpacity="1" id="ys-path208" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 223.71665,260.7248 c -0.18207,0 -0.3653,-0.006 -0.54854,-0.0162 l -16.71488,-1.00662 0.065,-1.08665 16.71604,1.00547 c 2.06892,0.12409 4.08913,-0.5671 5.6501,-1.95179 1.56212,-1.38469 2.48989,-3.29473 2.61398,-5.37873 l 0.58217,-9.64414 c 0.25746,-4.29904 -3.03032,-8.00778 -7.32936,-8.26872 l -22.67925,-1.36381 c -4.31644,-0.26558 -8.00895,3.02915 -8.26873,7.32935 l -1.0878,-0.0661 c 0.29688,-4.89977 4.54258,-8.64679 9.42147,-8.35106 l 22.67926,1.36382 c 4.89861,0.29688 8.64678,4.52286 8.35222,9.42263 l -0.58217,9.64413 c -0.14149,2.37277 -1.2003,4.5507 -2.9793,6.1279 -1.64214,1.45428 -3.7157,2.24056 -5.89016,2.24056 z"  id="ys-path210" fill={props.darkMode? "#b3b3b3": "#000000"} />
					</g>
					<g id="g12979" className="ys-mobile">
						<path d="m 201.98836,371.79008 c 0.89645,1.26756 2.50729,1.9228 4.1042,1.52734 l 16.89232,-4.19119 c 1.58184,-0.3943 2.69284,-1.70361 2.90739,-3.22283 -3.42578,-3.01756 -10.28662,-2.70096 -11.49503,-6.499 4.25845,-9.22433 -0.78397,-14.83384 -6.03976,-13.55236 -5.25232,1.28264 -7.15077,8.57953 0.87094,14.81064 0.67959,3.95113 -5.62923,6.833 -7.24006,11.1274 z"  fillOpacity="1" id="ys-path246" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 204.07003,382.05698 c -2.54788,0 -4.75944,-1.73028 -5.37525,-4.20974 l -9.12342,-36.78131 c -0.35719,-1.43688 -0.13337,-2.92711 0.63088,-4.19467 0.76309,-1.26872 1.97498,-2.16402 3.41186,-2.52005 l 22.18638,-5.50513 c 0.43953,-0.10902 0.88834,-0.16352 1.33715,-0.16352 2.55136,0 4.76292,1.73028 5.37641,4.20858 l 9.12806,36.78131 c 0.73526,2.9677 -1.08201,5.9783 -4.04854,6.71356 l -22.18522,5.50629 c -0.43837,0.10902 -0.88834,0.16468 -1.33831,0.16468 z m 13.0676,-52.2843 c -0.36067,0 -0.72134,0.0452 -1.07505,0.13221 l -22.18523,5.5063 c -1.15506,0.28529 -2.12806,1.00314 -2.74155,2.02253 -0.61348,1.01938 -0.79324,2.21504 -0.50679,3.37011 l 9.12343,36.78132 c 0.49403,1.99238 2.26955,3.38287 4.31759,3.38287 0.35951,0 0.7225,-0.0452 1.07621,-0.13221 l 22.18407,-5.50514 c 2.38436,-0.59145 3.84327,-3.0106 3.25414,-5.39264 l -9.12923,-36.78132 c -0.49171,-1.99354 -2.26839,-3.38403 -4.31759,-3.38403 z"  id="ys-path248"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
						<path d="m 205.15436,373.97729 c -2.03877,0 -3.805,-1.38237 -4.29556,-3.36315 l -6.88867,-27.76806 c -0.28645,-1.14579 -0.10669,-2.33565 0.50331,-3.34924 0.61001,-1.01475 1.57837,-1.72913 2.72648,-2.01441 l 16.89347,-4.19235 c 0.3514,-0.087 0.7109,-0.13221 1.07041,-0.13221 2.03645,0 3.80269,1.38354 4.2944,3.36316 l 6.88867,27.76922 c 0.28413,1.14695 0.10554,2.33797 -0.50447,3.3504 -0.61001,1.01358 -1.57952,1.72796 -2.72763,2.01209 l -16.89116,4.19235 c -0.35139,0.0881 -0.7109,0.1322 -1.06925,0.1322 z m 10.00944,-39.72813 c -0.27137,0 -0.54274,0.0336 -0.80831,0.0986 l -16.89348,4.19351 c -0.86514,0.21455 -1.59576,0.75265 -2.05616,1.51806 -0.4604,0.76309 -0.59493,1.65954 -0.37922,2.52352 l 6.88866,27.76806 c 0.4349,1.75464 2.26839,2.87608 4.04623,2.43655 l 16.89231,-4.19118 c 0.86514,-0.21455 1.59576,-0.75266 2.05616,-1.51806 0.45925,-0.76425 0.59494,-1.66071 0.38039,-2.52585 l -6.88867,-27.76922 c -0.37227,-1.49138 -1.70361,-2.53396 -3.23791,-2.53396 z"  id="ys-path250"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
						<path d="m 217.2536,374.29157 c 0.25282,1.01591 -0.36647,2.04689 -1.38469,2.29739 -1.01591,0.25165 -2.04457,-0.36763 -2.29507,-1.3847 -0.25281,-1.0159 0.36531,-2.04572 1.38354,-2.29738 1.01474,-0.2505 2.0434,0.36763 2.29622,1.38469 z"  fillOpacity="1" id="ys-path252" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								   
					</g>
					<g id="g12973" className="ys-small-globe-2">
						<path d="m 239.31705,434.74486 c -6.88982,0 -12.84029,-4.68174 -14.46968,-11.38602 -0.94052,-3.86879 -0.31776,-7.87094 1.75116,-11.27237 2.07008,-3.40026 5.34046,-5.79042 9.20925,-6.73094 1.16086,-0.28181 2.34956,-0.42446 3.53247,-0.42446 6.89099,0 12.84145,4.68175 14.472,11.38602 0.94052,3.86879 0.31892,7.8721 -1.75116,11.27237 -2.07008,3.40142 -5.34162,5.79042 -9.21156,6.73095 -1.16087,0.2818 -2.34957,0.42445 -3.53248,0.42445 z m 0.0232,-28.72366 c -1.09709,0 -2.19881,0.13221 -3.27502,0.3943 -3.58466,0.87094 -6.6173,3.08598 -8.53661,6.23923 -1.91816,3.15209 -2.49454,6.862 -1.6236,10.44666 1.5111,6.21603 7.02552,10.55566 13.41203,10.55566 1.09593,0 2.19881,-0.13336 3.27386,-0.3943 3.58582,-0.8721 6.61962,-3.08714 8.53777,-6.23923 1.91932,-3.15209 2.49686,-6.86316 1.6236,-10.44897 -1.50994,-6.21372 -7.02552,-10.55335 -13.41203,-10.55335 z"  id="ys-path229" fill={props.darkMode? "#b3b3b3": "#000000"} />
					</g>
					<g id="g12964" className="ys-cloud-2">
						<path d="m 288.05844,467.28512 c -1.29539,0 -2.18025,-0.5729 -2.48989,-1.61431 l -0.0754,-0.24934 c -0.10438,-0.34444 -0.20875,-0.69003 -0.28993,-1.04954 -1.45312,-6.52336 2.28463,-12.92611 8.33135,-14.27369 1.30467,-0.29109 2.65805,-0.32936 3.98823,-0.11017 0.98576,-6.69268 5.70925,-12.05169 11.90905,-13.43291 1.04954,-0.23426 2.12459,-0.35255 3.19384,-0.35255 6.06179,0 11.70609,3.74818 14.50563,9.58963 0.0174,-0.003 0.0336,-0.008 0.051,-0.0116 0.60769,-0.13568 1.22581,-0.20294 1.84161,-0.20294 4.24106,0 8.08781,3.26805 9.14547,7.76888 0.28412,1.20958 -0.15309,2.73344 -1.37542,3.1892 -0.91269,0.3398 -42.8663,9.56876 -47.64778,10.62061 0,0 -0.48476,0.12873 -1.08781,0.12873 z m 7.74801,-16.34725 c -0.6819,0 -1.36729,0.0742 -2.03528,0.22382 -5.46107,1.2177 -8.82771,7.03711 -7.50564,12.9725 0.0742,0.33052 0.1728,0.65292 0.27021,0.97068 l 0.0754,0.25513 c 0.0626,0.20643 0.24933,0.83499 1.44615,0.83499 0.45461,0 0.82687,-0.0951 0.83151,-0.0962 16.02949,-3.52668 46.5623,-10.26806 47.53066,-10.58582 0.61348,-0.22846 0.85586,-1.19334 0.68654,-1.91584 -0.944,-4.01491 -4.3431,-6.92926 -8.08432,-6.92926 -0.53579,0 -1.07505,0.0603 -1.60388,0.1786 -0.0963,0.0209 -0.19251,0.051 -0.28877,0.0789 l -0.60305,0.17279 -0.19367,-0.43025 c -2.54788,-5.69533 -7.93009,-9.37508 -13.70891,-9.37508 -0.99039,0 -1.98542,0.11017 -2.95726,0.32704 -5.92495,1.31974 -10.40142,6.57554 -11.14016,13.07571 l -0.0684,0.60189 -0.59029,-0.13105 c -0.67843,-0.15308 -1.3731,-0.22846 -2.06081,-0.22846 z"  id="ys-path254"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
						<path d="m 310.95282,471.59616 v 10e-4 c 0.17975,0.79834 -0.32124,1.60214 -1.1226,1.78352 0,0 -10e-4,0 -10e-4,0 l -2.91899,0.66451 5.44368,4.02001 c 0,1.2e-4 0.001,3.5e-4 0.001,5.8e-4 0.40357,0.29677 1.06345,0.78373 1.46587,1.08131 l 5.73012,4.23074 v 0 c 0.0719,0.0536 0.1322,0.0559 0.16931,0.0472 0.0383,-0.008 0.0916,-0.0369 0.13337,-0.11563 0,-1.1e-4 0,-3.5e-4 10e-4,-5.8e-4 l 3.33068,-6.29385 c 0.16932,-0.32113 0.4175,-0.78907 0.62973,-1.19067 0.0812,-0.15343 0.15772,-0.29712 0.22266,-0.41924 v 0 l 3.16716,-5.97969 -2.91782,0.66544 v 0 l -0.003,5.8e-4 c -0.79672,0.17802 -1.59808,-0.32182 -1.78131,-1.12364 l -1.61432,-7.0879 v -1.2e-4 c -0.0406,-0.17628 -0.22034,-0.28912 -0.39546,-0.24899 l -0.001,3.5e-4 -10.90126,2.48189 c 0,0 0,1.2e-4 0,1.2e-4 -0.17859,0.041 -0.28993,0.2215 -0.2505,0.39673 v 0 z"  fillOpacity="1" fill="#ff5f5f" stroke={props.darkMode? "#b3b3b3": "#000000"} id="ys-path260-3"  />								   
					</g>
					<g id="g12960" className="ys-envelope-2">
						<path d="m 392.32783,505 c -3.70643,0 -7.08234,-2.3287 -8.40209,-5.79274 l -3.4652,-9.12111 c -0.85239,-2.24404 -0.77933,-4.68522 0.20526,-6.87359 0.98344,-2.19069 2.76127,-3.86415 5.00415,-4.71654 l 21.44301,-8.14463 c 1.02634,-0.38966 2.09907,-0.58797 3.18804,-0.58797 3.70642,0 7.08118,2.32753 8.3986,5.7939 l 3.46521,9.11879 c 1.75696,4.62955 -0.57869,9.82853 -5.20593,11.59012 l -21.44765,8.1458 C 394.48721,504.80169 393.41564,505 392.32783,505 Z m 17.97433,-34.14762 c -0.95676,0 -1.89961,0.17396 -2.80186,0.51607 l -21.44301,8.14696 c -1.97151,0.74801 -3.53363,2.21968 -4.39762,4.14363 -0.86514,1.92396 -0.92892,4.06826 -0.17975,6.04093 l 3.46405,9.11994 c 1.15971,3.0454 4.1274,5.09228 7.38386,5.09228 0.9556,0 1.89729,-0.17395 2.79838,-0.51723 l 21.44649,-8.14463 c 4.06594,-1.54821 6.11862,-6.11746 4.57505,-10.18456 l -3.46405,-9.11879 c -1.15855,-3.04655 -4.12508,-5.0946 -7.38154,-5.0946 z"  id="ys-path186"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
						<path d="m 401.20424,492.05533 c -0.70394,0 -1.38701,-0.14728 -2.03297,-0.43721 l -18.54606,-8.33134 0.26326,-0.51143 c 1.01706,-1.97151 2.71488,-3.49073 4.78148,-4.27933 l 21.44417,-8.14463 c 2.05848,-0.78396 4.34426,-0.77353 6.41434,0.0232 l 0.53695,0.20643 -8.33483,18.54838 c -0.79903,1.77783 -2.57571,2.92594 -4.52634,2.92594 z m -19.06329,-9.28346 17.47797,7.85238 c 0.50447,0.22615 1.0391,0.34212 1.58648,0.34212 1.5227,0 2.90971,-0.89762 3.53363,-2.28463 l 7.85471,-17.47913 c -1.66534,-0.50795 -3.45941,-0.45576 -5.08996,0.167 l -21.44417,8.1458 c -1.63635,0.62276 -3.0106,1.76855 -3.91866,3.25646 z"  id="ys-path188"  fill={props.darkMode? "#b3b3b3": "#000000"}/>
					</g>
					<g id="g13047" className="why-us-woman"transform="matrix(1.7472471,0,0,1.7472471,-112.59148,-412.78091)" >
						<g className="ys-woman-hand">								         
							<path d="m 139.91913,347.87805 c 0,0 -0.16421,3.1173 0.16422,5.74172 0.32843,2.62558 -1.47666,5.49586 -2.37891,6.15225 -0.90214,0.6564 -5.41387,2.13271 -5.41387,2.13271 l -0.73804,-3.93721 0.65628,-1.63983 -0.90225,-5.98758 c 0,0 0.65628,-1.88684 1.47666,-3.19963 0.82049,-1.31279 3.03507,-2.70676 3.19859,-2.95262 0.16421,-0.24586 2.87086,0.3282 3.11695,0.3282 0.24597,0 1.47654,0.49287 1.47654,0.49287 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path274" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />
							<path d="m 141.31449,343.87242 c 0,0 1.4087,0.11481 1.58115,0.67495 0.17245,0.55898 -0.41854,3.78181 -0.76958,4.08101 -0.35186,0.29805 -1.30908,0.002 -1.31105,-1.30699 -0.002,-1.30931 0.13047,-2.93406 0.49948,-3.44897 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path276" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								         
							<path d="m 139.71421,343.4874 c 0,0 1.53581,0 1.77215,0.59029 0.23635,0.59145 -0.11817,4.13552 -0.47269,4.49039 -0.35464,0.35487 -1.41763,0.11829 -1.53581,-1.30003 -0.11817,-1.41833 -0.11817,-3.19036 0.23635,-3.78065 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path278" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								         
							<path d="m 138.07856,342.89247 c 0,0 1.73156,0 1.99818,0.66567 0.26661,0.66567 -0.13325,4.66203 -0.53254,5.06213 -0.39987,0.4001 -1.59819,0.13336 -1.73156,-1.46472 -0.13395,-1.59923 -0.13395,-3.59625 0.26592,-4.26308 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path280" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								         
							<path d="m 136.50774,342.90174 c 0,0 1.73156,0 1.99817,0.66568 0.26662,0.66567 -0.13325,4.66202 -0.53242,5.06212 -0.39998,0.4001 -1.59831,0.13337 -1.73168,-1.46471 -0.13394,-1.59924 -0.13394,-3.59741 0.26593,-4.26309 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path282" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								         
							<path d="m 132.11256,355.02302 c 0,0 -0.66579,-3.46289 -0.79916,-5.3277 -0.13325,-1.86481 1.99829,-4.12973 2.53072,-4.79656 0.53253,-0.66567 0.53253,-3.06395 1.46506,-3.06395 0.9324,0 1.19902,1.06577 1.19902,1.06577 0,0 0.53254,1.73261 -0.39987,2.66501 -0.93252,0.93241 -1.86493,2.39712 -1.86493,2.39712 0,0 2.79734,5.3277 2.79734,6.79357 0,1.46588 -2.66409,3.19732 -2.66409,3.19732"  id="ys-path284" fill="#ffffff"  />
							<path d="m 131.71756,357.63932 0.73873,6.39811 -17.55358,5.74288 0.16422,-9.59775 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path288" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								         
							<path d="m 132.11256,355.02302 c 0,0 -0.66579,-3.46289 -0.79916,-5.3277 -0.13325,-1.86481 1.99829,-4.12973 2.53072,-4.79656 0.53253,-0.66567 0.53253,-3.06395 1.46506,-3.06395 0.9324,0 1.19902,1.06577 1.19902,1.06577 0,0 0.53254,1.73261 -0.39987,2.66501 -0.93252,0.93241 -1.86493,2.39712 -1.86493,2.39712 0,0 2.79734,5.3277 2.79734,6.79357 0,1.46588 -2.66409,3.19732 -2.66409,3.19732"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path286" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />
						</g>
						<path d="m 77.610165,314.01225 c 4.15585,-3.49885 3.35252,-9.07472 3.35252,-9.07472 0,0 16.989381,15.41832 17.864149,20.44798 0.874768,5.03197 -11.811863,23.18605 -13.779772,26.02965 -0.241915,0.34908 -0.560719,0.62741 -0.937972,0.84311 0.532538,1.30931 0.902949,2.82389 0.885089,4.73277 -0.0523,5.57704 -6.35468,3.6009 -8.89639,1.90656 -2.541722,-1.69433 -1.906132,-4.65971 -1.906132,-4.65971 0,0 3.177311,1.26989 3.177311,-0.21222 0,-1.48211 -2.965664,-3.81312 -2.965664,-3.81312 l 0.07352,-0.051 c -0.619806,-0.47896 -1.19356,-1.00663 -1.679362,-1.58996 -3.281082,-3.93721 2.187141,-8.31163 3.28107,-10.71687 1.093919,-2.40639 -0.874721,-4.5936 -4.812013,-7.87558 -3.93728,-3.28081 2.187837,-12.46686 6.343641,-15.96686 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path272" fill={props.darkMode? "#3a3a3a": "#263238"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  className="ys-woman-hair-1"/>		
						<path d="m 102.76254,327.45559 c 0,0 9.77796,2.92594 10.43424,5.98873 0.65617,3.06163 2.69006,12.38453 2.69006,12.38453 0,0 2.4057,6.34244 2.18721,9.62326 -0.21849,3.28081 -2.18721,9.84361 -1.09395,14.21803 1.09395,4.37442 4.81198,17.49884 5.68675,21.87326 0.87476,4.37442 1.31244,6.99884 1.31244,6.99884 l -3.49965,0.65639 -4.81198,0.6564 c 0,0 -1.96861,-13.12442 -3.49954,-24.49884 -1.53093,-11.37326 2.4057,-19.24769 -1.31244,-26.6849 -3.71814,-7.43721 -12.686632,-14.87442 -12.030353,-17.93605 0.656163,-3.06163 3.937213,-3.27965 3.937213,-3.27965 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path290" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 120.48126,390.66782 c 0,0 1.31244,15.96802 -1.31244,27.7785 -2.62489,11.81279 -5.68675,19.90523 -5.68675,19.90523 0,0 -3.06256,19.24884 -5.24977,26.0285 -2.18709,6.78082 -2.84337,13.34361 -2.40558,18.59245 0.43767,5.25 4.15581,12.03081 5.90593,13.3436 1.75012,1.31163 5.24965,4.37442 0.87465,5.46803 -4.3743,1.0936 -10.0273,-1.42065 -12.686513,-1.53082 -5.249768,-0.21918 -5.905931,-3.71918 -5.905931,-6.99884 0,-3.28197 3.062558,-14.43605 2.187094,-20.12442 -0.874652,-5.68605 -1.093953,-24.49768 -0.218489,-29.5285 0.874768,-5.03081 1.312442,-9.62442 1.312442,-9.62442 l 0.656163,-40.90292 21.654424,-3.06163 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path292" fill={props.darkMode? "#3a3a3a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />								      
						<path d="m 96.333809,476.55699 c 5.049721,2.18953 7.884161,1.78943 9.441191,0.88486 -0.10507,1.95527 -0.0928,3.81776 0.0502,5.53181 0.43768,5.24884 4.15582,12.02965 5.90593,13.34244 1.75012,1.31279 5.24966,4.37442 0.87465,5.46803 -4.3743,1.0936 -10.0273,-1.42065 -12.68651,-1.53082 -5.249769,-0.21918 -5.905932,-3.71802 -5.905932,-6.99884 0,-2.70676 2.080633,-10.76441 2.320461,-16.69748 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path294" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 94.034571,492.74188 c 0.0023,-0.0359 0.0046,-0.0707 0.0081,-0.10785 0.0145,-0.18208 0.03305,-0.37923 0.05706,-0.58913 0.0046,-0.0441 0.0116,-0.0893 0.01647,-0.13453 0.02482,-0.20411 0.0523,-0.41634 0.08454,-0.64248 0.0035,-0.0244 0.0058,-0.0476 0.0093,-0.0707 0.03433,-0.23658 0.07352,-0.48824 0.114695,-0.74569 0.01241,-0.0789 0.02482,-0.15656 0.03781,-0.23658 0.0392,-0.2389 0.08048,-0.4836 0.123741,-0.73642 0.02331,-0.13568 0.04743,-0.27369 0.07144,-0.41286 0.0058,-0.029 0.01044,-0.0591 0.01577,-0.0893 0.306511,0.305 0.626707,0.63088 0.970908,0.99619 3.499653,3.71802 8.311635,7.87558 13.342795,7.87558 2.39677,0 3.00492,-0.69583 2.98636,-1.42412 1.78723,1.36613 4.99209,4.29556 0.73248,5.36133 -4.37431,1.0936 -10.02742,-1.42065 -12.686634,-1.53082 -5.249653,-0.21918 -5.905932,-3.71918 -5.905932,-7 0,-0.15308 0.0093,-0.3282 0.02134,-0.51259 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path296" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 98.388696,497.19052 c 4.155814,3.28082 8.530814,3.71803 12.248954,3.71803 2.87353,0 3.65482,-1.17247 3.86241,-1.70709 0.50158,1.04142 0.24598,2.04688 -1.8938,2.58267 -4.37431,1.0936 -10.02742,-1.42065 -12.686632,-1.53082 -4.945229,-0.20643 -5.813851,-3.32372 -5.897698,-6.42826 1.051392,0.78744 2.511581,1.90076 4.366766,3.36547 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path298" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 106.48265,414.94747 c 0,0 -2.18709,27.7785 -3.93721,35.21571 -1.75012,7.43605 -5.468258,33.24653 -5.468258,36.74653 0,3.49884 2.406395,8.53081 3.718138,10.71802 1.31233,2.18721 4.81198,2.62442 4.81198,4.59361 0,1.96802 -6.999187,1.96802 -8.311629,2.18721 -1.312442,0.21802 -10.280236,-2.18721 -10.499421,-3.28198 -0.218489,-1.0936 -0.218489,-5.68605 -0.218489,-5.68605 0,0 -0.218489,-2.18721 0,-3.71802 0.218489,-1.53082 2.843374,-6.12442 2.843374,-6.12442 0,0 -2.406396,-21.43606 -3.062559,-24.93606 -0.656279,-3.49884 -0.437674,-10.71802 -0.218489,-13.99884 0.218489,-3.28081 2.187095,-9.8436 2.187095,-9.8436 L 83.952877,402.04224 84.390551,390.887 c 0,0 6.124421,0.6564 12.90512,0.6564 6.780699,0 11.592679,0 11.592679,0 l -0.43767,8.31163"  id="ys-path300" fill={props.darkMode? "#3a3a3a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#000000"}  />						   
						<path d="m 106.48265,414.94747 c 0,0 -2.18709,27.7785 -3.93721,35.21571 -1.75012,7.43605 -5.468258,33.24653 -5.468258,36.74653 0,3.49884 2.406395,8.53081 3.718138,10.71802 1.31233,2.18721 4.81198,2.62442 4.81198,4.59361 0,1.96802 -6.999187,1.96802 -8.311629,2.18721 -1.312442,0.21802 -10.280236,-2.18721 -10.499421,-3.28198 -0.218489,-1.0936 -0.218489,-5.68605 -0.218489,-5.68605 0,0 -0.218489,-2.18721 0,-3.71802 0.218489,-1.53082 2.843374,-6.12442 2.843374,-6.12442 0,0 -2.406396,-21.43606 -3.062559,-24.93606 -0.656279,-3.49884 -0.437674,-10.71802 -0.218489,-13.99884 0.218489,-3.28081 2.187095,-9.8436 2.187095,-9.8436 L 83.952877,402.04224 84.390551,390.887 c 0,0 6.124421,0.6564 12.90512,0.6564 6.780699,0 11.592679,0 11.592679,0 l -0.43767,8.31163"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path302" fill={props.darkMode? "#3a3a3a": "#e8e8e8"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 86.797526,501.12773 c -0.218489,-1.0936 -0.218489,-5.68605 -0.218489,-5.68605 0,0 -0.218489,-2.18721 0,-3.71802 0.218489,-1.53198 2.843373,-6.12442 2.843373,-6.12442 0,0 -0.358698,-3.195 -0.836266,-7.31545 4.249288,1.6897 7.881379,0.23079 9.501724,-0.65407 -0.603976,4.54374 -1.00941,8.12724 -1.00941,9.28115 0,3.5 2.406395,8.53081 3.718142,10.71802 1.31233,2.18721 4.81198,2.62442 4.81198,4.59361 0,1.96918 -6.999191,1.96918 -8.311633,2.18721 -1.313138,0.21802 -10.280932,-2.18837 -10.499421,-3.28198 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path304" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"} />
						<path d="m 86.796018,501.12773 c -0.218489,-1.0936 -0.218489,-5.68605 -0.218489,-5.68605 0,0 -0.218489,-2.18721 0,-3.71918 0.126409,-0.88254 1.053364,-2.78562 1.815408,-4.23642 0.05219,0.10206 0.09962,0.19367 0.153197,0.29921 1.312443,2.62558 6.93181,9.45858 9.556694,10.98939 2.624882,1.53198 3.348422,-0.27137 3.348422,-0.27137 h 0.0681 c 1.56456,1.47515 3.93385,2.05269 4.08774,3.71919 0.28923,3.12077 -8.273128,2.91435 -9.518191,2.50729 -1.26501,-0.41518 -9.074372,-2.50845 -9.292861,-3.60206 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path306" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />			
						<path d="m 86.796018,501.12773 c -0.1416,-0.70626 -0.191004,-2.86796 -0.208863,-4.31411 6.057041,4.74784 12.338834,5.64662 15.017995,5.81362 2.52457,0.15772 3.55323,-0.96024 3.73322,-1.18407 0.14496,0.23426 0.24191,0.48824 0.2687,0.77817 0.28923,3.11961 -8.273128,2.91435 -9.518191,2.50729 -1.26501,-0.41402 -9.074372,-2.50729 -9.292861,-3.6009 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path308" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 104.07625,328.54803 c 0,0 8.53082,16.62442 10.49873,21.21687 1.96861,4.5936 1.09395,12.03081 1.09395,14.65524 0,2.62442 4.81198,26.24768 4.81198,26.24768 0,0 -4.375,4.5936 -15.96768,4.15639 -11.592675,-0.43837 -18.591864,-3.28197 -18.591864,-3.28197 0,0 2.624884,-21.65408 2.624884,-26.24768 0,-4.59361 -5.686746,-24.2785 -5.031163,-29.5285 0.656163,-5.25 8.530816,-4.81163 10.499421,-5.25 1.968605,-0.43605 10.061742,-1.96803 10.061742,-1.96803 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path310" fill={props.darkMode? "#3a3a3a": "#ffffff"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 113.86756,297.51655 c 0,0 2.44826,8.39513 1.5743,9.44467 -0.87477,1.04837 -1.74884,3.49768 -1.74884,3.49768 0,0 2.62349,5.07256 1.92396,5.77187 -0.69942,0.6993 -2.27373,1.22465 -2.27373,1.22465 0,0 -1.57418,7.52071 -2.97314,7.86978 -1.39907,0.35023 -9.6199,-1.92395 -9.6199,-1.92395 0,0 -4.897216,-4.37211 -2.97326,-12.24305 1.923957,-7.86978 9.6199,-13.46769 11.01885,-14.167 1.39838,-0.6993 5.07176,0.52535 5.07176,0.52535 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path312" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 109.4943,310.10983 v 1.92395 c 0,0 -2.16727,0.13221 -3.21657,-0.56709"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path314" fill="#ffffff"  stroke={props.darkMode? "#3a3a3a": "#263238"}  />								      
						<path d="m 110.22584,309.22265 c 0,0 -1.85797,-1.08433 -3.79294,0.0765"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path316"stroke={props.darkMode? "#3a3a3a": "#263238"}  />    
						<path d="m 112.16186,309.22149 c 0,0 -0.77445,-1.62475 -3.79294,-1.62475 -3.01931,0 -4.72211,0.92892 -4.95428,1.47051 -0.23229,0.54274 0.0776,0.92892 0.0776,0.92892 0,0 0,3.17413 0.85145,4.02535 0.85135,0.85123 3.94823,1.70361 5.49633,1.00663 1.54809,-0.69698 2.39955,-3.71571 2.39955,-4.41269 0,-0.69699 -0.0777,-1.39397 -0.0777,-1.39397 z"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path318" fill="transparent"  stroke={props.darkMode? "#ff5f5f": "#263238"}  />								      
						<path d="M 103.41371,309.06725 H 102.1748"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path320"stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 112.16186,309.22265 c 0,0 1.3158,-1.08433 1.85797,0.23194"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path322"stroke={props.darkMode? "#ff5f5f": "#263238"}  />				
						<path d="m 114.56048,309.22265 c 0,0 1.08364,-0.3108 2.39956,-0.3108 1.3158,0 1.70338,0.54274 1.62579,0.77468 -0.0777,0.23194 -0.46458,1.39397 -0.46458,2.2452 0,0.85122 -0.0776,2.16749 -0.85134,2.55484 -0.77445,0.38618 -1.70338,0.46388 -1.70338,0.46388"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path324" fill="transparent"  stroke={props.darkMode? "#ff5f5f": "#263238"}  />	
						<path d="m 96.859273,329.86082 c 0,0 5.905927,14.87326 7.436867,23.18489 1.53093,8.31164 0.87476,51.40176 0.87476,51.40176 0,0 -5.249765,2.84361 -12.686627,2.4064 -7.436863,-0.43721 -15.092226,-5.25 -15.092226,-5.25 0,0 1.750139,-12.68605 4.593501,-21.65408 2.843373,-8.96802 3.937326,-13.12326 3.937326,-13.12326 0,0 -5.68677,-29.09129 -5.249769,-32.15408 0.436979,-3.06163 16.186168,-4.81163 16.186168,-4.81163 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path326" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />	    
						<path d="m 108.09291,289.29538 c 0,0 8.5707,2.4493 8.5707,3.84791 0,1.39977 0.34977,3.32373 -5.42221,7.34675 -5.77187,4.02187 -9.96967,7.69583 -10.31944,12.59328 -0.34965,4.89629 4.54745,17.83979 5.59676,22.03678 1.0493,4.19814 0.525,10.32025 -2.79873,11.19467 -3.32291,0.87442 -2.27372,0.35023 -1.92395,-1.57373 0.34976,-1.92395 -1.57419,-9.97001 -7.346177,-12.59327 -5.771986,-2.62442 -12.026873,-6.82837 -13.292579,-14.69235 -1.321419,-8.20842 -0.790249,-20.08035 7.695825,-26.58516 8.58509,-6.58134 19.239801,-1.57488 19.239801,-1.57488 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path328" fill={props.darkMode? "#3a3a3a": "#263238"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  className="ys-woman-hair-2"/>								      
						<path d="m 92.26416,346.04687 2.187094,20.12327 1.312442,0.43721 v 1.09476 l 1.312442,0.6564 0.656163,0.87442 30.403729,-2.84361 c 0,0 6.99919,-0.87442 8.31163,-0.87442 1.31244,0 6.34291,-0.65639 6.34291,-0.65639 0,0 -0.65616,1.96802 -1.75011,2.18721 -1.09396,0.21802 -6.7807,2.18721 -6.7807,2.18721 0,0 -3.71803,1.96802 -6.12442,1.96802 -2.4057,0 -6.12443,0.21919 -6.12443,0.21919 0,0 -15.96767,4.37442 -20.99814,5.25 -5.03128,0.87442 -9.187096,2.84244 -10.936516,2.18721 -1.750116,-0.6564 -3.937326,-2.4064 -4.59349,-4.81279 -0.656279,-2.4064 -3.499536,-17.27966 -3.499536,-17.27966 0,0 -3.499617,-15.09245 -3.499617,-18.81163 0,-3.71803 5.030548,-5.68721 9.624038,-3.28082"  id="ys-path330" fill="#ff5f5f"  />								      
						<path d="m 92.26416,346.04687 2.187094,20.12327 1.312442,0.43721 v 1.09476 l 1.312442,0.6564 0.656163,0.87442 30.403729,-2.84361 c 0,0 6.99919,-0.87442 8.31163,-0.87442 1.31244,0 6.34291,-0.65639 6.34291,-0.65639 0,0 -0.65616,1.96802 -1.75011,2.18721 -1.09396,0.21802 -6.7807,2.18721 -6.7807,2.18721 0,0 -3.71803,1.96802 -6.12442,1.96802 -2.4057,0 -6.12443,0.21919 -6.12443,0.21919 0,0 -15.96767,4.37442 -20.99814,5.25 -5.03128,0.87442 -9.187096,2.84244 -10.936516,2.18721 -1.750116,-0.6564 -3.937326,-2.4064 -4.59349,-4.81279 -0.656279,-2.4064 -3.499536,-17.27966 -3.499536,-17.27966 0,0 -3.499617,-15.09245 -3.499617,-18.81163 0,-3.71803 5.030548,-5.68721 9.624038,-3.28082"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path332" fill="#ff5f5f"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 97.030794,377.58051 2.330782,-8.50067 28.774454,-2.69052 c 0,0 6.99919,-0.87442 8.31163,-0.87442 1.31244,0 6.34291,-0.65639 6.34291,-0.65639 0,0 -0.65616,1.96918 -1.75011,2.18721 -1.09396,0.21802 -6.7807,2.18721 -6.7807,2.18721 0,0 -3.71803,1.96802 -6.12442,1.96802 -2.4057,0 -6.12443,0.21919 -6.12443,0.21919 0,0 -15.96767,4.37442 -20.99814,5.25 -1.408003,0.24469 -2.743755,0.57521 -3.981976,0.91037 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path334" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />
						<path d="m 93.821185,375.00711 -0.681677,1.00663"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path336"stroke={props.darkMode? "#b3b3b3": "#263238"}  />		      
						<path d="m 97.732997,369.23293 -3.078446,4.54373"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path338"stroke={props.darkMode? "#b3b3b3": "#263238"}  />
						<path d="m 95.763464,367.70095 -4.375,3.06279"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path340"stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 141.4576,365.1809 c 0,0 -0.30372,1.11332 -1.41623,1.21422 -1.11309,0.10089 -4.35088,1.9228 -4.65391,1.9228"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path342"stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 137.81426,365.48475 c 0,0 -1.01219,0.91037 -1.92268,1.11332 -0.91037,0.20179 -3.84443,1.51806 -3.84443,1.51806"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path344"stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 122.43548,367.30549 c 0,0 4.52206,-1.52966 4.89165,-1.42296 0.36972,0.10785 1.68495,0.10785 3.20277,-0.0951 1.51794,-0.20179 2.42832,1.51806 1.21422,2.02369 -1.21422,0.50564 -2.22572,0.30385 -2.63034,0.30385 -0.40474,0 -1.61895,0.40473 -1.92268,0.40473"  id="ys-path346" fill="#ffffff"  />								      
						<path d="m 122.43548,367.30549 c 0,0 4.52206,-1.52966 4.89165,-1.42296 0.36972,0.10785 1.68495,0.10785 3.20277,-0.0951 1.51794,-0.20179 2.42832,1.51806 1.21422,2.02369 -1.21422,0.50564 -2.22572,0.30385 -2.63034,0.30385 -0.40474,0 -1.61895,0.40473 -1.92268,0.40473"  strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path348" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 157.95538,362.61563 -42.62589,4.79539 0.13336,-1.46587 42.75846,-4.52866 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path350" fill="#ffffff"  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 158.22131,361.41649 8.39199,-22.11216 -23.97685,1.99818 -7.85888,22.77899 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path352" fill={props.darkMode? "#3a3a3a": "#ffffff"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								      
						<path d="m 134.77722,364.0815 v 1.14231 l -19.44797,2.18721 0.13325,-1.46471 19.33466,-2.04804 z"   strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" id="ys-path354" fill={props.darkMode? "#3a3a3a": "#263238"}  stroke={props.darkMode? "#b3b3b3": "#263238"}  />								   
					</g>
				</svg>
			</div>
	)
}