import React from "react"
import {Link as RouteLink } from 'react-router-dom'
import LogoLarge from "../SVG-Components/logo-large.js"

export default function FooterContentMobItem(props) {

	const scrollToNavbar = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth", // You can adjust the scroll behavior (smooth or instant)
		});
	};

	const scrollToAboutUs = () => {

		const aboutusElement = document.getElementById("about-us");

		if (aboutusElement) {
			let offset = aboutusElement.clientHeight * 0.03; // 15% of the height
			const aboutusPosition = aboutusElement.offsetTop;

			// Scroll to the target position with the calculated offset
			window.scrollTo({
				top: aboutusPosition - offset,
				behavior: "smooth",
			});
		}
	};

	const scrollToContact = () => {
		const contactElement = document.getElementById("contact");

		if (contactElement) {
		const contactPosition = contactElement.offsetTop;
		// Scroll to the target position with the calculated offset
		window.scrollTo({
			top: contactPosition,
			behavior: "smooth",
		});
		}
	};

	const [currentTime, setCurrentTime] = React.useState(new Date());

	const formatTime = (time) => {
		const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
		const minutes = time.getMinutes();
		const ampm = time.getHours() >= 12 ? 'PM' : 'AM';
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${ampm}`;
	};

	setTimeout(() => {
    	setCurrentTime(new Date());
	}, 1000);

	return(
		<>
			<div className="footer-heading-mobile pb-2 pt-2">
				<LogoLarge darkMode={props.darkMode}/>
			</div>
			<p className="footer-text-mobile">{props.text}</p>
			<div className="d-flex flex-row pt-2">
				<a 
					href="https://www.facebook.com/profile.php?id=61555668879066&mibextid=2JQ9oc"
					target="_blank">
					<img 
						src="../SVGs/Footer/facebook-icon.svg" 
						className="img-fluid pe-4 footer-social-links"
						alt="Social media: Facebook"/>
				</a>
				<a
					href="https://www.linkedin.com/company/arrowcode/" 
					target="_blank">
					<img 
						src="../SVGs/Footer/linkedin-icon.svg"
						className="img-fluid pe-4 footer-social-links"
						alt="Social media: LinkedIn"/>
				</a>
				<a 
					href="https://www.instagram.com/arrowcodetech/?utm_source=qr&igsh=aWVrZGY1cWEzYXUz"
					target="_blank">
					<img 
						src="../SVGs/Footer/instagram-icon.svg"
						className="img-fluid pe-4 footer-social-links"
						alt="Social media: Instagram"/>
				</a>
			</div>
			<ul className="footer-nav-list pt-1">
					<li 
						className={`footer-link pb-2 pt-1 ${props.darkMode? "dark" : "light"}`}>
						<p onClick={scrollToNavbar}>{props.menu_1}</p>
						
					</li>
					<li 
						className={`footer-link pb-2 ${props.darkMode? "dark" : "light"}`}>
						<p onClick={scrollToAboutUs}>{props.menu_2}</p>
					</li>
					<li className="pb-2">
						<RouteLink  
							className={`footer-link ${props.darkMode? "dark" : "light"}`} 
							to="/terms-and-conditions"
							smooth={true} 
							duration={5}>
							{props.menu_3}
						</RouteLink>
					</li>
					<li className="pb-2">
						<RouteLink 
							className={`footer-link ${props.darkMode? "dark" : "light"}`} 
							to="/privacy-policy"
							smooth={true} 
							duration={5}>
							{props.menu_4}
						</RouteLink>
					</li>
					<li 
						className={`footer-link ${props.darkMode? "dark" : "light"}`}>
						<p onClick={scrollToContact}>{props.menu_5}</p>
					</li>
				</ul>
			<h4 className="footer-heading-mobile pb-2 pt-1">{props.title}</h4>
			<p className="footer-text-mobile pb-1 pe-5">{props.timings_1}</p>
			<p className="footer-text-mobile">{props.timings_2}</p>
			<p className="footer-text-mobile">{props.timings_3}</p>
			<p className="footer-text-mobile pb-3">Current Time in India is {formatTime(currentTime)}</p>
			<div className="d-flex flex-row footer-email">
				<img 
					src="../SVGs/Footer/phone-icon.svg"
					className="img-fluid pe-2"
					alt="Email icon"/>
				<p className="footer-text-mobile">{props.phone}</p>
			</div>
			<div className="d-flex flex-row footer-email">
				<img 
					src="../SVGs/Footer/email-icon.svg"
					className="img-fluid pe-2"
					alt="Email icon"/>
				<p className="footer-text-mobile">{props.email}</p>
			</div>
		</>
	)
}