import React, {useRef} from "react"
import {motion} from "framer-motion"

export default function ServicesContentMobItem(props) {



	return (
		<>
			<h3 className="section-heading pb-4 text-center">
				{props.title}
				<span>{props.title_span}</span>
			</h3>
			<p className=
				{`section-text text-center ms-md-5 me-md-5 pb-sm-3 
				${props.darkMode? "services-dark-underline" : "services-light-underline"}`}>
				{props.text}
			</p>
		</>
	)
}