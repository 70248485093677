import React from "react"
import {motion} from "framer-motion"
import Why_Us_Mobile_SVG from "../SVG-Components/why-us-mobile.js"

export default function WhyUsMobContentItem(props) {

	

	return(
			<motion.div
				initial="hidden"
				whileInView="visible"
				viewport={{ once: true }}
				variants=
						{{
							visible: { opacity: 1, y: 0 },
							hidden: { opacity: 0, y:-10}
						}}
				transition={{ duration: 1, type: "spring", delay: 0.2 }}>
				<h3 
					className={`section-heading pb-3 text-center
					${props.ysMobDarkMode ? "ws-dark-border" : "ws-light-border"}`}>
					{props.title}
					<span>{props.title_span}</span>
				</h3>
				<div className="pt-3 why-us-svg-div">
					<Why_Us_Mobile_SVG darkMode={props.ysMobDarkMode}/>
				</div>
				<div className="d-flex flex-row ps-2 pe-2 pt-3 pb-4">
					<div>
						<img 
							src="../SVGs/Why_Us/why-us-arrow-mobile.svg" 
							alt="Why choose us point one icon"/>
					</div>
					<div className="d-flex flex-column">
						<h4 className="why-us-points-heading-mobile">{props.p_title_1}</h4>
						<p className="why-us-points-text-mobile text-justify">{props.p_text_1}</p>
					</div>
				</div>
				<div className="d-flex flex-row ps-2 pe-2 pb-4">
					<div>
						<img 
							src="../SVGs/Why_Us/why-us-arrow-mobile.svg"
							alt="Why choose us point two icon"/>
					</div>
					<div className="d-flex flex-column">
						<h4 className="why-us-points-heading-mobile">{props.p_title_2}</h4>
						<p className="why-us-points-text-mobile text-justify">{props.p_text_2}</p>
					</div>
				</div>
				<div className="d-flex flex-row ps-2 pe-2 pb-4">
					<div>
						<img 
							src="../SVGs/Why_Us/why-us-arrow-mobile.svg"
							alt="Why choose us point three icon"/>
					</div>
					<div className="d-flex flex-column">
						<h4 className="why-us-points-heading-mobile">{props.p_title_3}</h4>
						<p className="why-us-points-text-mobile text-justify">{props.p_text_3}</p>
					</div>
				</div>
			</motion.div>
	)
}