import React from "react"
import {motion} from "framer-motion"

export default function ContactContentDesktopItem(props) {

	const [currentTime, setCurrentTime] = React.useState(new Date());

	const formatTime = (time) => {
		const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
		const minutes = time.getMinutes();
		const ampm = time.getHours() >= 12 ? 'PM' : 'AM';
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${ampm}`;
	};

	setTimeout(() => {
    	setCurrentTime(new Date());
	}, 1000);

	return(
		<>
			<h3 className="section-heading pb-4 text-center">
				{props.title}
				<span>{props.title_span}</span>
			</h3>
			<p className="section-text pb-4 text-center ps-5 pe-5">{props.text}</p>
			<h4 className="contact-heading-timings pb-4 text-center">
				{props.s_title_1}
				<span>{props.s_title_span}</span>
				{props.s_title_2}
			</h4>
			<p className="contact-text-timings-mobile pb-1 text-center">{props.phone}</p>
			<p className="contact-text-timings-mobile pb-1 text-center">{props.email}</p>
			<p className="contact-text-timings-mobile pb-1 text-center">{props.timings}</p>
			<p className="contact-text-timings-mobile pb-1 text-center">
				Current Time in India is {formatTime(currentTime)}
			</p>
		</>
	)
}