import React from "react"
import {motion} from "framer-motion"

import About_Us_JSON from "../JSONs/about-us"

import About_Us_SVG from "../SVG-Components/about-us.js"
import Project_Management_SVG from "../SVG-Components/about-us-project-management.js"
import Web_Development_SVG from "../SVG-Components/about-us-web-development.js"
import Projects_Delivered_SVG from "../SVG-Components/about-us-projects-delivered.js"
import Furry_Felines_SVG from "../SVG-Components/about-us-furry-felines.js"


export default function AboutUs(props) {

	return(
		<div className="pt-5" id="about-us">
		<section className="container pt-lg-5 pt-2 pb-1">
			{
				props.windowWidth > 992 && 

				<div className="about-us d-flex flex-row">
					<motion.div
						className="col-lg-6 col-7 ps-5 pe-lg-5 pe-2"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:-100}
								}}
						transition={{ duration: 0.3 }}>
						<h3 className="section-heading pb-3">
							{About_Us_JSON[0].title}
							<span>{About_Us_JSON[0].title_span}</span>
						</h3>
						<p className="section-text pb-3">{About_Us_JSON[0].text}</p>
						<div className="row row-cols-2 about-us-text-with-icons">
							<div className="col">
								<div className="d-flex flex-row pb-4 pt-2">
									<div className="project-management-svg pe-3">
										<Project_Management_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center">
										<h4 className="about-us-highlight-number">10+ </h4>
										<h4 className="about-us-highlight-text">Years of Product Management</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="d-flex flex-row pb-4 pt-2">
									<div className="web-development-svg pe-3">
										<Web_Development_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center">
										<h4 className="about-us-highlight-number">5+</h4>
										<h4 className="about-us-highlight-text"> Years of Web Development</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="d-flex flex-row pb-4">
									<div className="projects-delivered-svg pe-3">
										<Projects_Delivered_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center">
										<h4 className="about-us-highlight-number">40+ </h4>
										<h4 className="about-us-highlight-text">Projects Delivered</h4>
									</div>
								</div>
							</div>
							<div className="col">
								<div className="d-flex flex-row pb-4">
									<div className="furry-feline-svg pe-3">
										<Furry_Felines_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center">
										<h4 className="about-us-highlight-number">2+</h4>
										<h4 className="about-us-highlight-text"> Furry Feline Friends</h4>
									</div>
								</div>
							</div>
						</div>
					</motion.div>

					<motion.div
						className="col ps-5 pe-5 about-us-svg-div"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:100}
								}}
						transition={{ duration: 0.3 }}>
						<About_Us_SVG darkMode={props.darkMode}/>
					</motion.div>

				</div>
			}

			{
				props.windowWidth <=992 &&

				<div className={`d-flex flex-column ${props.darkMode? "" : ""}`}>
					<motion.div>
						<h3 className="section-heading pb-4 pt-3 text-center">
							{About_Us_JSON[0].title}
							<span>{About_Us_JSON[0].title_span}</span>
						</h3>
						<div className="about-us-svg-div pb-3">
							<About_Us_SVG darkMode={props.darkMode}/>
						</div>
						<p className="section-text pb-4 ps-2 pe-2 text-center">
							{About_Us_JSON[0].text}
						</p>
					</motion.div>
					<div className="row row-cols-2">
							<div className="col text-center pb-4">
								<div className="d-flex flex-column justify-content-center">
									<div className="project-management-svg">
										<Project_Management_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center pt-1">
										<h4 className="about-us-highlight-number-mobile">10+ </h4>
										<h4 className="about-us-highlight-text-mobile">Years of Product Management</h4>
									</div>
								</div>
							</div>
							<div className="col text-center pb-4">
								<div className="d-flex flex-column justify-content-center">
									<div className="web-development-svg">
										<Web_Development_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center pt-1">
										<h4 className="about-us-highlight-number-mobile">5+</h4>
										<h4 className="about-us-highlight-text-mobile"> Years of Web Development</h4>
									</div>
								</div>
							</div>
							<div className="col text-center">
								<div className="d-flex flex-column justify-content-center">
									<div className="projects-delivered-svg">
										<Projects_Delivered_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center pt-1">
										<h4 className="about-us-highlight-number-mobile">40+ </h4>
										<h4 className="about-us-highlight-text-mobile">Projects Delivered</h4>
									</div>
								</div>
							</div>
							<div className="col text-center">
								<div className="d-flex flex-column justify-content-center">
									<div className="furry-feline-svg">
										<Furry_Felines_SVG darkMode={props.darkMode}/>
									</div>
									<div className="d-flex flex-column justify-content-center pt-1">
										<h4 className="about-us-highlight-number-mobile">2+</h4>
										<h4 className="about-us-highlight-text-mobile"> Furry Feline Friends</h4>
									</div>
								</div>
							</div>
						</div>
				</div>
			}
		</section>
		</div>
	)
}