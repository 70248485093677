export default [
    {
        id:"questions_1",
        question: "What is the payment model for your services?",
        answer: "We tailor our payment structure to your requirements for flexibility. Typically, we follow a milestone-based plan with an initial payment for project setup, followed by periodic payments at specified milestones, and the final payment before project delivery. Alternatively, we can adopt an man-hour-based model. We'll discuss and choose the best approach based on your needs and provide a detailed quotation beforehand."
    },
    {   
        id:"questions_2",
        question: "How do I keep track of the project's progress?",
        answer: "We use JIRA to monitor our projects, tracking both hours worked and overall progress. We will email you periodic progress/update reports."
    },
    {   
        id:"questions_3",
        question: "Do you provide UI / UX design services?",
        answer: "We encourage clients to provide their own design, as design preferences are highly subjective and may involve multiple revisions. However,  we can facilitate direct collaboration with a third-party designer if needed."
    },
    {   
        id:"questions_4",
        question: "Do you have an enterprise plan?",
        answer: "We usually work with small businesses. However, we are open to discussing your specific needs, so let's connect! Please keep in mind, we don't offer credit facilities."
    },
    {   
        id:"questions_5",
        question: "Who pays for cloud services, domain name, etc.?",
        answer: "It's up to you! You can handle and pay for your cloud services, or we can assist you. Similarly, you can purchase domain names directly or we can guide you through the process."
    },
    {   
        id:"questions_6",
        question: "Do you charge for meetings?",
        answer: "Before starting a project, we offer 2 hours of free meeting time (phone calls, zoom meetings, google meet, etc.) to understand your requirements and provide a quotation. Once the project begins, we provide 4 hours of free monthly meeting time. Beyond that, meetings are charged at a fixed rate per hour."
    },
    {   
        id:"questions_7",
        question: "Do you provide product management services?",
        answer: "Yes, absolutely. Our team comprises seasoned product managers with over a decade of experience. However, these are typically more premium services. We often suggest our clients come up with the solution, and we handle the technical implementation."
    },
    {   
        id:"questions_8",
        question: "What other industries have you worked in?",
        answer: "We've successfully served clients in various industries for 10+ years, including Oil & Gas, Publishing Houses, FinTech, Architectural & Interior Design, Logistics, etc."
    },
]