import React from "react"
import {Link} from "react-router-dom"

export default function PrivacyPolicy(props) {

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return(
		<section className={`container pt-5 pb-5 ps-3 pe-3 ${props.darkMode ? "ppdark": ""}`}>
			<div className="minor-page-navbar pb-3">
				<Link to="/" className="minor-page-back-button">
					<div className="minor-page-navbar-back-button">
						<img 
						src={props.darkMode? 
							"../SVGs/Minor_Pages/back-darkmode.svg": "../SVGs/Minor_Pages/back-lightmode.svg"}
						className="img-fluid"/>
						<p>Back</p>
					</div>
				</Link>	
				<div onClick={props.toggleDarkMode} className="pe-3 darkMode-toggler-icon">
       	     		<img src={`./SVGs/Navbar/${props.darkMode? "moon.svg" : "sun.svg"}`}/>
    			</div>
			</div>

			<h3 className="text-center section-heading pb-3">Privacy <span>Policy</span></h3>
			<p className="minor-page-text pb-3">Your privacy is important to us. This Privacy Policy outlines how Arrow Code ("we," "us," "our", "www.arrowcode.tech") collects, uses, and protects your personal information when you use our website and services. By accessing our website and using our services, you consent to the practices described in this Privacy Policy.</p>

			<h5 className="minor-page-heading pt-3 pb-1">1. Your Consent</h5>
			<p className="minor-page-text">By using our website and/or services you consent irrevocably to our collection and use of your personal information as described in this Privacy Policy. We change our privacy policies and procedures in compliance with the development in laws and technology. We will post those changes on our website "www.arrowcode.tech" to keep you aware of what information we collect, how we use it and under what circumstances we may disclose it. You need to view the changes made in the Privacy Policy to stay informed.</p>

			<h5 className="minor-page-heading pt-3 pb-1">2. Information We Collect</h5>
			<p className="minor-page-text">We may collect the following types of information:</p>
			<ul className="minor-page-text pb-3">
				<li>Personal Information: This may include your name, email address, phone number, and other details when you voluntarily provide them to us.</li>
				<li>Usage Data: We may collect information about how you use our website, including but not limited to your IP address, browser type, and device information.</li>
				<li>Cookies and Tracking Technologies: We may use cookies and similar tracking technologies to enhance your experience on our website and gather data about your interactions with our content.</li>
			</ul>

			<h5 className="minor-page-heading pt-3 pb-1">3. How We Use Your Information</h5>
			<p className="minor-page-text">We may use your information for the following purposes:</p>
			<ul className="minor-page-text pb-3">
				<li>To provide, maintain, and improve our services.</li>
				<li>To communicate with you, respond to your inquiries, and send you updates and promotional materials.</li>
				<li>To improve our website and user experience.</li>
				<li>To monitor and analyze usage patterns and trends.</li>
				<li>To comply with legal obligations.</li>
				<li>To provide unique offers to viewers.</li>
				<li>To monitor traffic patterns to permit website personalisation and streamlining.</li>
				<li>To perform analysis using third parties to improve our products, services, website, and user experience. These statistics do not include personally identifying information.</li>
			</ul>

			<h5 className="minor-page-heading pt-3 pb-1">4. Disclosure of Your Information</h5>
			<p className="minor-page-text">We may share your information with:</p>
			<ul className="minor-page-text pb-3">
				<li>Third-party agencies that assist us in providing and maintaining our website, products, and services.</li>
				<li>Law enforcement or government agencies when required by law or to protect our rights and legal interests.</li>
			</ul>

			<h5 className="minor-page-heading pt-3 pb-1">5. Your Choices:</h5>
			<p className="minor-page-text">You have the right to:</p>
			<ul className="minor-page-text pb-3">
				<li>Access, update, or delete your personal information.</li>
				<li>Opt-out of receiving promotional communications.</li>
				<li>Disable cookies through your browser settings.</li>
			</ul>

			<h5 className="minor-page-heading pt-3 pb-1">6. Security:</h5>
			<p className="minor-page-text pb-3">We implement reasonable security measures to protect your personal information from unauthorized access and disclosure. However, no method of data transmission over the internet is completely secure, and we cannot guarantee the security of your data.</p>
			<p className="minor-page-text">In no event shall we be liable for special, indirect, exemplary, or consequential damages or any damages whatsoever, including but not limited to loss of use, data, or profits, without regard to the form of any action, including but not limited to contract, negligence, or other tortious actions, arising out of or in connection with the use, copying, or display of the contents of the website, even if Arrow Code or an authorized representative of Arrow Code has been advised of the possibility of such damages.</p>
			

			<h5 className="minor-page-heading pt-3 pb-1">7. Changes to this Privacy Policy:</h5>
			<p className="minor-page-text pb-3">We may update this Privacy Policy from time to time. When we do, we will revise the "Last Updated" date at the bottom of this page. Please review this Privacy Policy regularly to stay informed about how we are protecting your information.</p>
			

			<h5 className="minor-page-heading pt-3 pb-1">8. Contact Us:</h5>
			<p className="minor-page-text pb-3">For any questions or concerns related to this Privacy Policy or your personal information, please contact us at: support@arrowcode.tech.</p>
			

			<h5 className="minor-page-heading pt-3 pb-1">8. Governing Law:</h5>
			<p className="minor-page-text pb-5">This Privacy Policy is governed by the laws of India. Any legal disputes will be resolved in the courts of Mumbai (Maharashtra, India). By using our website and services, you acknowledge that you have read, understood, and agree to this Privacy Policy.</p>

			<small className="pp-update">Last Updated: 14<sup>th</sup> January 2024</small>
		</section>
	)
}