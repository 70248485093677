export default [
    {
        id: "why_us_1",
        title: "Simplifying ",
        title_span: "Business",
        p_title_1: "Personalized Attention",
        p_text_1: "Our team is committed to providing personalized and transparent communication at every step. Your success is our priority.",
        p_title_2: "Competitive Pricing",
        p_text_2: "We believe in smart spending. We ensure cost-effective, high-quality solutions that maximize your return on investment.",
        p_title_3: "On-Time Delivery",
        p_text_3: "Time is money, and we respect both. Our commitment to punctuality means we deliver on time, every time."

    }
]