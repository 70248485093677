export default [
    {
        id:"footer_1",
        text: "We help you become digitally savvy. Get online and grow your business",
        menu_1:"Home",
        menu_2:"About Us",
        menu_3:"Terms and Conditions",
        menu_4:"Privacy Policy",
        menu_5:"Get in Touch",
        title: "Arrow Code",
        timings_1: "Working Hours:",
        timings_2: "Monday-Friday",
        timings_3: "10AM to 5PM IST",
        phone: "(+91) 7517354240",
        email: "support@arrowcode.tech"
    }
]