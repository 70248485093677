import React from "react"
import {motion} from "framer-motion"

import Services_JSON from "../JSONs/services"
import Services_JSON_Component_Desktop from "../Content-Components/services-desktop"
import Services_JSON_Component_Mobile from "../Content-Components/services-mobile"

import Services_Drawer_1_JSON from "../JSONs/services-drawer-1"
import Services_Drawer_2_JSON from "../JSONs/services-drawer-2"
import Services_Drawer_Component_Desktop from "../Content-Components/services-drawer-desktop"
import Services_Drawer_Component_Mobile from "../Content-Components/services-drawer-mobile"

import Services_SVG from "../SVG-Components/services.js"


export default function Services(props) {

	let Services_JSON_Content_Desktop = Services_JSON.map(eachElement => (
			<Services_JSON_Component_Desktop 
				key={eachElement.id}
				id={eachElement.id}
				title={eachElement.title}
				title_span={eachElement.title_span}
				text={eachElement.text}
			/>))

	let Services_JSON_Content_Mobile= Services_JSON.map(eachElement => (
		<Services_JSON_Component_Mobile
			key={eachElement.id}
			id={eachElement.id}
			title={eachElement.title}
			title_span={eachElement.title_span}
			text={eachElement.text}
			darkMode={props.darkMode}
		/>))


	let Services_Drawer_1_Desktop = Services_Drawer_1_JSON.map(eachElement => (
		<Services_Drawer_Component_Desktop
			key={eachElement.id}
			id={eachElement.id}
			number={eachElement.number}
			title = {eachElement.title}
			desc = {eachElement.desc}
			darkMode={props.darkMode}
		/>
		))

	let Services_Drawer_2_Desktop = Services_Drawer_2_JSON.map(eachElement => (
		<Services_Drawer_Component_Desktop
			key={eachElement.id}
			id={eachElement.id}
			number={eachElement.number}
			title = {eachElement.title}
			desc = {eachElement.desc}
			darkMode={props.darkMode}
		/>))


	let Services_Drawer_1_Mobile = Services_Drawer_1_JSON.map(eachElement => (
			<Services_Drawer_Component_Mobile
				key={eachElement.id}
				id={eachElement.id}
				number={eachElement.number}
				title = {eachElement.title}
				desc = {eachElement.desc}
				darkMode={props.darkMode}
			/>))

	let Services_Drawer_2_Mobile = Services_Drawer_2_JSON.map(eachElement => (
			<Services_Drawer_Component_Mobile
				key={eachElement.id}
				id={eachElement.id}
				number={eachElement.number}
				title = {eachElement.title}
				desc = {eachElement.desc}
				darkMode={props.darkMode}
			/>))

	return(
		<div className="pt-5" id="services">
		<section className="container pt-md-3">
			{
				props.windowWidth > 992 && 

				<div className="d-flex flex-row">
					<motion.div
						className="col-lg-7 col-8 pt-4 ps-5"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:-100}
								}}
						transition={{ duration: 0.3 }}>
						{Services_JSON_Content_Desktop}
						<div className="row row-cols-2 pe-5 pt-4">
							<div className="d-flex flex-column services-list">
								{Services_Drawer_1_Desktop}
							</div>
							<div className="d-flex flex-column services-list">
								{Services_Drawer_2_Desktop}
							</div>
						</div>
					</motion.div>
					<motion.div
						className="col ps-5"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:100}
								}}
						transition={{ duration: 0.3 }}>
						<div className="services-svg-div pt-4 pe-2">
							<Services_SVG darkMode={props.darkMode}/>
						</div>
					</motion.div>
					
					
				</div>

			}
			{
				props.windowWidth <= 992 &&

				<div className="d-flex flex-column justify-content-center align-items-center">
					<motion.div
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, y: 0 },
									hidden: { opacity: 0, y:-10}
								}}
						transition={{ duration: 1, type: "spring", delay: 0.2 }}
						className="ps-md-5 pe-md-5 pb-md-3 pt-md-3">
						{Services_JSON_Content_Mobile}
						<div className="services-svg-div pe-5 ps-5 pt-3 pb-3 me-sm-5 ms-sm-5 d-flex align-items-center justify-content-center">
							<Services_SVG darkMode={props.darkMode}/>
						</div>
					</motion.div>

					<motion.div
						className="w-100 d-flex flex-column align-items-center"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, y: 0 },
									hidden: { opacity: 0, y:-10}
								}}
						transition={{ duration: 1, type: "spring", delay: 0.4 }}>
						{Services_Drawer_1_Mobile}
						{Services_Drawer_2_Mobile}
					</motion.div>
				</div>
			}
		</section>
		</div>
	)}