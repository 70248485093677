import React from "react"

export default function QuestionsDesktopContentItem(props) {
	const scrollToContact = () => {
		const contactElement = document.getElementById("contact");

		if (contactElement) {
		const contactPosition = contactElement.offsetTop;
		// Scroll to the target position with the calculated offset
		window.scrollTo({
			top: contactPosition,
			behavior: "smooth",
		});
		}
	};
	return (
		<>
			<h3
				className={`section-heading pb-4 ${props.isVisible ? "questions-lhs-animation" : ""}`}>
				{props.title}<span>{props.title_span}</span>
			</h3>
			<p className={`section-text pb-4 ${props.isVisible ? "questions-lhs-animation" : ""}`}>
				{props.text}
			</p>
			<div className={`ps-5 pe-5 pt-5 ms-4 ${props.isVisible ? "questions-button-pulse-animation" : ""}`}>
				<button 
					className="questions-button-shine"
					onClick={scrollToContact}>
					{props.button_text}
				</button>
			</div>
		</>	
	)
}