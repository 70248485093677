import React from "react"
import {motion} from "framer-motion"

export default function ServicesItem(props) {

	let plusSign="+"
	let minusSign="-"

	let [plus, setPlus] = React.useState(true);

	function handleClick() {
		setPlus(!plus)
	}

	return(
		<div className={`pt-3 pb-3 ${props.darkMode? "services-list-item-mobile-dark" : "services-list-item-mobile"}`}>
			<div 
				data-bs-toggle="collapse" 
				data-bs-target={`#${props.id}`} 
				aria-expanded="false" 
				aria-controls={props.id}
				className="d-flex flex-row justify-content-start"
				role="button"
				onClick={handleClick}>
					<h4 className="services-item-number pe-3">{props.number}</h4>
					<h4 className="w-100 services-item-title">{props.title}</h4>
					<h4 className="services-item-number services-item-sign"> {plus ? plusSign : minusSign}</h4>
			</div>
			<p className="services-list-desc-mobile p-1 collapse" id={props.id}>{props.desc}</p>
		</div>	
	)
}