export default [
    {
        id:"contact_1",
        title: "Let's Work ",
        title_span: "Together",
        text: "We're thrilled that you're considering us. Please fill out this form and we will contact you.",
        s_title_1: " How to ",
        s_title_span: "Reach ",
        s_title_2: "Us",
        phone: "Phone: (+91) 7517354250",
        email: "Email: support@arrowcode.tech",
        timings: "Monday - Friday: 9 AM to 5 PM IST"
    }
]