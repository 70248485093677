export default [
    {
        id:"service_item_1",
        number:"01",
        title: "Websites",
        desc: "We design eye-catching websites and handle everything from visuals to backend development, SEO, and hosting. Whether starting from scratch or using platforms like SquareSpace, Wix, Webflow, or WordPress, we ensure you have a top-notch online experience."
    },
    {   
        id:"service_item_2",
        number:"02",
        title: "E-commerce Stores",
        desc: "We elevate your e-commerce experience with top-notch stores using platforms like Shopify, BigCommerce, WooCommerce, OpenCart, and more. We seamlessly integrate third-party apps for enhanced features."
    },
    {   
        id:"service_item_3",
        number:"03",
        title: "Web Apps",
        desc: "We develop responsive web apps (CRUD, PWA, CRM, CMS, etc.) with a robust backend. Our expertise spans microservices or monolithic architecture, MVC model, database management, server and serverless solutions."
    },
    {   
        id:"service_item_4",
        number:"04",
        title: "User Management",
        desc: "We prioritize user data privacy with our expertise in user sign-up, user authentication, authorization, and identity and access management. We ensure your platform is secure and compliant."
    },
    {   
        id:"service_item_5",
        number:"05",
        title: "APIs",
        desc: "We create flawless APIs and seamlessly integrate them with platforms like Mailchimp, SendGrid, and WhatsApp Business. We specialize in crafting secure REST APIs, ensuring a robust foundation for your digital ventures. Our streamlined GitHub storage and user-friendly documentation guarantee easy accessibility. We are proficient in the Serverless framework, and ensure scalable and efficient management of your digital workflows."
    },
]