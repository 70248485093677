import React from "react"
import {motion} from "framer-motion"

import Form from "../Content-Components/contact-form"
import FormMobile from "../Content-Components/contact-form-mobile"

import Contact_JSON from "../JSONs/contact"
import Contact_JSON_Component_Desktop from "../Content-Components/contact-desktop"
import Contact_JSON_Component_Mobile from "../Content-Components/contact-mobile"


export default function Contact(props) {

 	let Contact_JSON_Content_Desktop = Contact_JSON.map(eachElement => (
			<Contact_JSON_Component_Desktop
				key={eachElement.id}
				id={eachElement.id}
				title={eachElement.title}
				title_span={eachElement.title_span}
				text={eachElement.text}
				s_title_1={eachElement.s_title_1}
				s_title_span={eachElement.s_title_span}
				s_title_2={eachElement.s_title_2}
				email={eachElement.email}
				phone={eachElement.phone}
				timings={eachElement.timings}
			/>
		))

 	let Contact_JSON_Content_Mobile = Contact_JSON.map(eachElement => (
			<Contact_JSON_Component_Mobile
				key={eachElement.id}
				id={eachElement.id}
				title={eachElement.title}
				title_span={eachElement.title_span}
				text={eachElement.text}
				s_title_1={eachElement.s_title_1}
				s_title_span={eachElement.s_title_span}
				s_title_2={eachElement.s_title_2}
				email={eachElement.email}
				phone={eachElement.phone}
				timings={eachElement.timings}
			/>
		))

	const [darkMode, setdarkMode] = React.useState(props.darkMode)

	const [isFlashMsgOn, setIsFlashMsgOn] = React.useState(false);

	const [flashMsg, setFlashMsg] = React.useState(null);

	const [formSubmitting, setFormSubmitting] = React.useState(false);

	function handleFlashMsgClick() {
		setIsFlashMsgOn(false)
	}

	const handleFormSubmit = async (formData) => {

		if (!formData.formName && !formData.formEmail && !formData.formMsg && formData.formPhone) {
			setFlashMsg('Please fill the form before submitting.');
			setIsFlashMsgOn(true);
			return;
		}

		if (!formData.formName || !formData.formEmail || !formData.formMsg) {
			setFlashMsg('Please fill in all required fields.');
			setIsFlashMsgOn(true);
			return;
		}

		setFormSubmitting(true)
          try {
               const response = await fetch('/form_submit', {
                    method: 'POST',
                    headers: {
                         'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData),

               });

               await new Promise(resolve => setTimeout(resolve, 1000));

               if (!response.ok) {
               	setIsFlashMsgOn(true)
                    setFlashMsg ('Something went wrong. Please try again.');
                    setFormSubmitting(false);
               }

               const result = await response.json();
               console.log(response);
               setIsFlashMsgOn(true)
               setFlashMsg ('Form submitted successfully!');
               setFormSubmitting(false);

          } catch (error) {
          	setIsFlashMsgOn(true);
               setFlashMsg ('Something went wrong. Please try again.');
               setFormSubmitting(false);
          } finally {
          	setFormSubmitting(false);
          }
	};

	return(
		<div className="pt-5" id="contact">
		<section className="container pt-5">
			
			{
				props.windowWidth >992 &&

				<div className={props.darkMode ? "contact dark" : "contact light"}>
					<div className="d-flex flex-row pt-5 pb-5 ps-5">
						<motion.div
							className="col-xl-7 col-lg-6 me-xxl-5 pt-lg-5 pt-2"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, x: 0 },
										hidden: { opacity: 0, x:-100}
									}}
							transition={{ duration: 0.3 }}>
							{Contact_JSON_Content_Desktop}
							{ isFlashMsgOn &&
								<div className={`d-flex flex-row justify-content-between ps-3 pe-3 pb-3 pt-1 
												${props.darkMode ? "flash-message-dark" : "flash-message"}`}>
									<div className="pt-3">
										<p>{flashMsg}</p>
									</div>
									<div>
										<img 
											src="./SVGs/Contact/close.svg" 
											alt="Close Flash Message" 
											className=""
											onClick={handleFlashMsgClick}/>
									</div>
								</div>
							}
						</motion.div>

						<motion.div
							className="col"
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, x: 0 },
										hidden: { opacity: 0, x:100}
									}}
							transition={{ duration: 0.3 }}>
							<Form 
								darkMode={props.darkMode}
								onSubmit={handleFormSubmit}
								formSubmitting={formSubmitting}/>
						</motion.div>

					</div>
				</div> 

			}

			{
				props.windowWidth <=992 && 

				<div 
					className={`d-flex flex-column contact-mobile pt-5 pe-4 ps-4 pb-5 
					${props.darkMode ? "dark" : "light"}`}>

					<motion.div
						initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-10}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.2 }}>
						{Contact_JSON_Content_Mobile}
					</motion.div>
					
					<motion.div
						initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-10}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.4 }}>
						<FormMobile 
							darkMode={props.darkMode}
							onSubmit={handleFormSubmit}
							formSubmitting={formSubmitting}/>
						{ isFlashMsgOn &&
							<div className={`d-flex flex-row justify-content-between ps-3 pe-3 pb-3 pt-1 mt-4
											${props.darkMode ? "flash-message-dark-mobile" : "flash-message-mobile"}`}>
								<div className="pt-4">
									<p>{flashMsg}</p>
								</div>
								<div>
									<img 
										src="./SVGs/Contact/close.svg" 
										alt="Close Flash Message" 
										className=""
										onClick={handleFlashMsgClick}/>
								</div>
							</div>
						}
					</motion.div>
				</div>
			}
			
		</section>
		</div>
	)
}