export default [
    {
        id:"service_item_6",
        number:"06",
        title: "Web Scraping",
        desc: "We help you take data-driven decisions with real-time insights using advanced RPA and crawler-based data extraction."
    },
    {
        id:"service_item_7",
        number:"07",
        title: "Digital Marketing",
        desc: "We boost your digital presence with expertise in campaign management, personlized SEO strategies, event tracking. We are well versed in tools like Meta Pixel, Google Tag Manager, Google Analytics, Google Ads, Google My Business, etc."
    },
    {
        id:"service_item_8",
        number:"08",
        title: "Domain Management",
        desc: "Your website is in capable hands with our custom domain and DNS services. We help you select perfect domain, handle all aspects of DNS setup, and ensure everything is in place to kickstart your online journey."
    },
    {
        id:"service_item_9",
        number:"09",
        title: "Backend",
        desc: "We streamline your web hosting experience with DNS management, cloud resource monitoring, server and serverless solutions, load balancing, and security measures. We also optimize performance with proxy servers."
    },
    {
        id:"service_item_10",
        number:"10",
        title: "Data Analytics",
        desc: "We transform complex data into actionable insights with tools like Excel, Power BI, Tableau, and Python. Our dynamic, interactive dashboards and data cleanup solutions ensure insightful summaries and analytics. "
    }
]