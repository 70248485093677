import React from "react"
import {motion} from "framer-motion"

import Questions_JSON from "../JSONs/questions"
import Questions_JSON_Component_Desktop from "../Content-Components/questions-desktop"

import Questions_Accordion_JSON from "../JSONs/questions-accordion"
import Questions_Accordion_Component_Desktop from "../Content-Components/questions-accordion-desktop"
import Questions_Accordion_Component_Mobile from "../Content-Components/questions-accordion-mobile"

import Questions_SVG from "../SVG-Components/questions.js"

export default function Questions(props) {

	const scrollToContact = () => {
		const contactElement = document.getElementById("contact");

		if (contactElement) {
		const contactPosition = contactElement.offsetTop;
		
		window.scrollTo({
			top: contactPosition,
			behavior: "smooth",
		});
		}
	};

	let Questions_JSON_Content_Desktop = Questions_JSON.map(eachElement => (
			<Questions_JSON_Component_Desktop 
				key={eachElement.id}
				title={eachElement.title}
				title_span={eachElement.title_span}
				text={eachElement.text}
				button_text={eachElement.button_text}
			/>
		))

	let Questions_Accordion_Desktop =  Questions_Accordion_JSON.map(eachElement => (
			<Questions_Accordion_Component_Desktop
				key={eachElement.id}
				id={eachElement.id}
				qDarkMode={props.darkMode}
				question={eachElement.question}
				answer={eachElement.answer}
			/>
		))

	let Questions_Accordion_Mobile =  Questions_Accordion_JSON.map(eachElement => (
			<Questions_Accordion_Component_Mobile
				key={eachElement.id}
				id={eachElement.id}
				darkMode={props.darkMode}
				question={eachElement.question}
				answer={eachElement.answer}
			/>
		))

	return(
		<section 
			className="container pt-5"
			id="questions">
			{
				props.windowWidth > 992 &&

				<div 
					className={`questions d-flex flex-row pt-5 ps-5 pe-5 pb-3 
								${props.darkMode ? "dark": "light"}`}>
					<motion.div
						className="col-5 pe-4"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:-100}
								}}
						transition={{ duration: 0.3 }}>
						<div className="d-flex flex-column">
							{Questions_JSON_Content_Desktop}
							<div 
								className="questions-svg-div ps-1">
								<Questions_SVG darkMode={props.darkMode}/>
							</div>
						</div>
					</motion.div>

					<motion.div
						className="col"
						initial="hidden"
						whileInView="visible"
						viewport={{ once: true }}
						variants=
								{{
									visible: { opacity: 1, x: 0 },
									hidden: { opacity: 0, x:100}
								}}
						transition={{ duration: 0.3 }}>
						<div 
							className="accordion accordion-flush" 
							id="accordionFlushExample" 
							data-bs-theme={props.darkMode ? "dark": "light" }>
							{Questions_Accordion_Desktop}
						</div>
					</motion.div>
				</div>
			}

			{
				props.windowWidth <= 992 &&

				<div className={props.darkMode ? "questionsMobile dark": "questionsMobile light"}>
					<div className="d-flex flex-column pt-5 pb-5 ps-4 pe-4">

						<motion.div
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-10}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.2 }}>
							
							<h3 className="section-heading pb-3 text-center">
								{Questions_JSON[0].title}<span>{Questions_JSON[0].title_span}</span>
							</h3>
							<p className="section-text pb-4 pe-5 ps-5 text-center">{Questions_JSON[0].text}</p>

							<div className="questions-svg-div pb-md-3 ps-md-5 pe-md-2">
									<Questions_SVG darkMode={props.darkMode}/>
							</div>
							<motion.div 
								className="text-center pt-3 pb-3"
								initial="hidden"
										whileInView="visible"
										viewport={{ once: true }}
										variants=
												{{
													hidden: { opacity: 0, scale: 0},
													visible: { opacity: 1, scale: 1}
												}}
										transition={{ duration: 1, type: "spring", delay: 1 }}>
								<button 
									className="questions-buttonMobile"
									onClick={scrollToContact}>
									{Questions_JSON[0].button_text}
								</button>
							</motion.div>
						</motion.div>

						<motion.div 
							className="accordion accordion-flush" 
							initial="hidden"
							whileInView="visible"
							viewport={{ once: true }}
							variants=
									{{
										visible: { opacity: 1, y: 0 },
										hidden: { opacity: 0, y:-10}
									}}
							transition={{ duration: 1, type: "spring", delay: 0.4 }}
							id="accordionFlushExample" 
							data-bs-theme={props.darkMode ? "dark": "light" }>
							{Questions_Accordion_Mobile}
						</motion.div>
					</div>
			</div>

			}
			
		</section>
	)
}