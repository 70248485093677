import React from "react"

import Navbar from "../Components/Navbar"
import Portfolio from "../Components/Portfolio"
import Footer from "../Components/Footer"

import LandingPage from "../Components/LandingPage"
import WhyUs from "../Components/WhyUs"
import Services from "../Components/Services"
import Contact from "../Components/Contact"
import Questions from "../Components/Questions"
import AboutUs from "../Components/AboutUs"

import LightboxDesktopGrow from "../Content-Components/lightbox-grow-desktop"
import LightboxMobileGrow from "../Content-Components/lightbox-grow-mobile"

import LightboxDesktopJF from "../Content-Components/lightbox-jf-desktop"
import LightboxMobileJF from "../Content-Components/lightbox-jf-mobile"

import LightboxDesktopAaraya from "../Content-Components/lightbox-aaraya-desktop"
import LightboxMobileAaraya from "../Content-Components/lightbox-aaraya-mobile"

import LightboxDesktopDC from "../Content-Components/lightbox-dc-desktop"
import LightboxMobileDC from "../Content-Components/lightbox-dc-mobile"



export default function Home({ darkMode, windowWidth, toggleDarkMode }) {

	/*Grow lightbox start*/
	const [lightboxOpen, setLightboxOpen] = React.useState(false);

	const openLightbox = () => {
		setLightboxOpen(true);
		document.body.classList.add("dont-scroll");
	};

	const closeLightbox = () => {
		setLightboxOpen(false);
		document.body.classList.remove("dont-scroll");
	};
	/*Grow lightbox end*/


	/*Judgefoodie lightbox start*/
	const [jflightboxOpen, setjfLightboxOpen] = React.useState(false);

	const openjfLightbox = () => {
		setjfLightboxOpen(true);
		document.body.classList.add("dont-scroll");
	};

	const closejfLightbox = () => {
		setjfLightboxOpen(false);
		document.body.classList.remove("dont-scroll");
	};
	/*judgefoodie lightbox end*/


	/*Aaraya lightbox start*/
	const [aarayalightboxOpen, setaarayaLightboxOpen] = React.useState(false);

	const openaarayaLightbox = () => {
		setaarayaLightboxOpen(true);
		document.body.classList.add("dont-scroll");
	};

	const closeaarayaLightbox = () => {
		setaarayaLightboxOpen(false);
		document.body.classList.remove("dont-scroll");
	};
	/*Aaraya lightbox end*/


	/*MKW lightbox start*/
	const [mkwlightboxOpen, setmkwLightboxOpen] = React.useState(false);

	const openmkwLightbox = () => {
		setmkwLightboxOpen(true);
		document.body.classList.add("dont-scroll");
	};

	const closemkwLightbox = () => {
		setmkwLightboxOpen(false);
		document.body.classList.remove("dont-scroll");
	};
	/*MKW lightbox end*/



	return(
		<>	
        	<Navbar 
                darkMode={darkMode} 
                toggleDarkMode={toggleDarkMode}
                windowWidth={windowWidth}/>
            <LandingPage 
            	darkMode={darkMode} 
            	windowWidth={windowWidth}/>
            <WhyUs
            	darkMode={darkMode}
            	windowWidth={windowWidth} />
            <Services
            	darkMode={darkMode}
            	windowWidth={windowWidth} />
            <Questions
            	darkMode={darkMode}
            	windowWidth={windowWidth} />
            <Portfolio 
		        darkMode={darkMode} 
		        portWindowWidth={windowWidth}
		        openLightbox={openLightbox}					//this is for grow lightbox
		        openjfLightbox={openjfLightbox} 			//this is for judgefoodie lightbox
		        openaarayaLightbox={openaarayaLightbox}		//this is for aaraya lightbox
		        openmkwLightbox={openmkwLightbox}			//this is for mkw lightbox
		    />
		    <Contact 
                darkMode={darkMode} 
                windowWidth={windowWidth}/>
            <AboutUs
                darkMode={darkMode}
                windowWidth={windowWidth} />
            <Footer 
                darkMode={darkMode} 
                windowWidth={windowWidth}/>

			{
				lightboxOpen && windowWidth >768 && 	//This is for grow lightbox
				<LightboxDesktopGrow 
					onClose={closeLightbox} 
					growlightboxOpen={lightboxOpen} /> 
			}

			{
				lightboxOpen && windowWidth <=768 && 	//This is for grow lightbox
				<LightboxMobileGrow
					onClose={closeLightbox} 
					growlightboxOpen={lightboxOpen} />
			}


			{
				jflightboxOpen && windowWidth >768 && 	//This is for judgefoodie lightbox
				<LightboxDesktopJF
					onClose={closejfLightbox} 
					jflightboxOpen={jflightboxOpen} />
			}

			{
				jflightboxOpen && windowWidth <=768 && 	//This is for judgefoodie lightbox
				<LightboxMobileJF
					onClose={closejfLightbox} 
					jflightboxOpen={jflightboxOpen} />
			}

			{
				aarayalightboxOpen && windowWidth >768 && 	//This is for aaraya lightbox
				<LightboxDesktopAaraya
					onClose={closeaarayaLightbox} 
					aarayalightboxOpen={aarayalightboxOpen} /> 
			}

			{
				aarayalightboxOpen && windowWidth <=768 && 	//This is for aaraya lightbox
				<LightboxMobileAaraya
					onClose={closeaarayaLightbox} 
					aarayalightboxOpen={aarayalightboxOpen} />
			}

			{
				mkwlightboxOpen && windowWidth >768 && 	//This is for mkw lightbox
				<LightboxDesktopDC
					onClose={closemkwLightbox} 
					mkwlightboxOpen={mkwlightboxOpen} /> 
			}

			{
				mkwlightboxOpen && windowWidth <=768 && 	//This is for mkw lightbox
				<LightboxMobileDC
					onClose={closemkwLightbox} 
					mkwlightboxOpen={mkwlightboxOpen} />
			}

		</>
	)
}