import React, {useEffect, useState} from "react"
import {motion} from "framer-motion"

export default function LandingPageDesktopItem(props) {

	const [buttonText, setButtonText] = useState('');
  const words = ["Imagine", "Believe", "Achieve"];
  const delay = 500; // Adjust the delay in milliseconds

  const animateText = (index) => {
    const word = words[index];
    const wordLength = word.length;

    // Typing animation
    for (let i = 0; i <= wordLength; i++) {
      setTimeout(() => {
        setButtonText(word.substring(0, i));
      }, i * 150); // Adjust the delay here
    }

    // Delay before clearing
    setTimeout(() => {
      clearText(index);
    }, wordLength * 150 + delay); // Adjust the delay here
  };

  const clearText = (index) => {
    const word = words[index];
    const wordLength = word.length;

    // Clearing animation
    for (let i = wordLength; i >= 0; i--) {
      setTimeout(() => {
        setButtonText(word.substring(0, i));
      }, (wordLength - i) * 50); // Adjust the delay here
    }

    // Move to the next word or restart the loop
    const nextIndex = (index + 1) % words.length;
    setTimeout(() => {
      animateText(nextIndex);
    }, wordLength * 150 + delay); // Adjust the delay here
  };

  // Initial animation start
  useEffect(() => {
    setTimeout(() => {
      animateText(0);
    }, 500);
  }, []); // Empty dependency array to run only once
	
	const scrollToContact = () => {
		const contactElement = document.getElementById("contact");

		if (contactElement) {
		const contactPosition = contactElement.offsetTop;
		// Scroll to the target position with the calculated offset
		window.scrollTo({
			top: contactPosition,
			behavior: "smooth",
		});
		}
	};

	return(
			<div className="d-flex flex-column ps-5 landing-page-lhs">
				<div className="col">

					<motion.h1 
						className="lp-title pt-xl-2 pb-4"
						initial={{x:-100, opacity: 0}}
						viewport={{ once: true }}
						animate={{x: 0, opacity: 1}}
						transition={{duration: 1, type: "spring"}}>
						<span>{props.title_span_1}</span>
						{props.title}
						<span>{props.title_span_2}</span>
					</motion.h1>

					<motion.h2 
						className="lp-text pe-5 pb-2"
						initial={{x:-150, opacity: 0}}
						viewport={{ once: true }}
						animate={{x: 0, opacity: 1}}
						transition={{duration: 1, type: "spring", delay: 0.2}}>
						{props.text}
					</motion.h2>

					<motion.div 
						className="row row-cols-2 pt-3 pb-2 lp-points"
						initial={{x:-200, opacity: 0}}
						viewport={{ once: true }}
						animate={{x: 0, opacity: 1}}
						transition={{duration: 1, type: "spring", delay: 0.4}}>
						<div className="col-1 pb-2">
							<img 
								src="./SVGs/Landing_Page/lp-point.svg" 
								alt="services point icon: bullseye" 
								className="img-fluid"/>
						</div>
						<h3 className="col-11 pb-2 lp-service-1">{props.point_1}</h3>
						<div className="col-1 pb-2">
							<img 
								src="./SVGs/Landing_Page/lp-point.svg" 
								alt="services point icon: bullseye" 
								className="img-fluid"/>
						</div>
						<h3 className="col-11 pb-2 lp-service-2">{props.point_2}</h3>
						<div className="col-1 pb-2">
							<img 
								src="./SVGs/Landing_Page/lp-point.svg" 
								alt="services point icon: bullseye" 
								className="img-fluid"/>
						</div>
						<h3 className="col-11 pb-2 lp-service-3">{props.point_3}</h3>
						<div className="col-1 pb-2 ">
							<img 
								src="./SVGs/Landing_Page/lp-point.svg" 
								alt="services point icon: bullseye" 
								className="img-fluid"/>
						</div>
						<h3 className="col-11 pb-1 lp-service-4">{props.point_4}</h3>
					</motion.div>

					<motion.div 
						className="pt-xl-5 pt-4"
						initial={{x:-150, opacity: 0}}
						viewport={{ once: true }}
						animate={{x: 0, opacity: 1}}
						transition={{duration: 1, type: "spring", delay: 0.6}}>
						<button 
							className="lp-button-shine"
							onClick={scrollToContact}>{buttonText}</button>
					</motion.div>
	
				</div>

				<div className="col pb-5 pt-xl-3">
					<motion.div 
						className="d-flex flex-row justify-content-between pe-5 pt-3 lp-icons"
						initial={{y: 30, opacity: 0}}
						animate={{y: 0, opacity: 1}}
						transition={{type: "spring", delay: 0.8, duration: 1}}>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/aws-dark-mode.svg" : 
								"../SVGs/Landing_Page/aws-light-mode.svg"} 
							className="img-fluid lp-skill-icon-1"
							alt="AWS icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/postgreSQL-dark-mode.svg" : 
								"../SVGs/Landing_Page/postgreSQL-light-mode.svg"} 
							className="img-fluid lp-skill-icon-2"
							alt="PostgreSQL icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/flask-dark-mode.svg" : 
								"../SVGs/Landing_Page/flask-light-mode.svg"} 
							className="img-fluid lp-skill-icon-3"
							alt="Flask icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/bootstrap-dark-mode.svg" : 
								"../SVGs/Landing_Page/bootstrap-light-mode.svg"} 
							className="img-fluid lp-skill-icon-4"
							alt="Bootstrap icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/react-dark-mode.svg" : 
								"../SVGs/Landing_Page/react-light-mode.svg"} 
							className="img-fluid lp-skill-icon-5"
							alt="React icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/threejs-dark-mode.svg" : 
								"../SVGs/Landing_Page/threejs-light-mode.svg"} 
							className="img-fluid lp-skill-icon-6"
							alt="ThreeJS icon"/>
						<img 
							src={props.darkMode ? 
								"../SVGs/Landing_Page/python-dark-mode.svg" : 
								"../SVGs/Landing_Page/python-light-mode.svg"} 
							className="img-fluid lp-skill-icon-7"
							alt="Python icon"/>
					</motion.div>
				</div>
			</div>

	)
}