export default [
    {
        id:"landing_page_1",
        title_span_1: "Digital ",
        title: "Solutions for Small ",
        title_span_2: "Businesses",
        text: "We craft innovative web solutions.",
        point_1: "Sleek Websites",
        point_2: "E-commerce Stores",
        point_3: "Web Scraping",
        point_4: "Digital Marketing",
        button_1: "Imagine",
        button_2: "Believe",
        button_3: "Achieve",
    }
]