import React from "react";

function LightboxMobile({onClose, growlightboxOpen}) {
  const images = [
  {
      id: "grow_1",
      src_display: "./SVGs/Lightbox/Images/Grow/Login-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Login-desktop-thumbnail.png",
      alt: "Grow: Login (Desktop View)",
    },
    {
      id: "grow_2",
      src_display: "./SVGs/Lightbox/Images/Grow/Login-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Login-mobile-thumbnail.png",
      alt: "Grow: Login (Mobile View)",
    },
    {
      id: "grow_3",
      src_display: "./SVGs/Lightbox/Images/Grow/Dashboard-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Dashboard-desktop-thumbnail.png",
      alt: "Grow: Dashboard (Desktop View)",
    },
    {
      id: "grow_4",
      src_display: "./SVGs/Lightbox/Images/Grow/Dashboard-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Dashboard-mobile-thumbnail.png",
      alt: "Grow: Dashboard (Mobile View)",
    },
    {
      id: "grow_5",
      src_display: "./SVGs/Lightbox/Images/Grow/View-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/View-desktop-thumbnail.png",
      alt: "Grow: View Entries (Desktop View)",
    },
    {
      id: "grow_6",
      src_display: "./SVGs/Lightbox/Images/Grow/View-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/View-mobile-thumbnail.png",
      alt: "Grow: View Entries (Mobile View)",
    },
    {
      id: "grow_7",
      src_display: "./SVGs/Lightbox/Images/Grow/Add-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Add-desktop-thumbnail.png",
      alt: "Grow: Add Entries (Desktop View)",
    },
    {
      id: "grow_8",
      src_display: "./SVGs/Lightbox/Images/Grow/Add-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/Grow/Add-mobile-thumbnail.png",
      alt: "Grow: Add Entries (Mobile View)",
    }];

  const [currentImgIndex, setCurrentImgIndex] = React.useState(0);
  const [displayImg, setDisplayImg] = React.useState(images[0].src_display);
  const [fadeEffect, setFadeEffect] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [scrollPosition, setScrollPosition] = React.useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = React.useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = React.useState(false);
  const galleryRef = React.useRef(null);
  const [altText, setAltText] = React.useState(images[0].alt);
  const [highlightedThumbnailIndex, setHighlightedThumbnailIndex] = React.useState(0);
  const [cursorStyle, setCursorStyle] = React.useState("default");
  const [resetZoom, setResetZoom] = React.useState(true);
  const [fadeIn, setFadeIn] = React.useState(false);

  // mobile gesture Swipe left and right
  const [swipeStartX, setSwipeStartX] = React.useState(0);
  const [isSwipe, setIsSwipe] = React.useState(false);

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setFadeIn(growlightboxOpen); // Set fadeIn to true when growlightboxOpen is true
  }, [growlightboxOpen]);

  const handleClose = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setTimeout(() => {
      onClose(); // Call the onClose callback to close the lightbox
      setFadeEffect(false);
    }, 300);
  };

  const handlePrevImg = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setTimeout(() => {
      const newIndex = (currentImgIndex - 1 + images.length) % images.length;
      setCurrentImgIndex(newIndex);
      setDisplayImg(images[newIndex].src_display);
      setAltText(images[newIndex].alt);
      setHighlightedThumbnailIndex(newIndex);
      setFadeEffect(false);

      // Scroll thumbnails to make highlighted thumbnail visible
      scrollThumbnailsIntoView(newIndex);
    }, 300);
    setCursorStyle("default");
  };

  const handleNextImg = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setTimeout(() => {
      const newIndex = (currentImgIndex + 1) % images.length;
      setCurrentImgIndex(newIndex);
      setDisplayImg(images[newIndex].src_display);
      setAltText(images[newIndex].alt);
      setHighlightedThumbnailIndex(newIndex);
      setFadeEffect(false);

      // Scroll thumbnails to make highlighted thumbnail visible
      scrollThumbnailsIntoView(newIndex);
    }, 300);
    setCursorStyle("default");
  };

  const scrollThumbnailsIntoView = (index) => {
    const thumbnailsContainer = document.getElementById("thumbnails");
    const thumbnailWidth = document.querySelector(".thumbnail-mobile").offsetWidth;
    const totalThumbnails = images.length;

    if (thumbnailsContainer) {
      // Calculate the scroll position
      const scrollPosition = thumbnailWidth * index;

      // Check if the highlighted thumbnail is the last image
      if (index === totalThumbnails - 1) {
        thumbnailsContainer.scrollTo({
          left: thumbnailsContainer.scrollWidth, // Scroll to the end
          behavior: "smooth",
        });
      } else {
        thumbnailsContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleThumbnailClick = (index) => {
    setFadeEffect(true);
    setResetZoom(true);
    setScrollPosition({ x: 0, y: 0 });
    setTimeout(() => {
      setCurrentImgIndex(index);
      setDisplayImg(images[index].src_display);
      setAltText(images[index].alt);
      setHighlightedThumbnailIndex(index);
      setFadeEffect(false);
    }, 300);
    setCursorStyle("default");
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.5, 2);
      setCursorStyle("grab");
      setScrollPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = 1;
      setCursorStyle("default");
      setScrollPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleDoubleClickZoom = () => {
    if (zoomLevel === 1 || zoomLevel < 2) {
      handleZoomIn(); // Zoom in on double click
    } else {
      handleZoomOut(); // Zoom out on double click
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;

      setScrollPosition((prevPosition) => ({
        x: prevPosition.x + deltaX / zoomLevel,
        y: prevPosition.y + deltaY / zoomLevel,
      }));

      setDragStart({ x: e.clientX, y: e.clientY });
      setCursorStyle("grabing");
    }
  };

  const handleMouseDown = (e) => {
    if (zoomLevel > 1) {
      setDragStart({ x: e.clientX, y: e.clientY });
      setIsDragging(true);
      setCursorStyle("grabbing");
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setCursorStyle("grab");
  };

  React.useEffect(() => {
    if (resetZoom) {
      setZoomLevel(1);
      setResetZoom(false);
    }
  }, [resetZoom]);

  const handleKeyboardEvents = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        handlePrevImg();
        break;
      case "ArrowRight":
        handleNextImg();
        break;
      case "Escape":
        handleClose();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);

    return () => {
      window.removeEventListener("keydown", handleKeyboardEvents);
    };
  }, [currentImgIndex]);

  // Mobile image swipe left and right for previous and next image start

  React.useEffect(() => {
  const lightboxDiv = document.getElementById("viewbox");

  if (lightboxDiv) {
    const handleSwipeStart = (e) => {
      setSwipeStartX(e.touches[0].clientX);
      setIsSwipe(true);
    };

    const handleSwipeMove = (e) => {
      if (!isSwipe) return;

      const swipeEndX = e.touches[0].clientX;
      const swipeDistance = swipeEndX - swipeStartX;

      // You can adjust the threshold based on your preference
      const swipeThreshold = 100;

      if (swipeDistance > swipeThreshold) {
        handlePrevImg(); // Trigger previous image
      } else if (swipeDistance < -swipeThreshold) {
        handleNextImg(); // Trigger next image
      }
    };

    const handleSwipeEnd = () => {
      setSwipeStartX(0);
    };

    const options = { passive: true };

    const addEventListeners = () => {
      lightboxDiv.addEventListener("touchstart", handleSwipeStart, options);
      lightboxDiv.addEventListener("touchmove", handleSwipeMove, options);
      lightboxDiv.addEventListener("touchend", handleSwipeEnd, options);
    };

    const removeEventListeners = () => {
      lightboxDiv.removeEventListener("touchstart", handleSwipeStart, options);
      lightboxDiv.removeEventListener("touchmove", handleSwipeMove, options);
      lightboxDiv.removeEventListener("touchend", handleSwipeEnd, options);
    };

    if (zoomLevel === 1) {
      addEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }
}, [zoomLevel, handlePrevImg, handleNextImg]);
  // Mobile image swipe left and right for previous and next image start

  // Mobile image move around when zoomed in start
  React.useEffect(() => {
    const galleryDiv = document.getElementById("gallery");

    const handleTouchMoveGallery = (e) => {
      if (isDragging && zoomLevel > 1) {
        // Handle gallery move
        const touchX = e.touches[0].clientX;
        const touchY = e.touches[0].clientY;
        const deltaX = touchX - dragStart.x;
        const deltaY = touchY - dragStart.y;

        setScrollPosition((prevPosition) => ({
          x: prevPosition.x + deltaX / zoomLevel,
          y: prevPosition.y + deltaY / zoomLevel,
        }));

        setDragStart({ x: touchX, y: touchY });
        setCursorStyle("grabbing");
      }
    };

    const handleTouchStartGallery = (e) => {
      if (zoomLevel > 1) {
        const touch = e.touches[0];
        setDragStart({ x: touch.clientX, y: touch.clientY });
        setIsDragging(true);
        setCursorStyle("grabbing");
      }
    };

    const handleTouchEndGallery = () => {
      setIsDragging(false);
      setCursorStyle("grab");
    };

    if (galleryDiv) {
      galleryDiv.addEventListener("touchstart", handleTouchStartGallery);
      galleryDiv.addEventListener("touchmove", handleTouchMoveGallery);
      galleryDiv.addEventListener("touchend", handleTouchEndGallery);

      return () => {
        // Remove touch event listeners when the component unmounts
        galleryDiv.removeEventListener("touchstart", handleTouchStartGallery);
        galleryDiv.removeEventListener("touchmove", handleTouchMoveGallery);
        galleryDiv.removeEventListener("touchend", handleTouchEndGallery);
      };
    }
  }, [zoomLevel, isDragging, dragStart]);
  // Mobile image move around when zoomed in end

  const thumbnails = images.map((eachElement, index) => (
    <img
      src={eachElement.src_thumbnail}
      key={eachElement.id}
      id={eachElement.id}
      className={`thumbnail-mobile m-4 ${fadeEffect ? "fade-out" : "fade-in"} 
                  ${index === highlightedThumbnailIndex ? "highlighted" : ""}`}
      onClick={() => handleThumbnailClick(index)}
    />
  ));

  return (
    <div className={`lightbox-mobile ${fadeIn ? "fade-in" : ""}`} id="lightbox">
      <div id="viewbox" className="viewbox">
        <div className="menubar-mobile d-flex flex-row align-items-center px-4 justify-content-between">
          <div className="image-stack-number">
            <span>{currentImgIndex + 1} / </span>
            <span>{images.length}</span>
          </div>
          <div className="d-flex flex-row align-items-start">
            <div onClick={handleZoomIn}>
              <img src="./SVGs/Lightbox/SVGs/zoom in.svg" alt="Zoom in" className="img-fluid zoom px-2 close"/>
            </div>
            <div onClick={handleZoomOut}>
              <img src="./SVGs/Lightbox/SVGs/zoom out.svg" alt="Zoom out" className="img-fluid zoom px-2 close"/>
            </div>
            <div onClick={handleClose}>
              <img src="./SVGs/Lightbox/SVGs/close.svg" alt="Close Lightbox" className="img-fluid close"/>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-row gallery-mobile justify-content-center pt-4"
          id="gallery"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp}>
          <div onClick={handlePrevImg} className="previous-button d-flex align-items-center">
            <img src="./SVGs/Lightbox/SVGs/previous.svg" alt="Previous Image" className="img-fluid close" />
          </div>
          <div
            className="image-full-screen d-flex justify-content-center img-fluid"
            ref={galleryRef}>
            <img
              src={displayImg}
              alt="Displayed Image"
              className={`full-screen-img-mobile img-fluid 
                          ${fadeEffect ? "fade-out" : "fade-in"}
                          ${isDragging ? "translate-transition" : ""}`}
              alt={`Image ${currentImgIndex + 1}`}
              onDoubleClick={handleDoubleClickZoom}
              style={{
                      transform: `translate(${scrollPosition.x}px, ${scrollPosition.y}px) scale(${zoomLevel})`,
                      cursor: cursorStyle,
                    }}/>
          </div>
          <div onClick={handleNextImg} className="next-button d-flex align-items-center">
            <img src="./SVGs/Lightbox/SVGs/next.svg" alt="Next Image" className="img-fluid close" />
          </div>
        </div>
        <div className="img-desc text-center pt-1">{altText}</div>
      </div>
      
      <div className="thumbnails-mobile d-flex flex-row align-items-center" id="thumbnails">
        {thumbnails}
      </div>
    </div>
  );
}

export default LightboxMobile;
