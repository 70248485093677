import React from "react"

export default function ContactContentDesktopItem(props) {

	const [currentTime, setCurrentTime] = React.useState(new Date());

	const formatTime = (time) => {
		const hours = time.getHours() % 12 || 12; // Convert to 12-hour format
		const minutes = time.getMinutes();
		const ampm = time.getHours() >= 12 ? 'PM' : 'AM';
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}${ampm}`;
	};

	setTimeout(() => {
    	setCurrentTime(new Date());
	}, 1000);


	return(
		<>
			<h3 className="section-heading pb-4">{props.title}<span>{props.title_span}</span></h3>
			<p className="section-text pe-5 pb-5">{props.text}</p>
			<h4 
				className="contact-heading-timings pb-4 pt-1">
				{props.s_title_1}<span>{props.s_title_span}</span>{props.s_title_2}
			</h4>
			<p 
				className="contact-text-timings pe-5 pb-2">
				{props.phone}
			</p>
			<p 
				className="contact-text-timings pe-5 pb-2">
				{props.email}
			</p>
			<p 
				className="contact-text-timings pe-5 pb-2">
				{props.timings}
			</p>
			<p className="contact-text-timings pe-5 pb-5">Current Time in India is {formatTime(currentTime)}</p>

		</>
	)
}