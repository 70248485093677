import React from "react"

export default function Portfolio({darkMode, openLightbox, openjfLightbox, openaarayaLightbox, openmkwLightbox}) {
	return(
		<div className="pt-md-5" id="portfolio">
		<section className="container pt-5" >
			<h3 className="section-heading text-center">Featured <span>Projects</span></h3>
			<div 
				id="carouselExampleAutoplaying" 
				className={`carousel carousel${darkMode? "-dark" : ""} slide`} 
				data-bs-ride="carousel">
		        <div className="carousel-inner">
		            <div className="carousel-item active ps-5 pe-5" onClick={openLightbox}>
		                <div className="container">
		                    <div 
		                    	className="col d-flex flex-lg-row flex-column align-items-center justify-content-center text-center pt-3 ">
		                        <div className="col-lg-8 laptop-container">
		                        	<img 
		                        		src = "./SVGs/Lightbox/slide_grow.png" 
		                        		alt="Slide: Grow - Expense Tracking App"
		                        		className="img-fluid"/>
		                        </div>
		                        <div className="col-lg-4 col-md-10 col-12 portfolio-text-container">
		                            <h4 className="portfolio-heading pb-3  pt-2"><span>Grow: </span>Expense Tracker</h4>
		                            <p className="portfolio-desc pb-4">
		                            	Manage money smartly with <b>Grow</b>. 
		                            	Set savings goals, track spending, categorize 
		                            	expenses, and gain insights with user-friendly dashboards. 
		                            </p>
		                            <p className="section-text pb-1">Made With</p>
		                            <div className="image-container col-12">
		                            	<div className="d-flex justify-content-around w-100">
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Bootstrap.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Bootstrap icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Javascript.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Javascript icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/PostgreSQL.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="PostgreSQL icon"/>
		                                	<img 
			                                	src={`../SVGs/Lightbox/SVGs/AWS-${darkMode ? "dark-mode.svg" : "light-mode.svg"}`} 
			                                	className="made-with-icons img-fluid p-md-1 p-2"
			                                	alt="AWS icon" />
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Python.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Python icon"/>
		                            	</div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		           	<div className="carousel-item ps-5 pe-5" onClick={openjfLightbox}>
		                <div className="container">
		                    <div className="col d-flex flex-lg-row flex-column align-items-center justify-content-center text-center pt-3 ">
		                        <div className="col-lg-8 laptop-container">
		                            <img 
		                            	src = "./SVGs/Lightbox/slide_judgefoodie.png" 
		                            	className="img-fluid"
		                            	alt="Slide: Judge Foodie - Restaurant Rating App"/>
		                        </div>
		                        <div className="col-lg-4 col-md-10 col-12 portfolio-text-container">
		                            <h4 className="portfolio-heading pb-3  pt-2">
		                            	<span>Judge Foodie: </span>Restaurant Rating 
		                            </h4>
		                            <p className="portfolio-desc pb-4">
		                            	Discover, rate, and savor with <b>Judge Foodie</b>! 
		                            	Curate your personalized list and elevate your 
		                            	culinary experience with the foodie app.
		                            </p>
		                            <p className="section-text">Made With</p>
		                            
		                            <div className="image-container col-12">
		                            	<div className="d-flex justify-content-around w-100">
			                                <img 
			                                	src="../SVGs/Lightbox/SVGs/React.svg" 
			                                	className="made-with-icons img-fluid p-md-1 p-2"
			                                	alt="React icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Bootstrap.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Bootstrap icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/PostgreSQL.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="PostgreSQL icon"/>
		                                	<img 
			                                	src={`../SVGs/Lightbox/SVGs/AWS-${darkMode ? "dark-mode.svg" : "light-mode.svg"}`} 
			                                	className="made-with-icons img-fluid p-md-1 p-2" 
			                                	alt="AWS icon"/>
		                                	<img 
		                                		src={`../SVGs/Lightbox/SVGs/Flask-${darkMode ? "dark-mode.svg" : "light-mode.svg"}`} 
		                                		className="made-with-icons img-fluid p-md-1 p-2" 
		                                		alt="Flask icon"/>
		                            	</div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		            <div className="carousel-item ps-5 pe-5" onClick={openaarayaLightbox}>
		                <div className="container">
		                    <div className="col d-flex flex-lg-row flex-column align-items-center justify-content-center text-center pt-3 ">
		                        <div className="col-lg-8 laptop-container">
		                            <img 
		                            	src = "./SVGs/Lightbox/slide_aarayafashion.png" 
		                            	className="img-fluid"
		                            	alt="Slide: Aaraya Fashion - Shopify Store"/>
		                        </div>
		                        <div className="col-lg-4 col-md-10 col-12 portfolio-text-container">
		                            <h4 className="portfolio-heading pb-3  pt-2"><span>Aaraya Fashion: </span>Shopify Store</h4>
		                            <p className="portfolio-desc pb-4">
		                            	<b>Aaraya Fashion</b> offers personalized curated collections for 
		                            	every occasion and affordable elegance with exclusive discounts.
		                            </p>
		                            <p className="section-text pb-1">Made With</p>
		                            <div className="image-container col-12">
		                            	<div className="d-flex justify-content-around w-100">
		                            		<img 
		                            			src="../SVGs/Lightbox/SVGs/Shopify.svg" 
		                            			className="made-with-icons img-fluid p-md-1 p-2"
		                            			alt="Shopify icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/CSS.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="CSS icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Bootstrap.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Bootstrap icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Javascript.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Javascript icon"/>
		                            	</div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		            <div className="carousel-item ps-5 pe-5" onClick={openmkwLightbox}>
		                <div className="container">
		                    <div className="col d-flex flex-lg-row flex-column align-items-center justify-content-center text-center pt-3 ">
		                        <div className="col-lg-8 laptop-container">
		                            <img 
		                            	src = "./SVGs/Lightbox/slide_decorcraft3d.png" 
		                            	className="img-fluid" 
		                            	alt="Slide: DecorCraft 3D - Interactive Kitchen Design App"/>
		                        </div>
		                        <div className="col-lg-4 col-md-10 col-12 portfolio-text-container">
		                            <h4 className="portfolio-heading pb-3  pt-2">
			                            <span>DecorCraft 3D: </span> 
			                            Interactive Kitchen Design
			                        </h4>
		                            <p className="portfolio-desc pb-4">
		                            	Transform your kitchen with <b>DecorCraft 3D. </b> 
		                            	Get real-time cost estimation of your perfect layout 
		                            	from a variety of customizable decor options.
		                            </p>
		                            <p className="section-text pb-1">Made With</p>
		                            <div className="image-container col-12">
		                            	<div className="d-flex justify-content-around w-100">
		                            		<img 
		                            			src="../SVGs/Lightbox/SVGs/threeJS.svg" 
		                            			className="made-with-icons img-fluid p-md-1 p-2"
		                            			alt="ThreeJS icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/CSS.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="CSS icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Javascript.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Javascript icon"/>
		                                	<img 
		                                		src="../SVGs/Lightbox/SVGs/Python.svg" 
		                                		className="made-with-icons img-fluid p-md-1 p-2"
		                                		alt="Python icon"/>
		                            	</div>
		                            </div>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		        <a
		        	className="carousel-control-prev" 
		        	type="button" 
		        	data-bs-target="#carouselExampleAutoplaying" 
		        	data-bs-slide="prev">
		            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
		            <span className="visually-hidden">Previous</span>
		        </a>
		        <a
		        	className="carousel-control-next" 
		        	type="button" 
		        	data-bs-target="#carouselExampleAutoplaying" 
		        	data-bs-slide="next">
		            <span className="carousel-control-next-icon" aria-hidden="true"></span>
		            <span className="visually-hidden">Next</span>
		        </a>
		    </div>
		</section>
		</div>
	)
}