import React from "react"
import {Link} from "react-router-dom"

export default function TermsAndConditions(props) {
	React.useEffect(() => {
	// Scroll to the top on component mount
		window.scrollTo(0, 0);
	}, []);

	return(
		<section className={`container pt-5 pb-5 ps-3 pe-3 ${props.darkMode ? "tncdark": ""}`}>
			<div className="minor-page-navbar pb-3">
				<Link to="/" className="minor-page-back-button">
					<div className="minor-page-navbar-back-button">
						<img 
						src={props.darkMode? 
							"../SVGs/Minor_Pages/back-darkmode.svg": "../SVGs/Minor_Pages/back-lightmode.svg"}
						className="img-fluid"/>
						<p>Back</p>
					</div>
				</Link>	
				<div onClick={props.toggleDarkMode} className="pe-3 darkMode-toggler-icon">
             	    <img src={`./SVGs/Navbar/${props.darkMode? "moon.svg" : "sun.svg"}`}/>
        		</div>
			</div>
			
			<h3 className="text-center section-heading pb-3">Terms and <span>Conditions</span></h3>
			<div>
				<h5 className="minor-page-heading pt-4 pb-1">Acceptance of Terms</h5>
				<p className="minor-page-text">The Terms and Conditions mentioned on our website www.arrowcode.tech will be applied fully and affect to your use of this website. By using our website and services, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please refrain from using our website and services. Minors or people below 18 years old are not allowed to use this website without a parent or guardian’s presence.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Definitions</h5>
				<ul className="minor-page-text">
					<li>"Company," "we," "us," or "our" refers to Arrow Code.</li>
					<li>"Website" or "site" refers to www.arrowcode.tech.</li>
					<li>"User" or "you" refers to anyone who accesses or uses our website or services.</li>
					<li>"Your content" shall mean any audio, video text, images, or other material you choose to display on this website.</li>
				</ul>

				<h5 className="minor-page-heading pt-4 pb-1">Company Services</h5>
				<p className="minor-page-text">We provide web development and digital marketing services worldwide and other jurisdictions as mentioned on our website.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Privacy Policy</h5>
				<p className="minor-page-text">Our Privacy Policy outlines how we collect, use, and protect user data. By using our website and services, you agree to our Privacy Policy.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Intellectual Property</h5>
				<p className="minor-page-text">All intellectual property rights, including copyrights and trademarks, related to the content on our website and services belong to us or our licensors.</p>
				<p className="minor-page-text">Other than the content you own, under these Terms, Arrow Code and/or its licensors own all the intellectual property rights and materials contained in this website. You are granted limited license only for purposes of viewing the material contained on this website.</p>

				<h5 className="minor-page-heading pt-4 pb-1">User Rights and Responsibilities</h5>
				<ul className="minor-page-text">
					<li>Users are responsible for maintaining the confidentiality of their account information.</li>
					<li>Users shall not engage in any unlawful or prohibited activities on our website.</li>
					<li>Users must comply with all applicable laws and regulations.</li>
				</ul>

				<h5 className="minor-page-heading pt-4 pb-1">Restrictions</h5>
				<p className="minor-page-text">You are specifically restricted from all of the following unless you have our express written consent:</p>
				<ul className="minor-page-text">
					<li>Publishing any website material in any other media.</li>
					<li>Selling, sublicensing and/or otherwise commercializing any website material.</li>
					<li>Publicly performing and/or showing any website material.</li>
					<li>Using this website in any way that is or may be damaging to us or the website.</li>
					<li>Using this website in any way that impacts user access to this website.</li>
					<li>Using this website contrary to applicable laws and regulations, or in any way may cause harm to the website, or to any person or business entity.</li>
					<li>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this website.</li>
					<li>Using this website to engage in any advertising or marketing.</li>
				</ul>
				<p className="minor-page-text">Certain areas of this website are restricted from being accessed by you. We may further restrict access by you to any areas of this website, at any time, in absolute discretion. Any user id and password you may have for this website are confidential and you must maintain confidentiality as well. You agree that if you provide any information that is untrue, incorrect, incomplete, etc., we have reasonable ground to believe that the information provided by you is untrue, incorrect, incomplete, etc., or not in accordance with the Terms and Conditions. We reserve the right to terminate accounts, remove or edit content, or cancel orders in our sole discretion without any intimation to you and in such an event the we are not liable to give any explanation to you or any person authorized by you.</p>


				<h5 className="minor-page-heading pt-4 pb-1">Your Content</h5>
				<p className="minor-page-text">By displaying your content, you grant us a non-exclusive, worldwide irrevocable, sub-licensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media. Your content must be your own and must not be invading any third-party’s rights. We reserve the right to remove any of your content from this website at any time without notice.</p>
				

				<h5 className="minor-page-heading pt-4 pb-1">Disclaimers</h5>
				<ul className="minor-page-text">
					<li>Information on our website is for informational purposes only and does not constitute professional advice. All information on the website is provided in good faith; however, we make no representation, guarantee, or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, suitability, or completeness of any information on the website.</li>
					<li>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the website or reliance on any information provided on the website.Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the website or reliance on any information provided on the website. Your use of the website and your reliance on any information on the website is solely at your own risk.</li>
					<li>The website may contain (or you may be sent through the website) links to other websites or content belonging to or originating from third parties or links to websites and features. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, suitability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the website or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</li>
					<li>The website may contain links to affiliate websites, and we may receive an affiliate commission for any purchases or actions made by you on the affiliate websites using such links.</li>
					<li>The website may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume that all users will have the same experiences. Your individual results may vary. The testimonials on the website are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted. They appear on the website verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have been shortened for the sake of brevity, where the full testimonial contained extraneous information not relevant to the general public. The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.</li>
					<li>This website may include content from guest contributors and any views or opinions expressed in such posts are personal and do not represent those of Arrow Code or any of its staff, affiliates, or representatives unless explicitly stated.</li>
					<li>All logos and trademarks of third parties referenced on our website are the trademarks and logos of their respective owners. Any inclusion of such trademarks or logos does not imply or constitute any approval, endorsement, or sponsorship of Arrow Code by such owners.</li>
				</ul>

				<h5 className="minor-page-heading pt-4 pb-1">Governing Law</h5>
				<p className="minor-page-text">These Terms and Conditions are governed by the laws of India. Any legal disputes will be resolved in the courts of Mumbai (Maharashtra, India).</p>

				<h5 className="minor-page-heading pt-4 pb-1">Dispute Resolution</h5>
				<p className="minor-page-text">Any disputes arising from these Terms and Conditions shall be resolved through mediation or arbitration in accordance with the laws of India.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Changes to Terms</h5>
				<p className="minor-page-text">We may update the Terms and Conditions from time to time. When we do, we will revise the "Last Updated" date at the bottom of this page. Please review this Terms and Conditions page regularly to stay informed.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Limitation of liability</h5>
				<p className="minor-page-text">In no event shall Arrow Code, nor any of its officers, directors, employees, and representatives be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. Arrow Code, including its officers, directors, employees, and representatives, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Indemnification</h5>
				<p className="minor-page-text">You hereby indemnify to the fullest extent Arrow Code, including its officers, directors, employees, and representatives, from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Assignment</h5>
				<p className="minor-page-text">Arrow Code is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms and Conditions without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms and Conditions.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Contact Information</h5>
				<p className="minor-page-text">For any inquiries or concerns regarding these Terms and Conditions, please contact us at support@arrowcode.tech.</p>
				

				<h5 className="minor-page-heading pt-4 pb-1">Severability</h5>
				<p className="minor-page-text">If any part of these Terms and Conditions is found to be unenforceable, the rest of the agreement remains in effect.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Entire Agreement</h5>
				<p className="minor-page-text">These Terms and Conditions represent the entire agreement between the user and our company, and supersedes all prior agreements and understandings.</p>

				<h5 className="minor-page-heading pt-4 pb-1">Electronic Acceptance</h5>
				<p className="minor-page-text pb-5">By using our website and services, you acknowledge that you have read, understood, and agree to these terms electronically, which constitutes a legally binding agreement.</p>
				

				<small className="tnc-update">Last Updated: 14<sup>th</sup> January 2024</small>
			</div>
			
		</section>
	)
}