import React from "react";

function LightboxDesktop({onClose, jflightboxOpen}) {
  const images = [
  {
      id: "judge_foodie_1",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Login-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Login-desktop-thumbnail.png",
      alt: "Judge Foodie: Login (Desktop View)",
    },
    {
      id: "judge_foodie_2",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Login-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Login-mobile-thumbnail.png",
      alt: "Judge Foodie: Login (Mobile View)",
    },
    {
      id: "judge_foodie_3",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Dashboard-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Dashboard-desktop-thumbnail.png",
      alt: "Judge Foodie: Dashboard (Desktop View)",
    },
    {
      id: "judge_foodie_4",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Dashboard-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Dashboard-mobile-thumbnail.png",
      alt: "Judge Foodie: Dashboard (Mobile View)",
    },
    {
      id: "judge_foodie_5",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Add-desktop.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Add-desktop-thumbnail.png",
      alt: "Judge Foodie: Add New Retaurant (Desktop View)",
    },
    {
      id: "judge_foodie_6",
      src_display: "./SVGs/Lightbox/Images/JudgeFoodie/Add-mobile.png",
      src_thumbnail: "./SVGs/Lightbox/Thumbnails/JudgeFoodie/Add-mobile-thumbnail.png",
      alt: "Judge Foodie: Add New Restaurant (Mobile View)",
    }];

  const [currentImgIndex, setCurrentImgIndex] = React.useState(0);
  const [displayImg, setDisplayImg] = React.useState(images[0].src_display);
  const [fadeEffect, setFadeEffect] = React.useState(false);
  const [zoomLevel, setZoomLevel] = React.useState(1);
  const [scrollPosition, setScrollPosition] = React.useState({ x: 0, y: 0 });
  const [dragStart, setDragStart] = React.useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = React.useState(false);
  const galleryRef = React.useRef(null);
  const [altText, setAltText] = React.useState(images[0].alt);
  const [highlightedThumbnailIndex, setHighlightedThumbnailIndex] = React.useState(0);
  const [cursorStyle, setCursorStyle] = React.useState("default");
  const [resetZoom, setResetZoom] = React.useState(true);
  const [fadeIn, setFadeIn] = React.useState(false);

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setFadeIn(jflightboxOpen); // Set fadeIn to true when jflightboxOpen is true
  }, [jflightboxOpen]);


  const handleClose = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setTimeout(() => {
      onClose(); // Call the onClose callback to close the lightbox
      setFadeEffect(false);
    }, 300);
  };


  const handlePrevImg = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setScrollPosition({ x: 0, y: 0 });
    setTimeout(() => {
      const newIndex = (currentImgIndex - 1 + images.length) % images.length;
      setCurrentImgIndex(newIndex);
      setDisplayImg(images[newIndex].src_display);
      setAltText(images[newIndex].alt);
      setHighlightedThumbnailIndex(newIndex);
      setFadeEffect(false);
    }, 300);
    setCursorStyle("default");
  };

  const handleNextImg = () => {
    setFadeEffect(true);
    setResetZoom(true);
    setScrollPosition({ x: 0, y: 0 });
    setTimeout(() => {
      const newIndex = (currentImgIndex + 1) % images.length;
      setCurrentImgIndex(newIndex);
      setDisplayImg(images[newIndex].src_display);
      setAltText(images[newIndex].alt);
      setHighlightedThumbnailIndex(newIndex);
      setFadeEffect(false);
    }, 300);
    setCursorStyle("default");
  };

  const handleThumbnailClick = (index) => {
    setFadeEffect(true);
    setResetZoom(true);
    setScrollPosition({ x: 0, y: 0 });
    setTimeout(() => {
      setCurrentImgIndex(index);
      setDisplayImg(images[index].src_display);
      setAltText(images[index].alt);
      setHighlightedThumbnailIndex(index);
      setFadeEffect(false);
    }, 300);
    setCursorStyle("default");
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = Math.min(prevZoom + 0.5, 2);
      setCursorStyle("grab");
      setScrollPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => {
      const newZoom = 1;
      setCursorStyle("default");
      setScrollPosition({ x: 0, y: 0 });
      return newZoom;
    });
  };

  const handleDoubleClickZoom = () => {
    if (zoomLevel === 1 || zoomLevel <2) {
      handleZoomIn(); // Zoom in on double click
    } else {
      handleZoomOut(); // Zoom out on double click
    }
  }

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;

      setScrollPosition((prevPosition) => ({
        x: prevPosition.x + deltaX / zoomLevel,
        y: prevPosition.y + deltaY / zoomLevel,
      }));

      setDragStart({ x: e.clientX, y: e.clientY });
      setCursorStyle("grabing");
    }
  };

  const handleMouseDown = (e) => {
    if (zoomLevel > 1) {
      setDragStart({ x: e.clientX, y: e.clientY });
      setIsDragging(true);
      setCursorStyle("grabbing");
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setCursorStyle("grab");
  };

  React.useEffect(() => {
    if (resetZoom) {
      setZoomLevel(1);
      setResetZoom(false);
    }
  }, [resetZoom]);



  const handleKeyboardEvents = (e) => {
    switch (e.key) {
      case "ArrowLeft":
        handlePrevImg();
        break;
      case "ArrowRight":
        handleNextImg();
        break;
      case "Escape":
        handleClose();
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyboardEvents);

    return () => {
      window.removeEventListener("keydown", handleKeyboardEvents);
    };
  }, [currentImgIndex]);

  const thumbnails = images.map((eachElement, index) => (
    <img
      src={eachElement.src_thumbnail}
      key={eachElement.id}
      id={eachElement.id}
      className={`thumbnail m-4 ${fadeEffect ? "fade-out" : "fade-in"} 
                  ${index === highlightedThumbnailIndex ? "highlighted" : ""}`}
      onClick={() => handleThumbnailClick(index)}
    />
  ));

  return (
    <div className={`lightbox ${fadeIn ? "fade-in" : ""}`}>
      <div className="menubar d-flex flex-row align-items-center px-4 justify-content-between">
        <div className="image-stack-number">
          <span>{currentImgIndex + 1} / </span>
          <span>{images.length}</span>
        </div>
        <div className="d-flex flex-row align-items-start">
          <div onClick={handleZoomIn}><img src="./SVGs/Lightbox/SVGs/zoom in.svg" alt="Zoom in" className="img-fluid zoom px-2 close"/></div>
          <div onClick={handleZoomOut}><img src="./SVGs/Lightbox/SVGs/zoom out.svg" alt="Zoom out" className="img-fluid zoom px-2 close"/></div>
          <div onClick={handleClose}><img src="./SVGs/Lightbox/SVGs/close.svg" alt="Close Lightbox" className="img-fluid close"/></div>
        </div>
      </div>
      <div
        className="d-flex flex-row gallery align-items-center justify-content-center pt-4"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseUp}
      >
        <div onClick={handlePrevImg} className="previous-button d-flex align-items-center">
          <img src="./SVGs/Lightbox/SVGs/previous.svg" alt="Previous Image" className="img-fluid close" />
        </div>
        <div
          className="image-full-screen d-flex justify-content-center img-fluid"
          ref={galleryRef}
        >
          <img
            src={displayImg}
            alt="Displayed Image"
            className={`full-screen-img img-fluid 
                        ${fadeEffect ? "fade-out" : "fade-in"}
                        ${isDragging ? "translate-transition" : ""}`}
            alt={`Image ${currentImgIndex + 1}`}
            onDoubleClick={handleDoubleClickZoom}
            style={{
                    transform: `translate(${scrollPosition.x}px, ${scrollPosition.y}px) scale(${zoomLevel})`,
                    cursor: cursorStyle,
                  }}
          />
        </div>
        <div onClick={handleNextImg} className="next-button d-flex align-items-center">
          <img src="./SVGs/Lightbox/SVGs/next.svg" alt="Next Image" className="img-fluid close" />
        </div>
      </div>
      <div className="img-desc text-center pt-1">{altText}</div>
      <div className="thumbnails d-flex flex-row justify-content-center align-items-center pt-3">
        {thumbnails}
      </div>
    </div>
  );
}

export default LightboxDesktop;
