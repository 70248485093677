import React from "react"
import Logo from "../SVG-Components/logo"

export default function Navbar(props) {

  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    // Function to handle scroll events
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setScrollY(currentScrollY);
    };

    // Add the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the listener when the component unmounts
    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToLandingPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // You can adjust the scroll behavior (smooth or instant)
    });
  };

  const scrollToPortfolio = () => {

    const portfolioElement = document.getElementById("portfolio");

    if (portfolioElement) {
      let offset;

      // Set the offset based on window width
      if (props.windowWidth <= 992 && props.windowWidth >= 768) {
        offset = portfolioElement.clientHeight * 0.30; // 15% of the height
      } else if (props.windowWidth <= 768) {
        offset = portfolioElement.clientHeight * 0.40; // 15% of the height
      } else {
        offset = 0; // No offset
      }

      const portfolioPosition = portfolioElement.offsetTop;

      // Scroll to the target position with the calculated offset
      window.scrollTo({
        top: portfolioPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToServices = () => {

    const servicesElement = document.getElementById("services");

    if (servicesElement) {
      let offset;

      // Set the offset based on window width
      if (props.windowWidth <= 992) {
        offset = servicesElement.clientHeight * 0.15; // 15% of the height
      } else {
        offset = 0; // No offset
      }

      const servicesPosition = servicesElement.offsetTop;

      // Scroll to the target position with the calculated offset
      window.scrollTo({
        top: servicesPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToAboutUs = () => {

    const aboutusElement = document.getElementById("about-us");

    if (aboutusElement) {
      let offset;

      // Set the offset based on window width
      if (props.windowWidth <= 992) {
        offset = aboutusElement.clientHeight * 0.19; // 15% of the height
      } else {
        offset = 0; // No offset
      }

      const aboutusPosition = aboutusElement.offsetTop;

      // Scroll to the target position with the calculated offset
      window.scrollTo({
        top: aboutusPosition - offset,
        behavior: "smooth",
      });
    }
  };

  const scrollToContact = () => {

    const contactElement = document.getElementById("contact");

    if (contactElement) {
      let offset;

      // Set the offset based on window width
      if (props.windowWidth <= 992) {
        offset = contactElement.clientHeight * 0.10; // 15% of the height
      } else {
        offset = 0; // No offset
      }

      const contactPosition = contactElement.offsetTop;

      // Scroll to the target position with the calculated offset
      window.scrollTo({
        top: contactPosition - offset,
        behavior: "smooth",
      });
    }
  };



  // Define the styles based on scroll position
  const navbarStyle = {
    backgroundColor: scrollY > 10 ? '#F0EDEA' : 'transparent',            //card-background
    boxShadow: `0 0 ${Math.min(scrollY / 10, 20)}px rgba(0, 0, 0, 0.2)`,
  };

  const navbarStyleDark = {
    backgroundColor: scrollY > 10 ? '#1d1d1d' : 'transparent',            //card-background-dark
    boxShadow: `0 0 ${Math.min(scrollY / 10, 20)}px rgba(0, 0, 0, 0.2)`,
  };

	
	return(
	<div className="container pt-3" id="navbar-container">
		<nav 
			id="navbar" 
			className={`navbar navbar-expand-lg ps-4 ${props.darkMode? "navbar-dark": ""}`} 
			style={props.darkMode ? navbarStyleDark : navbarStyle}>
      
			<div 
        className="navbar-brand-div" 
        onClick={scrollToLandingPage}>
          <Logo darkMode={props.darkMode} />
			</div>
	    
	    {
        props.windowWidth < 992 && 
          <div onClick={props.toggleDarkMode} className="pe-3 darkMode-toggler-icon">
            <img 
              src={`./SVGs/Navbar/${props.darkMode? "moon.svg" : "sun.svg"}`}
              className="img-fluid"
              alt="Dark-mode/Light-mode icon"/>
          </div>
      }

    	<button 
        className={`navbar-toggler me-3 ${props.darkMode ? "dark-navbar-toggler" : ""}`}
        type="button" 
        data-bs-toggle="collapse" 
        data-bs-target="#navbarSupportedContent" 
        aria-controls="navbarSupportedContent" 
        aria-expanded="false" 
        aria-label="Toggle navigation">
          <span className="navbar-toggler-icon dark-navbar-toggler"></span>
      </button>
      <div className={`collapse navbar-collapse `} id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto" >
            <li 
              className="nav-item pe-4" 
              data-bs-toggle={props. windowWidth <=992 ? "collapse" : ""}  
              data-bs-target="#navbarSupportedContent"
              onClick={scrollToPortfolio}>
              Portfolio
            </li>
            <li 
              className="nav-item pe-4" 
              data-bs-toggle={props. windowWidth <=992 ? "collapse" : ""}  
              data-bs-target="#navbarSupportedContent"
              onClick={scrollToServices}>
              Services
            </li>
            <li 
              className="nav-item pe-4" 
              data-bs-toggle={props. windowWidth <=992 ? "collapse" : ""}  
              data-bs-target="#navbarSupportedContent"
              onClick={scrollToAboutUs}>
              About Us
            </li>
            <li 
              className="nav-item pe-4" 
              data-bs-toggle={props. windowWidth <=992 ? "collapse" : ""} 
              data-bs-target="#navbarSupportedContent"
              onClick={scrollToContact}>
              Contact
            </li>
          </ul>
      </div> 
      {
        props.windowWidth >= 992 && 
              <div 
                onClick={props.toggleDarkMode} 
                className="pe-3 darkMode-toggler-icon">
              	<img 
                  src={`./SVGs/Navbar/${props.darkMode? "moon.svg" : "sun.svg"}`}
                  className="img-fluid"
                  alt="Dark-mode/Light-mode icon"/>
              </div>
      }
	  </nav>
	</div>   
	)
}