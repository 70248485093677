import React from "react"

export default function AboutUsWebDevSVG(props) {
	return(
		<>
			<svg width="90" height="90" viewBox="0 0 90 90" fill="none" id="svg299" xmlns="http://www.w3.org/2000/svg">
			    <defs id="defs303" />
			    <g id="g564" transform="matrix(0.8003842,0,0,0.8003842,0.28893784,-27.053788)">
			        <g id="g492">
			            <path d="M 101.15833,140 H 105 v -24.41141 c -0.005,-6.2999 -1.81332,-12.3398 -5.02722,-16.7945 -3.214619,-4.4547 -7.57294,-6.960496 -12.118865,-6.967696 H 52.146158 c -4.545855,0.0072 -8.904101,2.512996 -12.118506,6.967696 -3.214404,4.4547 -5.022529,10.4946 -5.027652,16.7945 V 140 h 3.842103 v -16.6771 c 0.0052,-6.35851 1.830132,-12.45451 5.07441,-16.95071 3.244349,-4.4962 7.643076,-7.0253 12.231215,-7.0325 h 27.704616 c 4.588212,0.007 8.986936,2.5363 12.231146,7.0325 3.244201,4.4962 5.06907,10.5922 5.07484,16.95071 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path165" />
			            <g id="g437" transform="translate(-0.959609,23.000326)">
			                <path d="m 76.385609,48.678754 h -11.2712 c -0.1594,3e-4 -0.3123,0.0638 -0.4249,0.1767 -0.1126,0.1129 -0.1757,0.2659 -0.1756,0.4254 v 17.8051 h 12.4737 v -17.8051 c 10e-5,-0.0791 -0.0155,-0.1574 -0.0457,-0.2305 -0.0303,-0.073 -0.0746,-0.1394 -0.1305,-0.1953 -0.0559,-0.0559 -0.1223,-0.1003 -0.1954,-0.1305 -0.073,-0.0303 -0.1513,-0.0458 -0.2304,-0.0458 z m -5.5977,10.1167 c -0.3525,-0.0016 -0.69,-0.1424 -0.9393,-0.3916 -0.2492,-0.2493 -0.39,-0.5868 -0.3916,-0.9393 v -2.0537 c 4e-4,-0.3527 0.1409,-0.6908 0.3904,-0.94 0.2496,-0.2492 0.5878,-0.3892 0.9405,-0.3892 0.3527,0 0.691,0.14 0.9405,0.3892 0.2496,0.2492 0.39,0.5873 0.3905,0.94 v 2.0537 c -0.002,0.3525 -0.1424,0.69 -0.3917,0.9393 -0.2492,0.2492 -0.5868,0.39 -0.9393,0.3916 z" fill={props.darkMode? "#b3b3b3" : "#3f3d56" } id="about-us-svg-wd-path167" />
			                <path d="m 95.050109,20.891674 h -48.1804 c -0.414,6.6e-4 -0.8108,0.16538 -1.1035,0.45808 -0.2927,0.29271 -0.4574,0.6895 -0.4581,1.1034 v 32.5121 c 4e-4,0.414 0.1651,0.811 0.4578,1.1037 0.2928,0.2928 0.6897,0.4575 1.1038,0.4578 h 48.1804 c 0.414,-3e-4 0.811,-0.165 1.103,-0.4578 0.293,-0.2927 0.458,-0.6897 0.458,-1.1037 v -32.5121 c -0.001,-0.4139 -0.165,-0.81069 -0.458,-1.10339 -0.293,-0.2927 -0.689,-0.45743 -1.103,-0.45809 z" fill={props.darkMode? "#b3b3b3" : "#3f3d56" } id="about-us-svg-wd-path169" />
			                <path d="m 94.647109,22.019654 h -47.375 c -0.2216,4e-4 -0.434,0.0888 -0.5905,0.2457 -0.1566,0.1569 -0.2445,0.3695 -0.2446,0.5911 v 31.7062 c 4e-4,0.2214 0.0885,0.4336 0.2451,0.5901 0.1565,0.1565 0.3687,0.2446 0.59,0.245 h 47.375 c 0.222,-4e-4 0.434,-0.0885 0.59,-0.245 0.157,-0.1565 0.245,-0.3687 0.245,-0.5901 v -31.7062 c 0,-0.2216 -0.088,-0.4342 -0.244,-0.5911 -0.157,-0.1569 -0.369,-0.2453 -0.591,-0.2457 z" fill={props.darkMode? "#010101" : "#ffffff" } id="about-us-svg-wd-path171" />
			                <path d="m 64.475109,66.516454 v 1.7178 c 2e-4,0.089 0.0356,0.1743 0.0986,0.2373 0.0629,0.0629 0.1483,0.0984 0.2373,0.0985 h 11.8794 c 0.0889,-4e-4 0.1741,-0.0359 0.2369,-0.0988 0.0629,-0.0629 0.0985,-0.148 0.0989,-0.237 v -1.7178 z" fill={props.darkMode? "#b3b3b3" : "#3f3d56" } id="about-us-svg-wd-path173" />
			                <path d="m 85.419609,69.167854 h -28.1857 c -0.0772,0 -0.1534,-0.0172 -0.223,-0.0504 -0.0697,-0.0331 -0.1311,-0.0814 -0.1797,-0.1414 -0.0486,-0.0599 -0.0832,-0.1299 -0.1013,-0.2049 -0.0182,-0.075 -0.0193,-0.1531 -0.003,-0.2285 l 0.4373,-2.0772 c 0.0249,-0.1162 0.0888,-0.2204 0.1811,-0.2953 0.0923,-0.0749 0.2074,-0.1159 0.3262,-0.1164 h 27.3112 c 0.1188,5e-4 0.234,0.0415 0.3262,0.1164 0.0923,0.0749 0.1562,0.1791 0.1811,0.2953 l 0.4373,2.0772 c 0.0159,0.0754 0.0148,0.1535 -0.003,0.2285 -0.0182,0.075 -0.0528,0.145 -0.1014,0.2049 -0.0486,0.06 -0.11,0.1083 -0.1796,0.1414 -0.0697,0.0332 -0.1459,0.0504 -0.223,0.0504 z" fill={props.darkMode? "#b3b3b3" : "#3f3d56" } id="about-us-svg-wd-path175" />
			                <path d="m 58.739509,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path177" />
			                <path d="m 60.475509,66.688254 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path179" />
			                <path d="m 62.211609,66.688254 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path181" />
			                <path d="m 63.948009,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path183" />
			                <path d="m 65.684009,66.688254 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path185" />
			                <path d="m 67.420409,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path187" />
			                <path d="m 69.156509,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path189" />
			                <path d="m 70.892509,66.688254 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path191" />
			                <path d="m 72.628909,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path193" />
			                <path d="m 74.365009,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path195" />
			                <path d="m 76.101309,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path197" />
			                <path d="m 77.837409,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path199" />
			                <path d="m 79.573709,66.688254 c 0,-0.1438 -0.1943,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2398,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path201" />
			                <path d="m 81.309809,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path203" />
			                <path d="m 83.045909,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path205" />
			                <path d="m 84.782209,66.688254 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path207" />
			                <path d="m 58.719009,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path209" />
			                <path d="m 60.455009,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path211" />
			                <path d="m 62.191109,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2398,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1943,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path213" />
			                <path d="m 63.927509,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path215" />
			                <path d="m 65.663509,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path217" />
			                <path d="m 67.399909,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path219" />
			                <path d="m 69.135909,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path221" />
			                <path d="m 70.872009,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path223" />
			                <path d="m 72.608409,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path225" />
			                <path d="m 74.344409,67.556154 c 0,-0.1438 -0.1943,-0.2604 -0.434,-0.2604 -0.2397,0 -0.4341,0.1166 -0.4341,0.2604 0,0.1438 0.1944,0.2604 0.4341,0.2604 0.2397,0 0.434,-0.1166 0.434,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path227" />
			                <path d="m 76.080809,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path229" />
			                <path d="m 77.816909,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path231" />
			                <path d="m 79.553209,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path233" />
			                <path d="m 81.289309,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path235" />
			                <path d="m 83.025409,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path237" />
			                <path d="m 84.761709,67.556154 c 0,-0.1438 -0.1944,-0.2604 -0.4341,-0.2604 -0.2397,0 -0.434,0.1166 -0.434,0.2604 0,0.1438 0.1943,0.2604 0.434,0.2604 0.2397,0 0.4341,-0.1166 0.4341,-0.2604 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path239" />
			                <path d="m 72.640909,68.337454 h -5.9684 c -0.2696,0 -0.4881,0.1166 -0.4881,0.2605 0,0.1438 0.2185,0.2604 0.4881,0.2604 h 5.9684 c 0.2696,0 0.4882,-0.1166 0.4882,-0.2604 0,-0.1439 -0.2186,-0.2605 -0.4882,-0.2605 z" fill={props.darkMode? "#6c6c6c" : "#e6e6e6" } id="about-us-svg-wd-path241" />
			                <path d="m 66.814009,35.799954 h -3.3438 c -0.0887,0 -0.1738,-0.0352 -0.2365,-0.0979 -0.0627,-0.0628 -0.0979,-0.1478 -0.0979,-0.2365 0,-0.0887 0.0352,-0.1737 0.0979,-0.2364 0.0627,-0.0627 0.1478,-0.098 0.2365,-0.098 h 3.3438 c 0.0887,0 0.1738,0.0353 0.2365,0.098 0.0627,0.0627 0.0979,0.1477 0.0979,0.2364 0,0.0887 -0.0352,0.1737 -0.0979,0.2365 -0.0627,0.0627 -0.1478,0.0979 -0.2365,0.0979 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-4" className="wd-dash-4-animation"/>
			                <path d="m 72.833109,35.799954 h -3.3439 c -0.0887,0 -0.1737,-0.0352 -0.2364,-0.0979 -0.0628,-0.0628 -0.098,-0.1478 -0.098,-0.2365 0,-0.0887 0.0352,-0.1737 0.098,-0.2364 0.0627,-0.0627 0.1477,-0.098 0.2364,-0.098 h 3.3439 c 0.0887,0 0.1737,0.0353 0.2364,0.098 0.0628,0.0627 0.098,0.1477 0.098,0.2364 0,0.0887 -0.0352,0.1737 -0.098,0.2365 -0.0627,0.0627 -0.1477,0.0979 -0.2364,0.0979 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-5" className="wd-dash-5-animation"/>
			                <path d="m 75.842609,38.474954 h -3.3439 c -0.0439,0 -0.0874,-0.0086 -0.1279,-0.0254 -0.0406,-0.0168 -0.0775,-0.0414 -0.1085,-0.0725 -0.0311,-0.031 -0.0557,-0.0679 -0.0725,-0.1085 -0.0168,-0.0405 -0.0255,-0.084 -0.0255,-0.1279 0,-0.044 0.009,-0.0874 0.0255,-0.128 0.0168,-0.0406 0.0414,-0.0774 0.0725,-0.1085 0.031,-0.031 0.0679,-0.0557 0.1085,-0.0725 0.0405,-0.0168 0.084,-0.0254 0.1279,-0.0254 h 3.3439 c 0.0439,0 0.0874,0.0086 0.128,0.0254 0.0405,0.0168 0.0774,0.0415 0.1084,0.0725 0.0311,0.0311 0.0557,0.0679 0.0725,0.1085 0.0168,0.0406 0.0255,0.084 0.0255,0.128 0,0.0439 -0.009,0.0874 -0.0255,0.1279 -0.0168,0.0406 -0.0414,0.0775 -0.0725,0.1085 -0.031,0.0311 -0.0679,0.0557 -0.1084,0.0725 -0.0406,0.0168 -0.0841,0.0254 -0.128,0.0254 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-8" className="wd-dash-8-animation"/>
			                <path d="m 69.842009,38.474954 h -6.3718 c -0.0887,0 -0.1738,-0.0352 -0.2365,-0.0979 -0.0627,-0.0627 -0.0979,-0.1478 -0.0979,-0.2364 0,-0.0887 0.0352,-0.1738 0.0979,-0.2365 0.0627,-0.0627 0.1478,-0.0979 0.2365,-0.0979 h 6.3718 c 0.0887,0 0.1737,0.0352 0.2364,0.0979 0.0627,0.0627 0.098,0.1478 0.098,0.2365 0,0.0886 -0.0353,0.1737 -0.098,0.2364 -0.0627,0.0627 -0.1477,0.0979 -0.2364,0.0979 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-7" className="wd-dash-7-animation"/>
			                <path d="m 81.880109,35.799954 h -6.3718 c -0.0887,0 -0.1738,-0.0352 -0.2365,-0.0979 -0.0627,-0.0628 -0.0979,-0.1478 -0.0979,-0.2365 0,-0.0887 0.0352,-0.1737 0.0979,-0.2364 0.0627,-0.0627 0.1478,-0.098 0.2365,-0.098 h 6.3718 c 0.0887,0 0.1737,0.0353 0.2364,0.098 0.0627,0.0627 0.098,0.1477 0.098,0.2364 0,0.0887 -0.0353,0.1737 -0.098,0.2365 -0.0627,0.0627 -0.1477,0.0979 -0.2364,0.0979 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-6" className="wd-dash-6-animation"/>
			                <path d="m 69.103309,30.734054 h -6.3718 c -0.044,10e-5 -0.0875,-0.0085 -0.1282,-0.0252 -0.0407,-0.0168 -0.0776,-0.0414 -0.1088,-0.0725 -0.0311,-0.031 -0.0558,-0.0679 -0.0727,-0.1085 -0.0168,-0.0407 -0.0255,-0.0842 -0.0255,-0.1282 0,-0.044 0.009,-0.0875 0.0255,-0.1282 0.0169,-0.0406 0.0416,-0.0775 0.0727,-0.1086 0.0312,-0.031 0.0681,-0.0556 0.1088,-0.0724 0.0407,-0.0167 0.0842,-0.0253 0.1282,-0.0252 h 6.3718 c 0.0887,0 0.1738,0.0352 0.2365,0.0979 0.0627,0.0628 0.0979,0.1478 0.0979,0.2365 0,0.0887 -0.0352,0.1737 -0.0979,0.2364 -0.0627,0.0628 -0.1478,0.098 -0.2365,0.098 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-2" className="wd-dash-2-animation" />
			                <path d="m 69.627309,32.687654 c -0.0624,0 -0.1233,-0.0194 -0.1741,-0.0557 -0.0508,-0.0363 -0.089,-0.0875 -0.1093,-0.1465 -0.0203,-0.0591 -0.0216,-0.123 -0.004,-0.1828 0.0179,-0.0598 0.0539,-0.1126 0.1032,-0.1509 l 1.6316,-1.2682 c 0.0655,-0.051 0.1198,-0.115 0.1593,-0.188 0.0396,-0.073 0.0635,-0.1535 0.0703,-0.2362 0.007,-0.0828 -0.004,-0.1661 -0.0307,-0.2446 -0.027,-0.0785 -0.0701,-0.1505 -0.1264,-0.2115 l -1.3698,-1.4829 c -0.0267,-0.0289 -0.0474,-0.0627 -0.0611,-0.0996 -0.0136,-0.0369 -0.0198,-0.0761 -0.0182,-0.1154 0.001,-0.0393 0.0108,-0.0779 0.0273,-0.1136 0.0164,-0.0358 0.0398,-0.0679 0.0687,-0.0946 0.0288,-0.0267 0.0627,-0.0474 0.0996,-0.061 0.0369,-0.0136 0.0761,-0.0198 0.1154,-0.0183 0.0393,0.0016 0.0779,0.0109 0.1136,0.0273 0.0357,0.0165 0.0678,0.0398 0.0945,0.0687 l 2.0342,2.202 c 0.0281,0.0305 0.0496,0.0665 0.0632,0.1057 0.0135,0.0392 0.0187,0.0808 0.0153,0.1221 -0.003,0.0413 -0.0153,0.0815 -0.0351,0.118 -0.0197,0.0365 -0.0468,0.0684 -0.0795,0.0939 l -2.4046,1.8691 c -0.0525,0.0409 -0.1171,0.063 -0.1836,0.063 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-3" className="wd-dash-3-animation"/>
			                <path d="m 62.409209,32.687654 c 0.0624,0 0.1233,-0.0194 0.1741,-0.0557 0.0508,-0.0363 0.089,-0.0875 0.1093,-0.1465 0.0202,-0.0591 0.0215,-0.123 0.004,-0.1828 -0.0178,-0.0598 -0.0539,-0.1126 -0.1032,-0.1509 l -1.6315,-1.2682 c -0.0656,-0.051 -0.1199,-0.115 -0.1594,-0.188 -0.0395,-0.073 -0.0634,-0.1535 -0.0702,-0.2362 -0.007,-0.0828 0.004,-0.1661 0.0306,-0.2446 0.0271,-0.0785 0.0701,-0.1505 0.1265,-0.2115 l 1.3698,-1.4829 c 0.0267,-0.0289 0.0474,-0.0627 0.061,-0.0996 0.0136,-0.0369 0.0198,-0.0761 0.0183,-0.1154 -0.002,-0.0393 -0.0109,-0.0779 -0.0273,-0.1136 -0.0165,-0.0358 -0.0398,-0.0679 -0.0687,-0.0946 -0.0289,-0.0267 -0.0627,-0.0474 -0.0996,-0.061 -0.0369,-0.0136 -0.0761,-0.0198 -0.1154,-0.0183 -0.0393,0.0016 -0.0779,0.0109 -0.1136,0.0273 -0.0357,0.0165 -0.0679,0.0398 -0.0946,0.0687 l -2.0341,2.202 c -0.0282,0.0305 -0.0497,0.0665 -0.0632,0.1057 -0.0135,0.0392 -0.0187,0.0808 -0.0153,0.1221 0.003,0.0413 0.0153,0.0815 0.035,0.118 0.0198,0.0365 0.0468,0.0684 0.0796,0.0939 l 2.4045,1.8691 c 0.0525,0.0409 0.1172,0.063 0.1837,0.063 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-1" className="wd-dash-1-animation" />
			                <path d="m 72.397409,44.209354 h -6.3718 c -0.0887,0 -0.1738,-0.0352 -0.2365,-0.0979 -0.0627,-0.0627 -0.0979,-0.1478 -0.0979,-0.2365 0,-0.0886 0.0352,-0.1737 0.0979,-0.2364 0.0627,-0.0627 0.1478,-0.098 0.2365,-0.098 h 6.3718 c 0.0887,0 0.1737,0.0353 0.2364,0.098 0.0628,0.0627 0.098,0.1478 0.098,0.2364 0,0.0887 -0.0352,0.1738 -0.098,0.2365 -0.0627,0.0627 -0.1477,0.0979 -0.2364,0.0979 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-11" className="wd-dash-11-animation"/>
			                <path d="m 73.520109,45.863654 c -0.0624,1e-4 -0.1233,-0.0194 -0.1741,-0.0557 -0.0508,-0.0362 -0.089,-0.0875 -0.1093,-0.1465 -0.0203,-0.059 -0.0216,-0.1229 -0.004,-0.1828 0.0178,-0.0598 0.0539,-0.1125 0.1032,-0.1508 l 1.6315,-1.2683 c 0.0656,-0.0509 0.1198,-0.115 0.1594,-0.188 0.0395,-0.073 0.0634,-0.1534 0.0702,-0.2362 0.007,-0.0828 -0.004,-0.166 -0.0307,-0.2445 -0.027,-0.0786 -0.0701,-0.1506 -0.1264,-0.2116 l -1.3698,-1.4829 c -0.0539,-0.0583 -0.0824,-0.1357 -0.0793,-0.215 0.003,-0.0794 0.0376,-0.1542 0.096,-0.2081 0.0583,-0.0539 0.1356,-0.0825 0.215,-0.0793 0.0794,0.0031 0.1542,0.0376 0.2081,0.0959 l 2.0342,2.202 c 0.0281,0.0305 0.0497,0.0665 0.0632,0.1057 0.0135,0.0392 0.0187,0.0808 0.0153,0.1221 -0.003,0.0414 -0.0153,0.0815 -0.0351,0.118 -0.0197,0.0365 -0.0468,0.0685 -0.0795,0.0939 l -2.4046,1.8691 c -0.0525,0.0409 -0.1171,0.0631 -0.1836,0.063 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-12" className="wd-dash-12-animation"/>
			                <path d="m 62.409209,45.863654 c 0.0624,1e-4 0.1233,-0.0194 0.1741,-0.0557 0.0508,-0.0362 0.089,-0.0875 0.1093,-0.1465 0.0202,-0.059 0.0215,-0.1229 0.004,-0.1828 -0.0178,-0.0598 -0.0539,-0.1125 -0.1032,-0.1508 l -1.6315,-1.2683 c -0.0656,-0.0509 -0.1199,-0.115 -0.1594,-0.188 -0.0395,-0.073 -0.0634,-0.1534 -0.0702,-0.2362 -0.007,-0.0828 0.004,-0.166 0.0306,-0.2445 0.0271,-0.0786 0.0701,-0.1506 0.1265,-0.2116 l 1.3698,-1.4829 c 0.0267,-0.0289 0.0474,-0.0627 0.061,-0.0996 0.0136,-0.0369 0.0198,-0.0761 0.0183,-0.1154 -0.002,-0.0393 -0.0109,-0.0779 -0.0273,-0.1136 -0.0165,-0.0357 -0.0398,-0.0679 -0.0687,-0.0945 -0.0289,-0.0267 -0.0627,-0.0475 -0.0996,-0.0611 -0.0369,-0.0136 -0.0761,-0.0198 -0.1154,-0.0182 -0.0393,0.0015 -0.0779,0.0108 -0.1136,0.0272 -0.0357,0.0165 -0.0679,0.0398 -0.0946,0.0687 l -2.0341,2.202 c -0.0282,0.0305 -0.0497,0.0665 -0.0632,0.1057 -0.0135,0.0392 -0.0187,0.0808 -0.0153,0.1221 0.003,0.0414 0.0153,0.0815 0.035,0.118 0.0198,0.0365 0.0468,0.0685 0.0796,0.0939 l 2.4045,1.8691 c 0.0525,0.0409 0.1172,0.0631 0.1837,0.063 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-9" className="wd-dash-9-animation"/>
			                <path d="m 62.977609,44.926654 1.3394,-3.0639 c 0.0358,-0.0809 0.1022,-0.1443 0.1846,-0.1764 0.0824,-0.032 0.1741,-0.0301 0.2551,0.0053 0.0811,0.0354 0.1448,0.1015 0.1772,0.1837 0.0324,0.0823 0.031,0.1741 -0.004,0.2552 l -1.3394,3.064 c -0.0174,0.0404 -0.0427,0.077 -0.0743,0.1076 -0.0317,0.0307 -0.0691,0.0548 -0.11,0.071 -0.041,0.0161 -0.0848,0.024 -0.1288,0.0232 -0.044,-8e-4 -0.0875,-0.0103 -0.1279,-0.0279 -0.0403,-0.0177 -0.0768,-0.0431 -0.1073,-0.0749 -0.0305,-0.0318 -0.0544,-0.0693 -0.0704,-0.1103 -0.0159,-0.0411 -0.0236,-0.0849 -0.0226,-0.1289 10e-4,-0.044 0.0107,-0.0874 0.0285,-0.1277 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="dash-10" className="wd-dash-10-animation"/>
			            </g>
			        </g>
			        <g id="g384" transform="translate(-1.5918375,20.048)">
			            <path d="m 49.111162,35.796398 c -0.109299,0.1409 -0.2603,0.2437 -0.4315,0.2936 -0.1713,0.0499 -0.3539,0.0444 -0.5218,-0.0157 -0.0511,-0.0151 -0.1011,-0.0334 -0.149999,-0.0547 0.0552,-0.5894 0.0278,-1.1837 -0.0811,-1.7655 -0.0428,0.5001 -0.165101,0.9902 -0.3623,1.4519 -0.1608,-0.1613 -0.2924,-0.3491 -0.389,-0.5553 -0.179901,-0.4266 -0.299101,-0.8764 -0.3542,-1.3361 -0.2661,-1.5663 -0.5287,-3.1958 -0.0945,-4.7266 0.6362,-2.23604 2.6423,-3.84355 3.4802,-6.01307 l 0.412799,-0.37058 c 0.7067,1.20333 0.738,2.6986 0.4499,4.06309 -0.286499,1.36716 -0.8639,2.65036 -1.3204,3.96886 -0.4564,1.3185 -0.7984,2.7209 -0.586599,4.1006 0.0486,0.3248 0.1229,0.6819 -0.0515,0.9595 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path269" />
			            <path d="m 47.269063,54.716998 h -7.3324 l -2.690201,12.3044 11.750401,3.8272 z" fill="#ffb6b6" id="about-us-svg-wd-path271" />
			            <path d="m 24.527862,93.424798 5.1174,8.112402 -2.0716,1.2828 -6.1033,-7.502302 z" fill="#ffb6b6" id="about-us-svg-wd-path273" />
			            <path d="m 31.433863,104.2167 -1.6665,0.744 -0.9998,-1.4406 -0.0595,1.9135 -4.4199,1.973 c -0.2119,0.0946 -0.4484,0.119 -0.6751,0.0697 -0.2267,-0.0494 -0.4317,-0.17 -0.585,-0.3441 -0.153401,-0.1741 -0.247,-0.3927 -0.2674,-0.6238 -0.0203,-0.2311 0.0338,-0.4627 0.1544,-0.6609 l 2.4414,-4.0133 -0.7101,-1.5906 3.8115,-1.435702 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path275" />
			            <path d="m 23.841862,100.0939 3.4989,-3.390802 c -2.5995,-6.4148 -2.807199,-10.3909 -6.411,-18.1493 8.550301,1.7489 14.663301,1.6426 21.7347,0.0746 2.3939,-0.5225 8.5708,-5.2938 8.063001,-7.7402 -0.0238,-0.1144 -0.0518,-0.2277 -0.0842,-0.3399 -0.759,-2.588 -0.2245,-6.5511 -1.867399,-9.4229 -3.0396,-1.146 -7.657601,2.7236 -11.27,5.0825 -1.7419,1.1374 -0.4668,2.6874 -1.7678,3.0937 l -4.987301,1.6189 -12.236199,-1.9762 c -0.5914,-0.0896 -1.1946,-0.0613 -1.775,0.0833 -0.580401,0.1446 -1.1264,0.4026 -1.60658,0.7592 -0.48019,0.3566 -0.88505,0.8047 -1.19126,1.3184 -0.3062,0.5138 -0.50769,1.0831 -0.59285,1.6752 -0.02071,0.1328 -0.03571,0.2664 -0.045,0.4007 0.56301,3.1166 1.1499,6.1513 1.78176,9.0322 0.20396,0.9299 -0.14037,2.7126 0.62663,2.7391 0.767,0.0265 0.458,1.8704 0.696899,2.7718 1.470601,5.5496 2.400501,8.2353 7.4327,12.369702 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path277" />
			            <path d="m 21.586762,106.4566 -4.442,8.5099 -2.158269,-1.1265 3.41477,-9.0461 z" fill="#ffb6b6" id="about-us-svg-wd-path279" />
			            <path d="m 16.785362,108.2132 3.4608,2.3828 c 4.241601,-6.1343 6.9956,-14.331402 9.0467,-23.503402 7.9858,-3.4837 14.9737,-7.4693 19.8432,-12.8153 0.7997,-0.8963 1.219,-2.0687 1.1691,-3.2689 -0.0499,-1.2001 -0.5651,-2.3337 -1.4364,-3.1605 -0.0852,-0.0799 -0.1733,-0.1564 -0.2643,-0.2296 -2.1091,-1.68 -6.7935,-0.7133 -9.7875,-2.1176 l -5.3486,7.4028 0.6147,1.7242 -1.8606,0.9058 -1.571,0.7647 -1.7983,0.8755 -5.8954,2.8699 c -0.5344,0.2672 -1.0107,0.6375 -1.4014,1.0897 -0.3906,0.4521 -0.6879,0.9771 -0.8746,1.5447 -0.1867,0.5676 -0.2592,1.1666 -0.2133,1.7623 0.0459,0.5958 0.2094,1.1766 0.4808,1.7088 0.0596,0.1206 0.1243,0.2384 0.194,0.3536 l -2.1895,10.9076 c 0,0 0.6025,1.8989 -0.3455,1.7212 -0.9479,-0.1777 -0.4314,2.149102 -0.4314,2.149102 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path281" />
			            <path d="m 15.837863,119.952 -1.61788,-0.844 0.50842,-1.679 -1.53684,1.142 -4.2910405,-2.2396 c -0.2057,-0.1074 -0.37169,-0.2776 -0.47375,-0.486 -0.10207,-0.2084 -0.13485,-0.4439 -0.09358,-0.6722 0.04128,-0.2283 0.15445,-0.4375 0.32301,-0.5969 0.16855,-0.1594 0.38365,-0.2608 0.61391,-0.2894 l 4.6618605,-0.5781 0.80595,-1.5442 3.492039,2.0962 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path283" />
			            <path d="m 63.012062,72.529298 -2.524299,-6.1003 -1.9888,1.1049 1.2011,6.2055 c -0.120301,0.3932 -0.1286,0.8121 -0.0238,1.2097 0.1047,0.3975 0.3183,0.758 0.6166,1.0409 0.2984,0.2828 0.6698,0.4768 1.0724,0.5602 0.4026,0.0834 0.8204,0.0528 1.206601,-0.0883 0.386099,-0.1411 0.725299,-0.3871 0.9793,-0.7104 0.254,-0.3233 0.412799,-0.711 0.458599,-1.1196 0.0457,-0.4086 -0.0235,-0.8218 -0.1997,-1.1933 -0.176299,-0.3714 -0.452599,-0.6863 -0.798,-0.9093 z" fill="#ffb6b6" id="about-us-svg-wd-path285" />
			            <path d="m 20.653863,24.290128 3.840899,5.36967 1.686701,-1.5267 -2.575901,-5.77209 c 0.0281,-0.41017 -0.0588,-0.82005 -0.250899,-1.18355 -0.192101,-0.3635 -0.481801,-0.66621 -0.8365,-0.8741 -0.3547,-0.20789 -0.7603,-0.31272 -1.171401,-0.3027 -0.410999,0.01002 -0.8111,0.13449 -1.1552,0.35941 -0.344199,0.22493 -0.6187,0.54139 -0.7929,0.91382 -0.1741,0.37243 -0.241,0.78605 -0.1929,1.19437 0.048,0.40833 0.209001,0.79516 0.464801,1.117 0.2558,0.32185 0.596399,0.56595 0.9833,0.70487 z" fill="#ffb6b6" id="about-us-svg-wd-path287" />
			            <path d="m 37.998263,58.324098 c -2.4912,3.8641 6.6355,12.2833 11.4405,10.3146 0.841299,-0.3447 3.7355,-1.4834 1.757199,-3.062 -1.9782,-1.5786 0.2294,0.1881 0.2816,-0.8395 0.0349,-0.6871 -0.4971,-2.1942 0.0579,-2.1296 0.555,0.0647 0.8735,-1.5405 -0.3352,-1.9494 -0.4202,-0.15 -0.789,-0.4169 -1.063,-0.7691 -0.274,-0.3523 -0.4418,-0.7754 -0.4838,-1.2197 -0.1156,-4.2354 1.3188,-8.6574 1.3188,-8.6574 l 5.8555,14.9681 c 0,0 -0.0433,2.5895 0.7034,1.7982 0.7467,-0.7914 0.6924,1.7699 0.6924,1.7699 0,0 1.1103,0.7749 0.4718,1.2061 -0.6384,0.4313 0.6874,1.7571 0.6874,1.7571 l 3.0938,-0.2209 c 0,0 -0.0485,-2.2982 -0.5767,-2.8065 -0.5282,-0.5082 -0.2688,-1.308 -0.2688,-1.308 0,0 -1.089,-1.013 -0.4532,-2.2059 0.635701,-1.193 -4.6298,-22.5314 -4.6298,-22.5314 -0.210999,-1.0267 -0.701699,-1.975 -1.417899,-2.7401 -0.716301,-0.7652 -1.630101,-1.3174 -2.6406,-1.5956 l -3.3414,-0.9201 -0.5934,-2.3541 h -4.704901 l -0.8196,1.5469 -7.5133,-0.663 c 0,0 -7.4343,-6.7641 -7.0713,-7.2923 0.363,-0.5282 -0.7895,-1.7647 -0.7895,-1.7647 0,0 -0.9842,-0.32213 -0.4288,-0.49411 0.5553,-0.17199 -0.4944,-0.56962 -0.4944,-0.56962 0,0 -0.9722,-0.25547 -0.5137,-0.59186 0.4585,-0.33638 -1.5303,-0.55736 -1.5303,-0.55736 l -2.5804,2.1054 0.5215,0.88435 c 0,0 -0.318,1.2042 0.097,0.7646 0.415,-0.4396 0.4932,0.8362 0.4932,0.8362 0,0 0.2119,1.397 1.4687,1.8179 1.2568,0.4209 2.2098,4.4196 2.2098,4.4196 l 10.607001,8.8392 c -1.6154,1.8542 -1.2309,3.7933 0.2002,5.7768 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="about-us-svg-wd-path289" />
			            <path d="m 46.096263,33.264798 c 2.8138,0 5.094799,-2.2811 5.094799,-5.0949 0,-2.81379 -2.280999,-5.09483 -5.094799,-5.09483 -2.813801,0 -5.094901,2.28104 -5.094901,5.09483 0,2.8138 2.2811,5.0949 5.094901,5.0949 z" fill="#ffb6b6" id="about-us-svg-wd-path291" />
			            <path d="m 50.543762,26.652518 c -0.130399,0.03757 -2.0441,0.28727 -2.872799,0.44198 -0.813201,0.1525 -0.9546,-0.88394 -1.619701,-0.68948 3.01e-4,-0.0045 -4.99e-4,-0.0091 -0.0023,-0.01325 -0.1031,-0.54914 -0.279201,-1.08201 -0.5237,-1.58443 0.0887,0.4812 0.1014,0.97334 0.0376,1.45847 -4e-4,0.01262 -0.0019,0.0252 -0.0044,0.03757 -0.0022,0.02209 -0.0067,0.04198 -0.0089,0.06407 -0.7027,-0.30937 -1.1491,-0.03314 -1.6352,0.06409 -0.5436,0.10828 -0.9679,0.34694 -0.6629,2.20976 0.6253,3.8318 3.8781,5.1997 3.3146,7.7343 -0.1105,0.5017 -0.3889,1.1292 -0.738,1.2861 -0.1031,-0.5491 -0.2793,-1.082 -0.5237,-1.5844 0.0886,0.4812 0.1013,0.9733 0.0375,1.4585 -0.5414,-0.5856 -0.9325,-2.6319 -2.7534,-4.2539 -0.4486,-0.3978 -2.6959,-2.7578 -2.9655,-3.3147 0.0827,0.4452 0.1227,0.8974 0.1193,1.3502 5e-4,0.3031 -0.0201,0.6058 -0.0619,0.906 0,0.0194 -0.0022,0.0386 -0.0066,0.0575 -0.0022,0.0353 -0.0088,0.0663 -0.0133,0.0994 -0.0044,0.0376 -0.011,0.0729 -0.0176,0.1105 -0.7359,-0.1061 -1.3878,-0.2342 -1.695,-0.5348 -1.4938,-1.454 0.3293,-4.287 1.3259,-6.85034 1.4253,-3.67047 5.5643,-4.15884 5.9665,-4.19861 1.7722,-0.179 4.7665,0.20109 5.7454,2.20979 0.6851,1.40765 0.2475,3.33679 -0.4419,3.53568 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path293" />
			            <path d="m 39.800763,27.481098 3.690599,-5.74115 c -0.8845,-0.51347 -1.891599,-0.77766 -2.914299,-0.76451 -0.5096,0.0106 -1.0113,0.12847 -1.4724,0.34593 -0.461,0.21746 -0.8711,0.52964 -1.2034,0.91619 -0.7538,0.93677 -0.8831,2.26102 -0.600701,3.42975 0.0975,0.40365 1.954801,-1.01659 1.512801,-0.35365 -0.3628,0.51904 -0.9561,2.70074 -1.1049,3.31474 -0.2395,0.9881 -0.8979,2.0025 -0.3114,2.833 0.6937,0.9822 1.931399,1.4704 3.1328,1.5199 1.2013,0.0496 2.3838,-0.2833 3.5255,-0.6604 z" fill={props.darkMode? "#3a3a3a" : "#2f2e41" } id="about-us-svg-wd-path295" />
			            <path d="m 41.218963,27.254698 c -0.0533,-10e-5 -0.1048,-0.0194 -0.145001,-0.0544 -0.0401,-0.035 -0.0663,-0.0834 -0.0736,-0.1362 -0.0074,-0.0528 0.0046,-0.10641 0.0337,-0.15106 0.0291,-0.04466 0.0733,-0.07729 0.1246,-0.0919 0.0579,-0.0956 0.0342,-0.49796 0.0151,-0.82306 -0.0719,-1.22068 -0.1922,-3.26365 1.7913,-4.64274 0.0238,-0.01656 0.0506,-0.02827 0.079,-0.03446 0.0283,-0.0062 0.0576,-0.0067 0.0861,-0.0016 0.0286,0.0051 0.0559,0.01583 0.0803,0.0315 0.0244,0.01566 0.0455,0.03599 0.0621,0.05981 0.0165,0.02382 0.0282,0.05067 0.0344,0.07901 0.0062,0.02835 0.0068,0.05763 0.0016,0.08619 -0.0051,0.02855 -0.0158,0.05582 -0.0315,0.08024 -0.0156,0.02442 -0.036,0.04551 -0.0598,0.06207 -1.7799,1.23773 -1.6731,3.05293 -1.6023,4.25397 0.0404,0.68668 0.0696,1.18273 -0.3469,1.27713 -0.0161,0.0037 -0.0326,0.0055 -0.0491,0.0055 z" fill={props.darkMode? "#FF5F5F" : "#FF5F5F" } id="about-us-svg-wd-path297" />
			        </g>
			    </g>
			</svg>
		</>
	)
}